import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { requests } from "utils/requests";


export default function PrivacyPolicy() {
  const [text, setText] = useState<string|null>(null)

  useEffect(()=>{
    requests.get('/system/policy/').then((r)=>{
      setText(r.body.text)
    })
  }, [])

  if(text === null){
    return null;
  }
  
  return (
    <Container sx={{
      whiteSpace: 'pre-wrap'
    }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={7} lg={6}>
          {text}
        </Grid>
      </Grid>
    </Container>
  );
}
