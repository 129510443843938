import React, { useEffect, useReducer, Reducer } from 'react';
import RequestsContext, { ProviderContext } from './RequestsContext';
import { useProgress } from 'component/progress';
import { IResponse } from './request';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMessage } from 'component/message';

interface RequestsProviderProps{
  children: React.ReactNode;
}

function reducer(state:Promise<IResponse>[], q?:Promise<IResponse>) {
  let _state = state.filter((s:any) => !s.is_finally);
  if(q){
    _state.push(q);
  }
  return _state;
}

export default function RequestsProvider(props: RequestsProviderProps) {
  const { showProgress, hideProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const Message = useMessage();
  const { t } = useTranslation();
  
  const [ activeRequests, setActiveRequests ] = useReducer<Reducer<any, any>>(reducer, [] as any);

  useEffect(() => {

    function handleStartRequest(r:CustomEventInit<Promise<IResponse|null>>) {
      setActiveRequests(r.detail);
      r.detail?.catch((response)=>{
        if(response === null || !!response.without_error) return null;

        if(response.status >= 500 && response.status < 600){
          enqueueSnackbar(t('server_error'), {variant: 'error'})
        }else if(response.status >= 400 && response.status < 500){
          if(response.body?.detail){
            Message({
              message: response.body?.detail
            })
          }else{
            enqueueSnackbar(t('request_error'), {variant: 'error'})
          }
        }
      }).finally(()=>{
        // @ts-ignore
        r.detail.is_finally = true;
        setActiveRequests(undefined);
      })
    }

    window.addEventListener("StartRequest", handleStartRequest, false);
    return () => {
      window.removeEventListener("StartRequest", handleStartRequest);
    };
  });
  
  useEffect(()=>{
    const _isRequest = !!activeRequests.length;
    if(_isRequest){
      showProgress();
    }else{
      hideProgress();
    }
  }, [activeRequests, showProgress, hideProgress])

  const context:ProviderContext = {
    isRequest: !!activeRequests.length,
  }

  return (
    <RequestsContext.Provider value={context}>
        {props.children}
    </RequestsContext.Provider>
  );
}