import React, {useCallback, useEffect} from 'react';

interface IProps {
    handle: ()=>void;
    children: JSX.Element;
  }

export default function OnWindowScrollEnd(props:IProps) {

    const {
        handle,
        children
    } = props

    const handleScroll = useCallback(() => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            handle()
        }
    }, [handle])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <React.Fragment>
           {children}
        </React.Fragment>
    );
}