import React, { useCallback, useState } from 'react';
import { orderSerializer } from 'utils/order/orderSerializer';
import IOrder from 'utils/order/types';
import { requests } from 'utils/requests';
import OrderRatingContext, { ProviderContext } from './OrderRatingContext';
import OrderRatingDialog from './OrderRatingDialog';


interface IProps{
  children: React.ReactNode;
}


export default function OrderRatingProvider(props: IProps) {
  const [order, setOrder] = useState<IOrder|null>(null);
  const [defaultRating, setDefaultRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resolveReject, setResolveReject] = useState<any>([]);
  const [resolve] = resolveReject;

  const loadOrder = useCallback((order_id:number)=>{
    setLoading(true);
    requests.get(`/order/${order_id}/`).then((r)=>{
      setOrder(orderSerializer(r.body))
    }).finally(()=>{
      setLoading(false);
    })
  }, [])
  
  const doRating = useCallback((order_id:number, default_rating:number)=>{
    return new Promise<number>((_resolve, _reject) => {
      setDefaultRating(default_rating);
      loadOrder(order_id);
      setResolveReject([_resolve, _reject]);
    });
  }, [loadOrder])

  const context:ProviderContext = {
    doRating: doRating
  }

  return (
    <OrderRatingContext.Provider value={context}>
      {props.children}
      {(order!==null || loading)?(
        <OrderRatingDialog
          loading={loading}
          order={order}
          defaultRating={defaultRating}
          onClose={()=>{
            setOrder(null);
          }}
          onSave={(rating)=>{
            resolve(rating)
            setOrder(null);
          }}
        />
      ):null}
    </OrderRatingContext.Provider>
  );
}