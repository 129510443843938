import { Feature, Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import { MAP_TILE } from 'config';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Marker, Route } from './types';
import {
  defaults as defaultInteractions,
} from 'ol/interaction';
import carSVG from 'images/car.svg';
import mapEndPointSVG from 'images/map_end_point.svg';
import mapStartPointSVG from 'images/map_start_point.svg';
import mapPointSVG from 'images/map_point.svg';
import ImageIconMaker from '../ImageIconMaker';
import LineString from 'ol/geom/LineString';
import { AppTheme } from 'theme';
import { IPoint } from 'utils/types';
import { Extent, extend } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import CurrentLocationModel from 'models/CurrentLocationModel';
import AppModel from 'models/AppModel';



var styles = {
  'free': new Style({
    image: new Circle({
      radius: 6,
      stroke: new Stroke({
          color: AppTheme.theme === 'dark' ? '#ccc' : '#fff',
          width: 1,
      }),
      fill: new Fill({
          color: AppTheme.theme === 'dark' ? '#117d43' : "#16AC5C",
      }),
    })
  }),
  'busy': new Style({
    image: new Circle({
      radius: 6,
      stroke: new Stroke({
          color: AppTheme.theme === 'dark' ? '#ccc' : '#fff',
          width: 1,
      }),
      fill: new Fill({
          color: AppTheme.theme === 'dark' ? '#a72a2a' : "#CB3A3A"
      })
    })
  }),
  'car': new Style({
    image: new Icon({
      src: carSVG,
      scale: 1,
    }),
  }),
  'start_point': new Style({
    image: new Icon({
      scale: 1,
      img: ImageIconMaker(mapStartPointSVG),
      imgSize: [27,27]
    }),
  }),
  'end_point': new Style({
    image: new Icon({
      scale: 1,
      img: ImageIconMaker(mapEndPointSVG),
      imgSize: [44,44]
    }),
  }),
  'point': new Style({
    image: new Icon({
      scale: 1,
      img: ImageIconMaker(mapPointSVG),
      imgSize: [34,34]
    }),
  }),
  'location': new Style({
    image: new Circle({
      radius: 6,
      stroke: new Stroke({
          color: '#fff',
          width: 2,
      }),
      fill: new Fill({
          color: "#346CF0"
      })
    })
  }),
};

var road_styles = {
  'default': new Style({
    stroke: new Stroke({
      width: 6,
      color: AppTheme.theme === 'dark' ? AppTheme.darkColorDark : AppTheme.darkColor,
    }),
  }),
}

export class MapClass{
  iconsSource:VectorSource<any>;
  vectorLayer:Vector<any>;
  currentLocationSource:VectorSource<any>;
  currentLocationLayer:Vector<any>;
  includeCurrentLocationToFit:boolean;
  roadSource:VectorSource<any>;
  roadLayer:Vector<any>;
  tileLayer:TileLayer<any>;
  map:Map;
  targetMap:string;
  marginBottom:number;
  animate = {
    interval: 16,
    time: 5000,
    min_distance: 5,
    max_distance: 1000,
    rotateTime: 3000,
  };

  constructor(targetMap:string) {
    this.targetMap = targetMap;
    this.marginBottom = 0;

    this.iconsSource = new VectorSource();
    this.vectorLayer = new Vector({
      source: this.iconsSource,
      updateWhileAnimating: true,
    });

    this.includeCurrentLocationToFit = false;
    this.currentLocationSource = new VectorSource();
    this.currentLocationLayer = new Vector({
      source: this.currentLocationSource,
      updateWhileAnimating: true,
    });

    this.tileLayer = new TileLayer({
      source: new XYZ({
        url: `${MAP_TILE}{z}/{x}/{y}.png`
      })
    });

    this.roadSource = new VectorSource();
    this.roadLayer = new Vector({
      source: this.roadSource,
    });

    this.map = new Map({
      target: this.targetMap,
      interactions: defaultInteractions({
        altShiftDragRotate:false,
        pinchRotate:false
      }),
      layers: [
        this.tileLayer,
        this.roadLayer,
        this.vectorLayer,
        this.currentLocationLayer
      ],
      view: new View({
        center: fromLonLat([
          AppModel.city?.center.coordinates[1] || 30.5035,
          AppModel.city?.center.coordinates[0] || 50.4548
        ]),
        zoom: 12,
        maxZoom: 20,
      })
    });

    CurrentLocationModel.subscribe(this.updateCurrentLocation)
  }

  protected distance = (p1:Point, p2:Point) => {
    // Расстояние между двумя точками
    const c1 = p1.getCoordinates();
    const c2 = p2.getCoordinates();
    const distance = Math.sqrt(((c2[0] - c1[0]) ** 2) + ((c2[1] - c1[1]) ** 2));
    return distance;
  }

  protected pointInSquare = (point:Point, square:number[]):boolean => {
    // Входит ли точка в прямоуголькик
    const coordinates = point.getCoordinates();
    return coordinates[0] > square[0] && coordinates[0] < square[2] && coordinates[1] > square[1] && coordinates[1] < square[3];
  }

  get currentLocationFeature():Feature<Geometry>|null {
    const feature = this.currentLocationSource.getFeatureById("currentLocation");
    return feature?feature:null
  }

  protected updateCurrentLocation = () => {
    if(!!this.currentLocationFeature && CurrentLocationModel.point){
      const geometry = new Point(fromLonLat([
        CurrentLocationModel.point.coordinates[1],
        CurrentLocationModel.point.coordinates[0]]
      ));
      this.currentLocationFeature.setGeometry(geometry);
    }
  }

  showCurrentLocation = () => {
    this.hideCurrentLocation();
    const feature = new Feature();
    feature.setId("currentLocation");
    feature.setStyle(styles.location.clone())
    this.currentLocationSource.addFeature(feature);
    this.updateCurrentLocation();
  }

  hideCurrentLocation = () => {
    if(!!this.currentLocationFeature){
      this.currentLocationSource.removeFeature(this.currentLocationFeature);
    }
  }

  updateMarkers = (markers:Marker[]) => {
    // Обновление маркеров

    const marker_ids = markers.map((i) => i.id.toString());
    this.iconsSource.getFeatures().map((feature) => {
      if(marker_ids.indexOf((feature.getId() || "").toString()) < 0){
        // Удаляем маркеры с карты, которых нет в новом списке
        this.iconsSource.removeFeature(feature);
      }
      return feature;
    })

    markers.map((marker) => {
      // Берем маркер по ID или создаем новый
      let icon = this.iconsSource.getFeatureById(marker.id.toString());
      if(!icon){
        icon = new Feature();
      }
      const current_property = icon.getProperties();
      const current_geometry = icon.getGeometry() as Point;

      if(current_property){
        // Останавливаем анимацию движения и поворота если она запущена
        if(current_property.moveInterval){
          clearInterval(current_property.moveInterval);
        }
        if(current_property.rotateInterval){
          clearInterval(current_property.rotateInterval);
        }
      }
      icon.setId(marker.id.toString());
      if(!current_property || !current_property.info || current_property.info.style !== marker.style){
        // Устанавливаем стиль маркера
        icon.setStyle(styles[marker.style].clone())
      }

      const geometry = new Point(fromLonLat([marker.point.coordinates[1], marker.point.coordinates[0]]));
      const distance = current_geometry ? this.distance(current_geometry, geometry) : null;
      const visible_square = this.map.getView().calculateExtent(this.map.getSize());
      let moveInterval:any = null;
      let rotateInterval:any = null;
      if(current_geometry){
        // Если есть предыдущая координата у маркера
        // Другими словами если это не новый маркер
        const current_coordinates = (icon.getGeometry() as Point).getCoordinates();
        const to_coordinates = geometry.getCoordinates();

        if(distance &&
          distance > this.animate.min_distance &&
          distance < this.animate.max_distance &&
          (this.pointInSquare(current_geometry, visible_square) || this.pointInSquare(geometry, visible_square))
        ){
          // Анимация движения
          // Запускается анимациция движения если:
          // - расстояние больше чем this.animate.min_distance
          // - расстояние меньше чем this.animate.max_distance
          // - начальная или конечная точка попадает в видимую область карты

          // Количество итераций
          const stepCount = this.animate.time / this.animate.interval;

          // Значение инкримента на каждой итерации
          const valueIncrementX = (to_coordinates[0] - current_coordinates[0]) / stepCount;
          const valueIncrementY = (to_coordinates[1] - current_coordinates[1]) / stepCount;

          const sinValueIncrement = Math.PI / stepCount;
          let currentValueX = current_coordinates[0];
          let currentValueY = current_coordinates[1];
          let currentSinValue = 0;

          moveInterval = setInterval(()=>{
            currentSinValue += sinValueIncrement;
            currentValueX += valueIncrementX;
            currentValueY += valueIncrementY;
        
            if (currentSinValue < Math.PI) {
              icon.setGeometry(new Point([currentValueX, currentValueY]));
            } else {
              icon.setGeometry(geometry);
              clearInterval(moveInterval);
            }
            
          }, this.animate.interval)
        }else{
          icon.setGeometry(geometry);
        }

        if(marker.style === 'car'){
          // Анимация поворота только для маркера car

          const image = (icon.getStyle() as Style).getImage();

          // Текущий угол поварота
          const current_angle = image.getRotation();

          // Новый угол поворота
          const new_angle = Math.atan2(current_coordinates[0] - to_coordinates[0], current_coordinates[1] - to_coordinates[1]) + (1 * Math.PI);

          if(current_coordinates[0] !== to_coordinates[0] || current_coordinates[1] !== to_coordinates[1]){
            // Делаем что-то только если координаты поменялись
            
            if((this.pointInSquare(current_geometry, visible_square) || this.pointInSquare(geometry, visible_square))){
              // Запускается анимациция врощения если:
              // - начальная или конечная точка попадает в видимую область карты

              const diffAngle = (current:number, target:number):number => {
                // Принимает два угла и рассчитывает ближайший путь
                // для осуществления поворота
                let diff = target - current;
                if(diff < 0)
                  diff += 2 * Math.PI;
                if(diff > Math.PI)
                  return diff - 2 * Math.PI; // left turn
                else
                  return diff; // right turn
              }
              const diff_angle = diffAngle(current_angle, new_angle);
              
              // Количество итераций
              const stepCount = this.animate.rotateTime / this.animate.interval;

              // Инкримент на каждой итерации
              let valueIncrement = diff_angle / stepCount;
              
              const sinValueIncrement = Math.PI / stepCount;
              let currentValue = current_angle;
              let currentSinValue = 0;

              rotateInterval = setInterval(()=>{
                currentSinValue += sinValueIncrement;
                currentValue += valueIncrement;
            
                if (currentSinValue < Math.PI) {
                  image.setRotation(currentValue);
                } else {
                  image.setRotation(new_angle);
                  clearInterval(rotateInterval);
                }
                // Эта штука нужна потому что если не запускается анимация
                // движения, то и не работает анимация поворота так как
                // не рендерится маркер
                icon.setStyle(icon.getStyle())
                
              }, this.animate.interval)
            }else{
              image.setRotation(new_angle);

              // Эта штука нужна потому что если не запускается анимация
              // движения, то и не работает анимация поворота так как
              // не рендерится маркер
              icon.setStyle(icon.getStyle())
            }
          }
        }
      }else{
        icon.setGeometry(geometry);
      }

      icon.setProperties({
        info: marker,
        moveInterval: moveInterval,
        rotateInterval: rotateInterval,
      })
      this.iconsSource.addFeature(icon);
      return marker;
    })
  }

  setFit = (_extent:Extent) => {
    let new_extent:Extent = [..._extent];
    if(!!this.currentLocationFeature && this.includeCurrentLocationToFit){
      const e = this.currentLocationFeature?.getGeometry()?.getExtent();
      if(e){
        extend(new_extent, e)
      }
    }

    this.map.getView().fit(new_extent, {
      size: this.map.getSize(),
      padding: [25, 25, 25 + (this.marginBottom || 0), 25]
    });
  }

  updateRoute = (road?:Route) => {
    this.roadSource.getFeatures().map((feature) => {
      this.roadSource.removeFeature(feature);
      return feature;
    })

    if(road === undefined) return;
    const locations = (road?.route.coordinates || []).map(function(l) {
      return [...l].reverse();
    });
    var polyline = new LineString(locations);
    polyline.transform('EPSG:4326', 'EPSG:3857');
    let roadFeature = new Feature(polyline);
    roadFeature.setStyle(road_styles[road.style].clone())
    this.roadSource.addFeature(roadFeature);

    this.setFit(this.roadSource.getExtent());
  }

  setCenter = (_point:IPoint) => {
    const fixPointFit = 0.0007;
    const geometry = new LineString([
      fromLonLat([_point.coordinates[1]-fixPointFit, _point.coordinates[0]-fixPointFit]),
      fromLonLat([_point.coordinates[1]+fixPointFit, _point.coordinates[0]+fixPointFit]),
    ]);
    this.setFit(geometry.getExtent());
  }
}