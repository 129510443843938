import { Button, Container, FormControl, Grid, InputAdornment, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import FirebaseLog from "component/firebaseLog";
import { useProgress } from "component/progress";
import CitiesListModel, { ICity } from "models/CitiesListModel";
import PhoneModel from "models/PhoneModel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useFocus } from "utils/focus";
import { requests } from "utils/requests";
import { Md5 } from 'ts-md5';
import { API_CONFIG } from "config";
import { useSnackbar } from "notistack";
import { useMessage } from "component/message";


function Start() {
  const { t } = useTranslation();

  const storagePhone = (PhoneModel.data && PhoneModel.data.number) ? PhoneModel.data.number : "";
  const storageCode = PhoneModel.data ? PhoneModel.data.code : "";

  const [anchorCodesEl, setAnchorCodesEl] = useState<null | HTMLElement>(null);
  const [phoneRef, setPhoneFocus] = useFocus(true);
  const [phone, setPhone] = useState<string>(storagePhone);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [phoneCode, setPhoneCode ] = useState<string>(storageCode);
  const [city] = useState<ICity>(CitiesListModel.cities[0]);
  const [redirect, setRedirect ] = useState<string>("");
  const { isProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const Message = useMessage();

  const handlePhoneCodesOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorCodesEl(event.currentTarget);
  };

  const handlePhoneCodesClose = () => {
    setAnchorCodesEl(null);
  };

  useEffect(()=>{
    const new_phone_code = (phoneCode && city.phone_codes.indexOf(phoneCode) > -1) ? phoneCode : city.phone_codes[0];
    if(new_phone_code !== phoneCode){
      setPhoneCode(new_phone_code);
    }
  }, [phoneCode, city])

  useEffect(() => {
    PhoneModel.setData({
      code: phoneCode,
      number: phone,
      city_id: city.id,
    })
  }, [phoneCode, phone, city])

  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setPhoneError(false);
  }

  const handleCode = (code: string) => {
    setCode(code);
  }
  

  const setCode = (code:string) => {
    setPhoneCode(code);
    handlePhoneCodesClose();
  }

  const phoneStartAdornment = ():JSX.Element => {
    if(city.phone_codes.length <= 1){
      return (
        <Typography>{phoneCode}</Typography>
      );
    }
    return (
      <Button
        variant="text"
        color="inherit"
        disableRipple={true}
        endIcon={<ExpandMoreOutlined/>}
        onClick={handlePhoneCodesOpen}
      >
        {phoneCode}
      </Button>
    );
  }

  const doRequest = (ext_method?:any) => {
    setPhoneError(false);

    let params:any = {
      phone: `${phoneCode}${phone}`,
      city: city.id
    }

    if(!!ext_method){
      params.ext_method = ext_method;
    }

    requests.withoutErrorMessage().post("/auth/code/", params).then((r)=>{
      FirebaseLog.send_sms("first", r.status);

      PhoneModel.codeSent();
      PhoneModel.setData({
        code: phoneCode,
        number: r.body.phone.replace(phoneCode, ""),
        city_id: city.id
      })
      setRedirect('/code');
    }).catch((r)=>{
      if(r.status === 422){
        if(r.body?.ext_method?.method === "secret_key"){
          const key = r.body?.ext_method?.data || "";
          doRequest({
            method: "secret_key",
            data: Md5.hashStr(`${key}${navigator.userAgent}${API_CONFIG.api_key}${phoneCode}${phone}`)
          })
        }else{
          FirebaseLog.send_sms("first", r.status);
          setPhoneError(true);
          setPhoneFocus();
        }
      }else{
        FirebaseLog.send_sms("first", r.status);
        setPhoneError(true);
        setPhoneFocus();

        if(r.status >= 500 && r.status < 600){
          enqueueSnackbar(t('server_error'), {variant: 'error'})
        }else if(r.status >= 400 && r.status < 500){
          if(r.body?.detail){
            Message({
              message: r.body?.detail
            })
          }else{
            enqueueSnackbar(t('request_error'), {variant: 'error'})
          }
        }
      }
    })
  }

  const onSubmit = (e:any) => {
    e.preventDefault();
    doRequest();
  }

  if(redirect){
    return (
      <Redirect to={redirect} push={true} />
    );
  }
  
  return (
    <div>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <form onSubmit={onSubmit} noValidate={true}>
              <FormControl fullWidth>
                <TextField
                  value={phone}
                  error={phoneError}
                  autoFocus={true}
                  inputRef={phoneRef}
                  onChange={handlePhone}
                  type="tel"
                  inputProps={{
                    maxLength: 11,
                    pattern: "[0-9]*"
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ fontSize: '16px'}}>
                        {phoneStartAdornment()}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& input, & button, & p': {
                      fontSize: '16px',
                      fontWeight: 100,
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }
                  }}
                />

                <br/><br/>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isProgress}
                >
                  {t('get_code')}
                </Button>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Container>

        <Menu
          anchorEl={anchorCodesEl}
          keepMounted
          open={Boolean(anchorCodesEl)}
          onClose={handlePhoneCodesClose}
        >
          {city.phone_codes.map((code) => (
            <MenuItem
              key={code}
              onClick={()=>{
                handleCode(code);
              }}
            >
              {code}
            </MenuItem>
          ))}
        </Menu>
    </div>
  );
}

export default Start;
