import { IResponse, requests } from "utils/requests";
import { IPoint } from "utils/types";

export interface ICity{
  id: number,
  name: string,
  center: IPoint,
  default: boolean,
  phones: string[],
  phone_codes: string[],
}

class _CitiesListModel {
  private _cities:ICity[] = [];

  public load = ():Promise<boolean> => {
    return Promise.all([
      requests.get('/city/').then((r:IResponse) => {
        this._cities = r.body;
        return this.cities;
      })
    ]).then(()=>true).catch(()=>{
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.load().then(resolve);
        }, 2000);
      });
    })
  }

  get cities():ICity[] {
    return this._cities;
  }
}


const CitiesListModel = new _CitiesListModel();
export default CitiesListModel;