import { Box, BoxProps, styled } from "@mui/material";
import React from "react";
import { AppTheme } from "theme";


interface RootProps extends BoxProps {
  error?: boolean;
}

export const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<RootProps>(({ theme, error }) => ({
  width: 60,
  height: 60,
  "& input": {
    outline: 'none',
    borderRadius: 10,
    borderWidth: 0,
    borderStyle: 'solid',
    width: 60,
    height: 60,
    paddingTop: 26,
    paddingBottom: 26,
    lineHeight: "34px",
    fontSize: 34,
    textAlign: 'center',
    borderColor: AppTheme.buttonBg,
    backgroundColor: AppTheme.lightBg,
    color: AppTheme.color,
    MozAppearance: 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '@media (prefers-color-scheme: dark)': {
      borderColor: AppTheme.buttonBgDark,
      backgroundColor: AppTheme.lightBgDark,
      color: AppTheme.colorDark,
    },
    '&:focus, &:not([value=""])': {
      borderWidth: 1
    },
    '&:focus': {
      boxShadow: theme.shadows[2],
    },

    ...(error && {
      borderWidth: 1,
      borderColor: AppTheme.alarmButtonBg,
      '@media (prefers-color-scheme: dark)': {
        borderColor: AppTheme.alarmButtonBgDark,
      },
    }),
  },
}));

interface Props{
  value:string,
  error?:boolean,
  onChange: (v:string) => void,
  onFocus: () => void,
}

const  CodeFieldItem = React.forwardRef((props:Props, ref:any) => {
  const {
    value,
    error,
    onChange,
    onFocus,
  } = props;

  return (
    <Root error={error}>
      <input
        type="number"
        pattern="[0-9]*"
        maxLength={1}
        max={9}
        value={value}
        ref={ref}
        onChange={(e)=>{
          onChange(e.target.value);
        }}
        onFocus={(e)=>onFocus()}
      />
    </Root>
  );
})

CodeFieldItem.defaultProps = {
  error: false
}

export default CodeFieldItem;
