import { IRoutePoint } from "apps/WebCabApp/components/routePoints/types";

export interface IPoint{
  type: "Point",
  coordinates: [number, number],
}
export interface ILineString{
  type: "LineString",
  coordinates: ([number, number])[],
}

export interface ICar{
  brand: string;
  car_class: number;
  color: string;
  number: string;
}

export interface IDriver{
  firstname: string;
  lastname: string;
  middlename: string;
}

export interface ICallSign{
  call_sign: string;
  car: ICar;
  driver: IDriver;
  phone: string;
  another_order: boolean;
}

export enum PaymentTypes{
  CASH='CASH',
  CARD='CARD',
  COMPANY='COMPANY',
  APPLE_PAY='APPLE_PAY',
  GOOGLE_PAY="GOOGLE_PAY",
}

export interface IPayment{
  type: PaymentTypes;
  card?: number;
  company?: number;
  token?: string;
}

export interface IRoute{
  distance: number;
  duration: number;
  route: ILineString;
}

interface IClassPrice{
  id: number;
  price: number;
}

export interface IRoutePrice extends IRoute{
  class_prices: IClassPrice[]
}

export interface ITemplate{
  id?: number;
  name: string;
  comment: string;
  need_update?: boolean;
  routepoints: IRoutePoint[];
}

export class CarLocation {
  data:any;
  constructor(data:any) {
    this.data = data;
  }

  public get callsign():string {
    return this.data['cs'];
  }

  public get point():[number, number] {
    return this.data['p'];
  }

  public get status():number {
    return this.data['s'];
  }

  public get class():number {
    return this.data['c'];
  }

  public get available_classes():number[] {
    return this.data['ac'];
  }
}