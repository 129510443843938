import IOrder from "./types"

export const orderSerializer = (data:any):IOrder => {
  let order:IOrder = JSON.parse(JSON.stringify(data));
  
  if(data.date) order.date = new Date(data.date * 1000);
  return order
}

export const orderDeserializer = (data:IOrder):any => {
  let order:any = {...data};
  
  if(data.date) order.date = Math.floor(data.date.getTime() / 1000);
  return order
}