import { Box, Button, Dialog, Slide, styled, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { SxProps } from "@mui/system";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import LayoutAppBar from "apps/WebCabApp/layout/layoutAppBar";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps{
  open: boolean;
  onClose: ()=>void;
  onConfirm?: ()=>void;
  title: string;
  children: JSX.Element|JSX.Element[];
  contentProps?: {
    sx?: SxProps<Theme>
  }
}


const StyledDialogContent = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 0),
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BaseDialog(props:IProps){
  const { t } = useTranslation();

  const {
    title,
    open,
    onClose,
    onConfirm,
    children,
    contentProps
  } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
      TransitionComponent={Transition}
    >
      <LayoutAppBar
        title={title}
        onBack={onClose}
        sx={{
          position: 'static'
        }}
      />
      <StyledDialogContent sx={contentProps?.sx}>
        {children}
      </StyledDialogContent>
      {onConfirm!==undefined?(
        <BaseContainer>
          <Button
            onClick={onConfirm}
            variant='contained'
            color='primary'
            fullWidth
            sx={{
              mx: 0,
              my: 2,
            }}
          >{t('confirm')}</Button>
        </BaseContainer>
      ):null}
    </Dialog>
  )
}