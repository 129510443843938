import { Grid, styled, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { ICallSign } from "utils/types";

interface IProps{
  call_sign: ICallSign
}

const Photo = styled("img")(({ theme }) => ({
  width: 80,
  marginBottom: theme.spacing(),
}));

export default function CallSign(props:IProps) {
  const {
    call_sign
  } = props;

  const driver_name = useMemo(()=>{
    let names = [
      call_sign.driver.lastname,
      call_sign.driver.firstname,
      call_sign.driver.middlename
    ].filter((i)=>!!i)
    return names.join(" ")
  }, [
    call_sign.driver.lastname,
    call_sign.driver.firstname,
    call_sign.driver.middlename
  ])
  
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Photo src="/logo.svg" alt="" />
      </Grid>
      <Grid item>{call_sign.car.brand} {call_sign.car.color}</Grid>
      <Grid item><Typography variant="h5" sx={{
        fontWeight: 500,
        mb: 1,
      }}>{call_sign.car.number}</Typography></Grid>
      <Grid item><Typography variant="subtitle1" sx={{
        fontWeight: 500,
      }}>{driver_name}</Typography></Grid>
    </Grid>
  );
}
