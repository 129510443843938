interface SubscribeProps{
  func: (message:any)=>void;
  type: RegExp|null;
}

export interface WSMessage{
  type: string;
  data: any;
}

const WSModel = class {
  protected funcs:SubscribeProps[] = [];

  public subscribe(func:(message:WSMessage)=>void, type:RegExp|null=null) {
      this.funcs.push({
        func: func,
        type: type
      });
  }

  public unsubscribe(func:(message:WSMessage)=>void) {
      this.funcs = this.funcs.filter((s:SubscribeProps) => s.func !== func);
  }

  public onMessage(message:WSMessage) {
    this.funcs.map((s) => {
      const regex = s.type || new RegExp(".*");
      if(regex.test(message.type)){
        s.func(message);
      }
      return s;
    })
  }
}

export default new WSModel();