export const setNativeValue = (element:any, value:any) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter?.call(element, value);
  } else {
      valueSetter?.call(element, value);
  }

  element.dispatchEvent(new Event('input', { bubbles: true}));
}

export const pad = (num:number, size:number):string => {
  let res:string = num.toString();
  while (res.length < size) res = "0" + res;
  return res;
}