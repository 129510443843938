import { TextField } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import GreyLine from "apps/WebCabApp/components/greyLine";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import AppModel from "models/AppModel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceCheckList from "../serviceCheckList";
import BaseDialog from "./baseDialog";

interface IProps{
  open: boolean;
  onClose: ()=>void;
}

export default function ServicesDialog(props:IProps){
  const { t } = useTranslation();
  const {newOrder, setNewOrder} = useNewOrder();
  const [comment, setComment] = useState<string>("");
  const [services, setServices] = useState<number[]>([]);

  useEffect(()=>{
    setComment(newOrder.comment || "");
    setServices(newOrder.additionalservices || []);
  }, [newOrder])

  const {
    open,
    onClose
  } = props;

  const onDone = () => {
    setNewOrder({
      ...newOrder,
      comment: comment,
      additionalservices: [...services]
    })
    onClose();
  }

  return (
    <BaseDialog
      open={open}
      onClose={onDone}
      title={t('services_title')}
      onConfirm={onDone}
    >
      <BaseContainer>
        <TextField
          placeholder={t('write_note')}
          multiline
          fullWidth
          value={comment}
          onChange={(event:any)=>{
            setComment(event.target.value)
          }}
        />
      </BaseContainer>

      <GreyLine
        title={t('services')}
        sx={{
          mx: 0,
          my: 2,
        }}
      />

      <BaseContainer>
        <ServiceCheckList
          date={newOrder.date || null}
          items={AppModel.city?.options || []}
          value={services}
          onChange={setServices}
        />
      </BaseContainer>
    </BaseDialog>
  )
}