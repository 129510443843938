import { WSModel } from 'apps/WebCabApp/ws';
import { WSMessage } from 'apps/WebCabApp/ws/WSModel';
import React, { useState, useCallback, useEffect } from 'react';
import { CarLocation } from 'utils/types';
import CarsContext, { ProviderContext } from './CarsContext';


interface IProps{
  children: React.ReactNode;
}

export default function CarsProvider(props: IProps) {
  const [cars, setCars] = useState<CarLocation[]>([])

  useEffect(()=>{
    const WS = (message:WSMessage) => {
      const _cars = message.data.map((i:any)=>new CarLocation(i));
      setCars(_cars);
    }
    WSModel.subscribe(WS, /^webcab\.car\.location$/);
    return () => {
      WSModel.unsubscribe(WS);
    }
  }, [])

  const getCarByCallSign = useCallback((callsign:string):CarLocation|null=>{
    for(let i=0; i<cars.length; i++){
      if(cars[i].callsign === callsign){
        return cars[i];
      }
    }
    return null;
  }, [cars])

  const context:ProviderContext = {
    cars: cars,
    getCarByCallSign: getCarByCallSign,
  }

  return (
    <CarsContext.Provider value={context}>
        {props.children}
    </CarsContext.Provider>
  );
}