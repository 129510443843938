import FirebaseAnalytics from "component/firebaseAnalytics";
import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./layout";
import routes from "./routes";

function AuthApp() {
  return (
    <BrowserRouter>
      <FirebaseAnalytics/>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            exact={true}
            path={route.path}
            render={(props) => (
              <Layout titleKey={route.name_key}>
                <route.component
                  {...props}
                />
              </Layout>
            )}
          />
        ))}
        <Route path='*' exact={true} render={() => (<Redirect to="/" />)} />
      </Switch>
      </BrowserRouter>
  );
}

export default AuthApp;
