import { AppTheme } from 'theme';

export default function ImageIconMaker(url:string):HTMLImageElement {
  const color = AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg;
  let image = new Image();
  fetch(url).then((r)=>{
    return r.text();
  }).then((data)=>{
    data = data.replaceAll('fill="current"', `fill="${color}"`)
    data = data.replaceAll('stroke="current"', `stroke="${color}"`)
    image.src = "data:image/svg+xml;base64,"+Buffer.from(data).toString('base64');
  })

  return image
}