import AuthModel from "models/AuthModel";
import { API_CONFIG, DEFAULT_LANGUAGE } from "config";
import i18n from "i18n";
import FirebaseLog from "component/firebaseLog";

export interface IResponse{
    status: number,
    body: any|null,
    without_error?: boolean,
}

export class APIRequest{
    _with_error_message = true;
    _watcher:null|string = null;

    public withoutErrorMessage = () => {
        let r = new APIRequest()
        r._with_error_message = false;
        r._watcher = this._watcher;
        return r;
    }

    public isWatcher = () => !!this._watcher;
    public setWatcher = (watcher:null|string) => {
        this._watcher = watcher;
        if(watcher === null){
            this._with_error_message = true;
        }else{
            this._with_error_message = false;
        }
    }

    protected _remove_session = () => {
        if(!this.isWatcher()){
            AuthModel.removeSession();
        }
    }

    private _response = async (response:Response):Promise<IResponse> => {
        let body:object|string|null = null;
        try {
            body = await response.clone().json()
        }
        catch (e) {
            try{
                body = await response.text();
            } catch (e) {
                body = null;
            }
        }

        if(response.status === 401){
            this._remove_session();
        }

        return {
            status: response.status,
            body: body,
            without_error: !this._with_error_message
        }
    }

    public authorization_data = ():null|[string, string] => {
        if(!!this._watcher){
            return ['WATCHER_HASH', this._watcher]
        }
        let session = AuthModel.session();
        if(session){
            return ['PHPSESSID', session]
        }
        return null
    }

    private _auth_headers = () => {
        const auth_data = this.authorization_data();

        let headers:Record<string, string> = {
            'API-KEY': API_CONFIG.api_key,
            ...(auth_data !== null ? {
                'Authorization': auth_data.join(" ")
            } : {})
        }
        return headers;
    }

    public getCurrentLng = () => i18n.language || window.localStorage.i18nextLng as string || DEFAULT_LANGUAGE;

    private _headers = () => {
        let headers:Record<string, string> = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': this.getCurrentLng()
        }
        return {...this._auth_headers(), ...headers};
    }

    private _get_full_url = (url:string, params:any|null):string => {
        let _url = `${API_CONFIG.api_base_url}${url}`;
        if(params !== null){
            let str_params = Object.keys(params).map(function(key) {
                return key + '=' + params[key];
            }).join('&');
            _url += "?" + str_params
        }
        return _url;
    }

    private _do_fetch = async (request:Request, f:Promise<Response>):Promise<IResponse|null> => {
        let promise = new Promise<IResponse|null>(async (resolve, reject) => {
            let response:Response = new Response(null, {status: 500});
            try{
                response = await f;
            } catch (e:any) {
                if(e.name === "AbortError"){
                    FirebaseLog.request_error(request)
                    reject(null);
                    return;
                }
            }
            const res = await this._response(response);
            if(res.status >= 400 && ![401].includes(res.status)){
                FirebaseLog.request_error(request, res)
            }

            if(res.status >= 200 && res.status < 300){
                resolve(res);
            }else{
                reject(res);
            }
        })
        return await promise;
    }

    private _fetch = async (url:string, params:any|null):Promise<any> => {
        const request = new Request(url, params);
        const res = fetch(request);
        
        const response_promise = new Promise<IResponse|null>(async (resolve, reject) => {
            resolve(this._do_fetch(request, res));
        })
        var evt = new CustomEvent("StartRequest", {detail: response_promise});
        window.dispatchEvent(evt);
        return await this._do_fetch(request, res);
    }

    public get_base_url = (url:string, params?:any|null):string => {
        const base_url = new URL(API_CONFIG.api_base_url);
        let _url = `${base_url.origin}${url}`;
        if(params !== null && params !== undefined){
            let str_params = Object.keys(params).map(function(key) {
                return key + '=' + params[key];
            }).join('&');
            _url += "?" + str_params
        }
        return _url;
    }
    
    public get = async (url:string, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            headers: this._headers(),
            signal: signal
        })
        return res
    }
    public getAll = async (url:string, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        let res = await this.get(url, {
            ...params,
            'offset': 0
        }, signal);
        if(res?.body?.count && res?.body?.results){
            let count = res.body.results.length;
            while(res.body.count > count){
                const _res = await this.get(url, {
                    ...params,
                    'offset': count
                }, signal);
                res.body.results = res.body.results.concat(_res.body.results)
                count = res.body.results.length;
            }
        }
        return await Promise.resolve(res);
    }

    public post = async (url:string, body:object|null=null, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'POST',
            body: body ? JSON.stringify(body) : null,
            headers: this._headers(),
            signal: signal
        })
        return res
    }
    public put = async (url:string, body:object|null=null, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'PUT',
            body: body ? JSON.stringify(body) : null,
            headers: this._headers(),
            signal: signal
        })
        return res
    }
    public patch = async (url:string, body:object|null=null, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'PATCH',
            body: body ? JSON.stringify(body) : null,
            headers: this._headers(),
            signal: signal
        })
        return res
    }
    public delete = async (url:string, params:any|null=null, signal:AbortSignal|null=null):Promise<IResponse> => {
        const res = await this._fetch(this._get_full_url(url, params), {
            method: 'DELETE',
            headers: this._headers(),
            signal: signal
        })
        return res
    }
}

const request = new APIRequest();
export default request