import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as MyOrdersIcon } from 'images/menu/my_orders.svg';
import { ReactComponent as TemplatesIcon } from 'images/menu/templates.svg';
import { ReactComponent as PaymentIcon } from 'images/menu/payment.svg';
import { ReactComponent as HelpIcon } from 'images/menu/help.svg';
import { ReactComponent as FeedbackIcon } from 'images/menu/feedback.svg';
import AppModel from 'models/AppModel';
import { Link } from 'react-router-dom';

export enum menuTypes{
  currentTrips='currentTrips',
  trips='trips',
  templates='templates',
  payment='payment',
  help='help',
  feedback='feedback',
  settings='settings',
  info='info',
}

export interface IMenu{
  title_key: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  getProps?: ()=>{[key: string]: any};
  isHide?: ()=>boolean;
  isAutoCloseMenu?: ()=>boolean;
}

export const menuItems:{[key in menuTypes]: IMenu} = {
  currentTrips: {
    title_key: 'active_orders',
    getProps: ()=>({
      component: Link,
      to: '/current-trips'
    })
  },
  trips: {
    title_key: 'my_orders_title',
    icon: MyOrdersIcon,
    getProps: ()=>({
      component: Link,
      to: '/trips'
    })
  },
  templates: {
    title_key: 'templates_title',
    icon: TemplatesIcon,
    getProps: ()=>({
      component: Link,
      to: '/templates'
    })
  },
  payment: {
    title_key: 'payment',
    icon: PaymentIcon,
    getProps: ()=>({
      component: Link,
      to: '/payments'
    })
  },
  help: {
    title_key: 'help',
    icon: FeedbackIcon,
    getProps: ()=>{
      if(AppModel.city === null) return {};
      else if(AppModel.city?.phones.length>1) return {};
      else return {
        component: 'a',
        href: `tel:${AppModel.city?.primary_phone}`
      };
    },
    isHide: ()=>!AppModel.city?.phones.length,
    isAutoCloseMenu: ()=>(AppModel.city !== null && AppModel.city.phones.length>1)
  },
  feedback: {
    title_key: 'write_to_devs',
    icon: HelpIcon,
    getProps: ()=>({
      component: Link,
      to: '/feedback'
    })
  },
  settings: {
    title_key: 'settings',
    getProps: ()=>({
      component: Link,
      to: '/settings'
    })
  },
  info: {
    title_key: 'info',
    getProps: ()=>({
      component: Link,
      to: '/info'
    })
  }
}