import { Box, Divider, TextField } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import GreyLine from "apps/WebCabApp/components/greyLine";
import RoutePointsField from "apps/WebCabApp/components/routePoints/field";
import { IRoutePoint } from "apps/WebCabApp/components/routePoints/types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "./baseDialog";

interface IProps{
  open: boolean;
  routepoints: IRoutePoint[];
  driverComment?: string|null;
  onChange: (routepoints:IRoutePoint[], index:number|"frontdoor")=>void;
  onChangeDriverComment: (value:string) => void;
  onClose: ()=>void;
  activeAddress: number|"frontdoor"|null;
}

export default function AddressSearchDialog(props:IProps){
  const { t } = useTranslation();

  const [focusIndex, setFocusIndex] = useState(0);
  const [resultsElement, setResultsElement] = useState<JSX.Element|null>(null)

  const {
    open,
    driverComment,
    onChange,
    onClose,
    onChangeDriverComment,
    activeAddress
  } = props;

  const routepoints = useMemo(()=>{
    return props.routepoints.map((i)=>{
      if(i.poi?.id===0){
        return {}
      }
      return i;
    })
  }, [props.routepoints])

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={focusIndex===0?t('starting_point'):t('destination_point')}
      contentProps={{
        sx: {
          overflow: 'hidden',
          paddingTop: 0,
          pb: 4,
        }
      }}
    >
      <Box sx={{
        flexGrow: 0,
        flexShrink: 0,
        pt: 3
      }}>
        <BaseContainer>
          <RoutePointsField
            value={routepoints}
            autoFocus={activeAddress}
            onChange={onChange}
            onRenderResults={setResultsElement}
            onChangeFocusIndex={setFocusIndex}
            onChangeDriverComment={onChangeDriverComment}
          />
        </BaseContainer>

        <Divider sx={{
          mt: 3,
          mb: 4,
        }}/>
      </Box>
      
      
      {resultsElement!==null?resultsElement:(
        <React.Fragment>
          <GreyLine
            title={t('clarification')}
            sx={{
              mt: -4,
              mb: 2,
            }}
          />

          <BaseContainer sx={{
            overflow: 'hidden',
            "& *": {
              height: '100%',
              maxHeight: '100%',
            },
            "& textarea": {
              overflow: 'auto !important',
              WebkitOverflowScrolling: 'touch',
            }
          }}>
            <TextField
              placeholder={t('write_note')}
              multiline
              fullWidth
              value={driverComment || ""}
              onChange={(event:any)=>{
                onChangeDriverComment(event.target.value)
              }}
            />
          </BaseContainer>
        </React.Fragment>
      )}
    </BaseDialog>
  )
}