import { Box, styled, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { AppTheme } from "theme";
import BaseContainer from "./baseContainer";

const Root = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  backgroundColor: AppTheme.lightBg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.lightBgDark,
  },
  '& >*': {
    opacity: 0.5
  }
}));

interface IProps{
  title: string,
  sx?: SxProps<Theme>,
}

export default function GreyLine(props:IProps) {
  return (
    <Root sx={props.sx}>
      <BaseContainer>
        <Typography variant='body2'>{props.title}</Typography>
      </BaseContainer>
    </Root>
  );
}
