import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useProgress } from "component/progress";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IOrder from "utils/order/types";
import { requests } from "utils/requests";


interface IProps{
  order: IOrder|null;
  onClose: ()=>void;
  onSaved: (order:IOrder)=>void;
}

export default function DriverMessageDialog(props: IProps) {
  const { t } = useTranslation();
  const {isProgress} = useProgress();
  const [message, setMessage] = useState<string>("");

  const {
    order,
    onClose,
    onSaved
  } = props

  useEffect(()=>{
    setMessage((order?.comment || "").toString())
  }, [order])

  const onSubmit = () => {
    if(order===null) return;

    requests.put(`/order/${order.id}/comment/`, {
      comment: message
    }).then((r)=>{
      const _order:IOrder = {
        ...order,
        comment: message
      };
      onSaved(_order);
    })
  }

  return (
    <Dialog
      open={props.order!==null}
      onClose={onClose}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle>{t('notes_title')}</DialogTitle>
      <DialogContent sx={{
        pb: 3
      }}>
        <TextField
          placeholder={t('write_note')}
          multiline
          fullWidth
          disabled={isProgress}
          value={message}
          onChange={(e)=>{
            setMessage(e.target.value);
          }}
        />
        <br/><br/>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          size="large"
          disabled={isProgress}
          fullWidth
        >{t('confirm')}</Button>
      </DialogContent>
    </Dialog>
  )
}