import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import NumberFormat from "react-number-format";


const Root = styled("b")(({ theme }) => ({
  root: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: theme.spacing(0.2, 1),
    borderColor: AppTheme.buttonBg,
    color: AppTheme.darkColor,
    '@media (prefers-color-scheme: dark)': {
      borderColor: AppTheme.buttonBgDark,
      color: AppTheme.darkColorDark,
    },
  }
}));

interface IProps{
  distance?: number
}

export default function OrderDistance(props:IProps) {
  const { t } = useTranslation();

  const {
    distance
  } = props

  if(!distance) return null;

  return (
    <Root>
      <NumberFormat
        value={distance}
        displayType={'text'}
        thousandSeparator={' '}
        suffix={t('distance').replace("%s", " ")}
        decimalScale={1}
        fixedDecimalScale={true}
      />
    </Root>
  )
}
