import { Box, Grid, styled, Typography } from "@mui/material";
import IOrder from "utils/order/types";
import { useMemo } from "react";
import { requests } from "utils/requests";
import PaymentType from "./paymentType";
import Services from "./services";
import Money from "utils/money";
import { useAppModel } from "models/AppModel";
import { ReactComponent as PassengerIcon } from 'images/passenger.svg';
import { AppTheme } from "theme";
import { useTranslation } from "react-i18next";


const StyledOrderClassGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 500,
  lineHeight: 1,
  '& img': {
    height: 25,
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
  },
  '&>div':{
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '30%',
    fontSize: "0.9em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.7em",
    }
  }
}));

interface IProps{
  order: IOrder;
  withPrice?: boolean;
  withPassenger?: boolean;
}

export default function OrderInfoLine(props:IProps) {
  const { t } = useTranslation();
  const { getClassByID } = useAppModel();

  const {
    order,
    withPassenger,
    withPrice
  } = props

  const order_class = useMemo(()=>{
    if(!order) return null;
    return getClassByID(order?.car_class)
  }, [order, getClassByID])

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      spacing={3}
    >
      <Grid item style={{
        overflow: 'hidden',
        flexGrow: 1,
        flexShrink: 1
      }}>
        <StyledOrderClassGrid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          wrap="nowrap"
          spacing={2}
        >
          {order_class ? (
            <Grid item>
              <div className="toggle-theme">
                <img src={requests.get_base_url(order_class.images.svg__light)} alt="" className="light" />
                <img src={requests.get_base_url(order_class.images.svg__dark)} alt="" className="dark" />
              </div>
              {order_class.name}
            </Grid>
          ):null}

          {order.payment!==undefined ?(
            <Grid item>
                <PaymentType
                  payment={order.payment}
                />
            </Grid>
          ):null}

          {order.additionalservices !== undefined && order.additionalservices.length ? (
            <Grid item>
              <Services
                services={order.additionalservices}
              />
            </Grid>
          ):null}

          {Boolean(withPassenger) ? (
            <Grid item>
              <Box sx={{
                marginBottom: "5px"
              }}>
                <PassengerIcon
                  stroke={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
                  height={23}
                />
              </Box>
              {order.other_passenger ? order.other_passenger.name : t('im_going')}
            </Grid>
          ):null}
        </StyledOrderClassGrid>
      </Grid>
      
      {Boolean(withPrice)?(
        <Grid item sx={{
          color: AppTheme.darkColor,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
          }
        }}>
          <Typography variant="h5" noWrap>
            <b>
              <Money
                value={order.price}
              />
            </b>
          </Typography>
        </Grid>
      ):null}
    </Grid>
  )
}
