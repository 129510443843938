import React, { useState, useEffect } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import NetworkAvailableContext, { ProviderContext } from './NetworkAvailableContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoInternetIcon } from 'images/no_internet.svg';


interface NetworkAvailableProviderProps{
  children: React.ReactNode;
}

export default function NetworkAvailableProvider(props: NetworkAvailableProviderProps) {
  const { t } = useTranslation();

  const [available, setAvailable] = useState(window.navigator.onLine);
  
  useEffect(()=>{
    const online = () => {
      setAvailable(true);
    }
    const offline = () => {
      setAvailable(false);
    }

    window.addEventListener('online', online);
    window.addEventListener('offline', offline);

    return () => {
      window.removeEventListener('online', online);
      window.removeEventListener('offline', offline);
    }
  }, [])

  const context:ProviderContext = {
    networkAvailable: available
  }

  return (
    <NetworkAvailableContext.Provider value={context}>
        {props.children}

        <Dialog
          open={!available}
          maxWidth="xs"
        >
          <Box sx={{
            maxWidth: 300,
            textAlign: 'center',
            p: 2,
          }}>
            <Typography variant="subtitle1">
              <NoInternetIcon
                width="80%"
              />
              <br/>
              {t('no_internet')}
            </Typography>
          </Box>
        </Dialog>
    </NetworkAvailableContext.Provider>
  );
}