import { Box, Button, Divider, List, ListItem, ListItemText, styled, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import NotFound from "apps/WebCabApp/components/notFound";
import OrderDistance from "apps/WebCabApp/components/orderDistance";
import OrderInfoLine from "apps/WebCabApp/components/orderInfoLine";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import useLayout from "apps/WebCabApp/layout/useLayout";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import useOrderRating from "apps/WebCabApp/providers/OrderRatingProvider/useOrderRating";
import { format } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { orderSerializer } from "utils/order/orderSerializer";
import IOrder from "utils/order/types";
import { requests } from "utils/requests";
import { TRIP_ORDER_STATUSES } from "../trips";
import CallSign from "./callSign";
import RatingButton from "./ratingButton";


const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function OneTrip() {
  const match:any = useRouteMatch();
  const { t } = useTranslation();
  const {setTitle} = useLayout();
  const [order, setOrder] = useState<IOrder|null>(null)
  const [notFound, setNotFound] = useState(false);
  const confirm = useConfirm();
  const history = useHistory();
  const {doRating} = useOrderRating();
  const {setNewOrder} = useNewOrder();

  const onDelete = () => {
    confirm({
      title: null,
      description: (
        <Typography align='center' component='span' style={{display: 'block'}}>
          {t('delete_order_title')}
        </Typography>
      ),
      cancellationText: t('no'),
      confirmationText: t('yes'),
    }).then(() => {
      requests.delete(`/order/${match.params.id}/`).then(()=>{
        if(history.length){
          history.goBack()
        }else{
          history.push('/')
        }
      })
    });
  }

  const load = useCallback(() => {
    requests.withoutErrorMessage().get(`/order/${match.params.id}/`, {
      status__in: TRIP_ORDER_STATUSES.join(",")
    }).then((r)=>{
      const _order = orderSerializer(r.body);
      setTitle(format(_order.date as Date, "dd.MM.yyyy HH:mm"));
      setOrder(_order);
    }).catch((e)=>{
      if(e.status === 404){
        setNotFound(true);
      }
    })
  }, [match.params.id, setTitle])

  useEffect(load, [load])

  useEffect(()=>{
    if(window.location.hash === "#rating" && order !== null && order.rating?.available){
      doRating(order.id || 0, 0).then(load)
    }
  }, [load, order, doRating])

  const repeat = (item:IOrder) => {
    setNewOrder((o)=>{
      o.routepoints = [...item.routepoints];
      o.comment = item.comment;
      return {...o}
    })
    history.push('/');
  }

  const onRepeatTrip = () => {
    if(order!==null){
      repeat(order);
    }
  }

  const onRepeatBackwardsTrip = () => {
    if(order!==null){
      const _order:IOrder = {
        ...order,
        routepoints: [...order.routepoints]
      };
      _order.routepoints.reverse();
      repeat(_order);
    }
  }

  if(notFound){
    return (<NotFound/>)
  }

  if(order === null){
    return null
  }
  
  return (
    <BaseContainer>
      {order.call_sign ? (
        <React.Fragment>
          <CallSign
            call_sign={order.call_sign}
          />
          <StyledDivider/>
        </React.Fragment>
      ):null}
      <RoutePoints
        routepoints={order.routepoints}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          my: 1,
        }}
      >
        <Box>
          <OrderDistance distance={order.distance}/>
        </Box>
        <Box>
          <Button
            variant='contained'
            color='primary'
            size='small'
            component={Link}
            to={`/trips/${order.id}/map`}
          >{t('route_on_map')}</Button>
        </Box>
      </Box>
      <StyledDivider/>

      <OrderInfoLine
        order={order}
        withPrice
      />

      <StyledDivider sx={{mb: 0}}/>
      <List disablePadding>
        {order.rating?.available || order.rating?.value ? (
          <React.Fragment>
            <ListItem disableGutters>
              <ListItemText primary={(
                <RatingButton
                  order={order}
                  onRatingAdded={load}
                />
              )} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ):null}
        <ListItem disableGutters button onClick={onRepeatTrip}>
          <ListItemText primary={t('repeat_order')} />
          <ChevronRight/>
        </ListItem>
        <ListItem disableGutters button onClick={onRepeatBackwardsTrip}>
          <ListItemText primary={t('ride_backwards')} />
          <ChevronRight/>
        </ListItem>
        <ListItem disableGutters button onClick={onDelete}>
          <ListItemText primary={t('delete')} />
          <ChevronRight/>
        </ListItem>
      </List>
      <StyledDivider sx={{mt: 0}}/>
    </BaseContainer>
  );
}
