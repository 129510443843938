import { keyframes, styled } from "@mui/material";
import clsx from "clsx";
import React, { LegacyRef } from "react";
import { AppTheme } from "theme";
import { ReactComponent as MapStartPointIcon } from 'images/map_start_point.svg';

interface IProps{
  active: boolean;
}

const PointerShadowAnimation = keyframes({
  "0%": {
    transform: "scale(1.3)"
  },
  "12%": {
    transform: "scale(1.25)"
  },
  "24%": {
    transform: "scale(1.1)"
  },
  "36%": {
    transform: "scale(1)"
  },
  "54%": {
    transform: "scale(1.15)"
  },
  "74%": {
    transform: "scale(1)"
  },
  "82%": {
    transform: "scale(1.1)"
  },
  "92%": {
    transform: "scale(1)"
  },
  "96%": {
    transform: "scale(1.05)"
  },
  "100%": {
    transform: "scale(1)"
  }
})

const PointerAnimation = keyframes({
  "0%": {
    transform: "translateY(-50%)"
  },
  "12%": {
    transform: "translateY(-45%)"
  },
  "24%": {
    transform: "translateY(-28%)"
  },
  "36%": {
    transform: "translateY(0%)"
  },
  "54%": {
    transform: "translateY(-20%)"
  },
  "74%": {
    transform: "translateY(0%)"
  },
  "82%": {
    transform: "translateY(-10%)"
  },
  "92%": {
    transform: "translateY(0%)"
  },
  "96%": {
    transform: "translateY(-5%)"
  },
  "100%": {
    transform: "translateY(0%)"
  },
})

const StyledPointerPixel = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
  width: 1,
  height: 1
}));

const StyledPointerPixelContainer = styled("div")(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: -5,
    left: -10,
    width: 21,
    height: 10,
    borderRadius: "50%",
    zIndex: 1,
    opacity: 0.3,
    backgroundColor: theme.palette.grey[400],
    animation: `${PointerShadowAnimation} 500ms linear`,
  },

  "& .pointer": {
    content: '""',
    position: 'relative',
    top: -25,
    left: -1,
    width: 3,
    height: 25,
    borderBottomLeftRadius: 1,
    borderBottomRightRadius: 1,
    zIndex: 2,
    backgroundColor: AppTheme.buttonBgDark,
    animation: `${PointerAnimation} 500ms linear`,
    
    '&>svg': {
      position: 'absolute',
      top: -16,
      left: -12,
      width: 27,
      height: 27,
      zIndex: 3,
    },
  },

  "&.active": {
    '&:before': {

      animation: "none",
      transition: `transform 300ms`,
      transform: 'scale(1.3)'
    },

    "& .pointer": {
      animation: "none",
      transition: "transform 300ms",
      transform: "translateY(-50%)"
    }
  }
}));

export const MapPointer = React.forwardRef((props: IProps, ref:LegacyRef<HTMLDivElement>) => {
  return (
    <StyledPointerPixel ref={ref}>
      <StyledPointerPixelContainer className={clsx({
        'active': props.active
      })}>
        <div className="pointer">
          <MapStartPointIcon
            fill={AppTheme.buttonBg}
            stroke={AppTheme.buttonBg}
          />
        </div>
      </StyledPointerPixelContainer>
    </StyledPointerPixel>
  )
})