import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import IOrder from "utils/order/types";
import PhoneIcon from '@mui/icons-material/Phone';
import { requests } from "utils/requests";

interface IProps{
  order: IOrder|null;
  onClose: ()=>void;
  onCall: ()=>void;
}

export default function ArrivedOrderDialog(props: IProps) {
  const { t } = useTranslation();

  const {
    order,
    onClose,
    onCall
  } = props

  return (
    <Dialog
      open={order!==null}
      onClose={onClose}
      maxWidth='xs'
    >
      <DialogTitle>{t('car_arrived')}</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item>
            <Typography>
              {order?.call_sign?.driver.firstname}<br/>
              {order?.call_sign?.car.brand} {order?.call_sign?.car.color}<br/>
              <b>{order?.call_sign?.car.number}</b>
            </Typography>
          </Grid>
          {!requests.isWatcher() && (
            <Grid item>
              <Fab
                color="secondary"
                size="small"
                onClick={onCall}
              >
                <PhoneIcon />
              </Fab>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
        >{t('ok')}</Button>
      </DialogActions>
    </Dialog>
  )
}