import React, { useState, useCallback } from 'react';
import { LinearProgress, styled } from '@mui/material';
import ProgressContext, { ProviderContext } from './ProgressContext';


const StyledLoading = styled("div")(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: theme.zIndex.drawer * theme.zIndex.drawer,
}));

interface ProgressProviderProps{
  children: React.ReactNode;
}

export default function ProgressProvider(props: ProgressProviderProps) {

  const [showProgress, setShowProgress] = useState(false);
  
  const show = useCallback(() => {
    setShowProgress(true);
  }, [])
  const hide = useCallback(() => {
    setShowProgress(false);
  }, [])

  const context:ProviderContext = {
    showProgress: show,
    hideProgress: hide,
    setProgress: setShowProgress,
    isProgress: showProgress
  }

  return (
    <ProgressContext.Provider value={context}>
        {props.children}
        {showProgress && (
          <StyledLoading>
            <LinearProgress />
          </StyledLoading>
        )}
    </ProgressContext.Provider>
  );
}