import CurrentTrips from "./pages/currentTrips";
import Feedback from "./pages/feedback";
import Info from "./pages/info";
import PrivacyPolicy from "./pages/info/privacyPolicy";
import MapTrip from "./pages/mapTrip";
import OneTrip from "./pages/oneTrip";
import Payments from "./pages/payments";
import Settings from "./pages/settings";
import Start from "./pages/start";
import Templates from "./pages/templates";
import TemplateAdd from "./pages/templates/templateAdd";
import TemplateEdit from "./pages/templates/templateEdit";
import Trips from "./pages/trips";

export interface IRoute{
  name_key?: string;
  path: string;
  component: React.ComponentType<any>|null;
}

let routes:IRoute[] = [
  {
    path: "/",
    component: Start
  },
  {
    name_key: "",
    path: "/trips/:id/map",
    component: MapTrip
  },
  {
    name_key: "",
    path: "/trips/:id",
    component: OneTrip
  },
  {
    name_key: "my_orders_title",
    path: "/trips",
    component: Trips
  },
  {
    name_key: "write_to_devs",
    path: "/feedback",
    component: Feedback
  },
  {
    name_key: "app_settings",
    path: "/settings",
    component: Settings
  },
  {
    name_key: "info",
    path: "/info",
    component: Info
  },
  {
    name_key: "policy_title",
    path: "/privacy-policy",
    component: PrivacyPolicy
  },
  {
    name_key: "templates_title",
    path: "/templates",
    component: Templates
  },
  {
    name_key: "new_template",
    path: "/templates/add",
    component: TemplateAdd
  },
  {
    name_key: "",
    path: "/templates/:id",
    component: TemplateEdit
  },
  {
    name_key: "payment_method_title",
    path: "/payments",
    component: Payments
  },
  {
    name_key: "",
    path: "/current-trips",
    component: CurrentTrips
  },
  {
    name_key: "",
    path: "/current-trips/:id",
    component: CurrentTrips
  },
]

export default routes;