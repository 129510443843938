import { Container, Grid, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";

interface IProps{
  sx?: SxProps<Theme>;
  children: any;
  fake?: boolean;
}

export default function BaseContainer(props:IProps) {
  if(props.fake){
    return props.children;
  }
  
  return (
    <Container sx={props.sx}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={7} md={5} lg={4}>
          {props.children}
        </Grid>
      </Grid>
    </Container>
  );
}
