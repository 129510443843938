import { List, styled } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import CheckListItem from "apps/WebCabApp/components/checkListItem";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "../baseDialog";
import TimePickerDialog from "./timePickerDialog";
import { format } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import ServiceCheckList from "../../serviceCheckList";
import AppModel from "models/AppModel";
import { AppTheme } from "theme";

interface IProps{
  open: boolean;
  onClose: ()=>void;
}

const KievTime = styled("span")(({ theme }) => ({
  color: AppTheme.alarmButtonBg,
  '@media (prefers-color-scheme: dark)': {
    color: AppTheme.alarmButtonBgDark,
  }
}));

export default function TimeDialog(props:IProps){
  const { t } = useTranslation();
  const {newOrder, setNewOrder} = useNewOrder();
  const [dateTime, setDateTime] = useState<Date|null>(null);
  const [services, setServices] = useState<number[]>([]);
  const [selecterOpen, setSelecterOpen] = useState(false);

  const {
    open,
    onClose
  } = props;

  useEffect(()=>{
    setDateTime(newOrder.date ? new Date((newOrder.date || new Date()).getTime()) : null)
    setServices(newOrder.additionalservices || []);
  }, [newOrder])

  const submitHandler = (value:Date|null) => {
    if(value === null){
      setNewOrder({
        ...newOrder,
        date: undefined,
        pre_order: false,
        additionalservices: [...services]
      })
    }else{
      setNewOrder({
        ...newOrder,
        date: value,
        pre_order: true,
        additionalservices: [...services]
      })
    }
    onClose();
  }

  const clearServicesByDateTimeNull = () => {
    setServices((AppModel.city?.options || []).filter((i)=>services.includes(i.id) && !i.pre_order).map((i)=>i.id))
  }

  const localTimeFormat:string|null = useMemo(()=>{
    if(dateTime === null) return null;
    return format(dateTime, "dd.MM.yyyy HH:mm")
  }, [dateTime])

  const kievTimeFormat:string|null = useMemo(()=>{
    if(dateTime === null) return null;
    return formatToTimeZone(dateTime, "DD.MM.YYYY HH:mm", {
      timeZone: "Europe/Kiev",
      // timeZone: "Europe/Berlin"
    })
  }, [dateTime])

  return (
    <BaseDialog
      open={open}
      onClose={()=>submitHandler(dateTime)}
      onConfirm={()=>submitHandler(dateTime)}
      title={t('order_time_title')}
    >
      <BaseContainer>
        <List>
          <CheckListItem
            title={t('asap')}
            type="list"
            checked={dateTime===null}
            onChange={()=>{
              setDateTime(null)
              clearServicesByDateTimeNull();
            }}
          />
          <CheckListItem
            title={localTimeFormat!==null ? localTimeFormat : t('select_date_time')}
            subtitle={kievTimeFormat!==null && localTimeFormat !== kievTimeFormat ? (
              <KievTime>{t('kyiv_time').replace("%s", kievTimeFormat)}</KievTime>
            ) : null}
            type="list"
            checked={dateTime!==null}
            onChange={()=>{
              setSelecterOpen(true);
            }}
          />
        </List>

        <ServiceCheckList
          date={dateTime}
          items={(AppModel.city?.options || []).filter((i)=>i.pre_order)}
          value={services}
          onChange={setServices}
        />
      </BaseContainer>

      <TimePickerDialog
        open={selecterOpen}
        value={dateTime}
        onChange={(value)=>{
          setDateTime(value);
          setSelecterOpen(false);
        }}
        onClose={()=>{
          setSelecterOpen(false);
        }}
      />

    </BaseDialog>
  )
}