import { Button, Grid, List, ListItem } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import request from "utils/requests/request";
import BaseDialog from "./baseDialog";
import parse from 'html-react-parser'
import AppModel from "models/AppModel";
import NumberFormat from "react-number-format";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

interface IProps{
  open: boolean;
  onClose: ()=>void;
}

interface IIndividualBrand{
  brand: string;
  price: number;
}

interface IIndividual{
  title: string,
  description: string,
  url?: string,
  brands: IIndividualBrand[]
}


export default function IndividualDialog(props:IProps){
  const { t } = useTranslation();
  const [data, setData] = useState<IIndividual|null>(null);

  const {
    open,
    onClose
  } = props;

  useEffect(()=>{
    if(open && data===null){
      request.get("/individual/").then((r)=>{
        setData(r.body);
      })
    }
  }, [open, data])

  return (
    <BaseDialog
      open={open && data!==null}
      onClose={onClose}
      title={data?.title || ""}
    >
      <BaseContainer>
        {parse(data?.description || "")}

        <Button
          fullWidth
          variant="contained"
          color="primary"
          href={`tel:${AppModel.city?.primary_phone}`}
          target="_blank"
          sx={{
            fontSize: "1.2em",
            mt: 3,
            mb: 2
          }}
        >
          <PhoneOutlinedIcon fontSize="small" />&nbsp;&nbsp;
          <NumberFormat
            value={AppModel.city?.primary_phone.replace("+38", "")}
            displayType={'text'}
            format="(###) ###-#-###"
          />
        </Button>

        <List sx={{
          fontSize: "1.2em",
          margin: 0,
          padding: 0
        }}>
          {(data?.brands || []).map((i)=>(
            <ListItem
              key={i.brand}
              dense
              disableGutters
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Grid item sx={{
                  fontWeight: "bold",
                }}>{i.brand}</Grid>
                <Grid item sx={{
                  whiteSpace: 'nowrap'
                }}>
                  <NumberFormat
                    value={i.price}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                  &nbsp;{t('uah_hour')}
                </Grid>
              </Grid>
              
            </ListItem>
          ))}
        </List>

        {data?.url?(
          <Button
            fullWidth
            variant="contained"
            color="primary"
            href={data?.url}
            target="_blank"
            sx={{
              fontSize: "1.2em",
              mt: 2
            }}
          >
            {t('read_more')}
          </Button>
        ):null}
      </BaseContainer>
    </BaseDialog>
  )
}