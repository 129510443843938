import Watcher from "./watcher";

export interface IRoute{
  path: string;
  component: React.ComponentType<any>;
}

let routes:IRoute[] = [
  {
    path: "/watch/:hash",
    component: Watcher
  }
]

export default routes;