import { List } from "@mui/material";
import CheckListItem from "apps/WebCabApp/components/checkListItem";
import ImgColor from "apps/WebCabApp/components/imgColor";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import { useMessage } from "component/message";
import AppModel, { IOption } from "models/AppModel";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import { orderDeserializer } from "utils/order/orderSerializer";
import { requests } from "utils/requests";

interface IProps{
  date: Date|null;
  items: IOption[];
  value: number[];
  onChange: (value:number[])=>void;
}

export default function ServiceCheckList(props:IProps){
  const { t } = useTranslation();
  const Message = useMessage();
  const {newOrder} = useNewOrder();

  const {
    date,
    items,
    value,
    onChange
  } = props;

  const handlerItemChange = (item:IOption) => (checked:boolean) => {
    if(checked){
      const _value = [...value]
      _value.push(item.id);

      if(item.pre_order){
        requests.post("/order/check/", orderDeserializer({
          ...newOrder,
          date: date || undefined,
          additionalservices: _value
        })).then(()=>{
          onChange(_value);
        })
      }else if(item.car_class){
        if(newOrder.car_class !== item.car_class){
          const car_class = AppModel.getClassByID(item.car_class);
          Message({
            message: t('only_for_class').replace("%s", (car_class?.name || "null").toLowerCase())
          })
        }else{
          onChange(_value);
        }
      }else{
        onChange(_value);
      }
    }else{
      onChange([...value.filter((i)=>i!==item.id)])
    }
  }

  const ifItemDisabled = (item:IOption):boolean => {
    if(item.car_class && newOrder.car_class !== item.car_class) return true;
    if(item.pre_order && date === null) return true;
    return false
  }

  const getSubTitle = (item:IOption):string|JSX.Element|null => {
    if(item.extended_price) return `+${Math.round(item.extended_price.city)}₴ ${t('city')}, +${Math.round(item.extended_price.suburb)}₴ ${t('suburb')}`;
    if(item.price - 0) return `+${Math.round(item.price)}₴`;
    return t('free');
  }

  return (
    <List disablePadding>
      {items.map((option)=>(
        <CheckListItem
          sx={{
            ...(ifItemDisabled(option)&&{
              opacity: 0.4
            })
          }}
          key={option.id}
          title={option.name}
          subtitle={getSubTitle(option)}
          image={(
            <ImgColor
              src={requests.get_base_url(option.images.svg)}
              color={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
            />
          )}
          type="check_list"
          checked={value.includes(option.id)}
          onChange={handlerItemChange(option)}
        />
      ))}
    </List>
  )
}