import { Button, Container, Grid, TextField } from "@mui/material";
import { APP_VERSION } from "config";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { requests } from "utils/requests";


export default function Feedback() {
  const { t } = useTranslation();
  const history = useHistory();
  const messageRef = useRef<HTMLInputElement>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [message, setMessage] = useState('');
  
  const submitHandler = (e:any) => {
    e.preventDefault();
    setIsSubmit(true);
    setIsServerError(false);
    if(!message){
      messageRef.current?.focus();
    }else{
      const data = {
        message: message,
        device: window.navigator.userAgent,
        os: window.navigator.platform,
        version: APP_VERSION,
      }
      requests.post('/system/feedback/', data).then(()=>{
        if(history.length){
          history.goBack()
        }else{
          history.push('/')
        }
      }).catch(()=>{
        setIsServerError(true);
        messageRef.current?.focus();
      })
    }
  }
  
  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={7} md={5} lg={4}>
          <form onSubmit={submitHandler}>
            <TextField
              inputRef={messageRef}
              placeholder={t('add_comment_placeholder')}
              multiline
              fullWidth
              error={!!((isSubmit && !message) || isServerError)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                setMessage(event.target.value);
                setIsSubmit(false);
                setIsServerError(false);
              }}
            />

            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              sx={{
                mt: 3
              }}
            >{t('send')}</Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
