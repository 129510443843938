import { Box, Grid, styled } from "@mui/material";
import ImgColor from "apps/WebCabApp/components/imgColor";
import clsx from "clsx";
import AppModel, { IOption } from "models/AppModel";
import React, { useRef, useState } from "react";
import { AppTheme } from "theme";
import { requests } from "utils/requests";
import IconButtonBox from "./iconButtonBox";
import { ReactComponent as IndividualIcon } from 'images/individual.svg';

interface IProps{
  value: number;
  optionIDs: number[];
  onChange: (value:number)=>void;
  onClickOption: (option:IOption)=>void;
  onClickIndividual: ()=>void;
  onClearOptions: (options:IOption[])=>void;
}


const SmallText = styled(Grid)(({ theme }) => ({
  fontSize: '0.9em',
  lineHeight: 1
}));

const StyledOptions = styled("div")(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  margin: "0px -2px",
  top: 0,
  right: theme.spacing(2),
  "&>div": {
    margin: "0px 2px",
    width: 16,
    height: 16,
    padding: 1,
    borderRadius: '50%',
    backgroundColor: AppTheme.buttonBg,
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: AppTheme.buttonBgDark,
    },
  }
}));

const StyledCarClassButton = styled(Grid)(({ theme }) => ({
  position: 'relative',
  textAlign: "center",
  lineHeight: 1,
  opacity: 0.3,
  textTransform: "uppercase",
  "&.active": {
    opacity: 1
  },
  "& img": {
    height: 25
  }
}));

const StyledItem = styled(Grid)(({ theme }) => ({
  "&.activeItem": {
    boxShadow: `0px 0px ${theme.spacing(1)} ${AppTheme.theme === 'dark' ? AppTheme.darkColorDark : AppTheme.darkColor}`,
    borderRadius: 7
  },
}));

export default function CarClassChoice(props:IProps){
  const [openItem, setOpenItem] = useState<number|null>(null);
  const rootRef = useRef(null);

  const {
    value,
    optionIDs,
    onChange,
    onClickOption,
    onClickIndividual,
    onClearOptions
  } = props

  return (
    <Box
      ref={rootRef}
      sx={{
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch',
        mx: -2,
        my: -3,
      }}
    >
      <Grid
        container
        alignItems="stretch"
        justifyContent="space-around"
        wrap="nowrap"
        sx={{
          minWidth: '100%',
          width: 'fit-content',
          margin: 0,
          whiteSpace: 'nowrap',
          px: 2,
          py: 1
        }}
      >
        {AppModel.city?.classes.map((car_class)=>{
          const car_class_options = AppModel.getOptionByClass(car_class.id);
          const has_individual = car_class.has_individual;
          const car_class_option_ids = car_class_options.map((i)=>i.id);

          return (
            <StyledItem
              item
              key={car_class.id}
              id={`class-${car_class.id}`}
              className={clsx({
                activeItem: openItem===car_class.id
              })}
            >
              <Grid
                container
                alignItems="stretch"
                justifyContent="center"
                wrap="nowrap"
                sx={{
                  whiteSpace: 'nowrap',
                  p: 1
                }}
              >
                {openItem===car_class.id ? car_class_options.map((option)=>(
                  <SmallText
                    key={option.id}
                    item
                  >
                    <IconButtonBox
                      title={option.short_name}
                      onClick={()=>{
                        onClickOption(option);
                        setOpenItem(null);
                      }}
                    >
                      <ImgColor
                        src={requests.get_base_url(option.images.svg)}
                        color={AppTheme.theme === 'dark' ? AppTheme.darkColorDark : AppTheme.darkColor}
                        height={25}
                        width={25}
                      />
                    </IconButtonBox>
                  </SmallText>
                )):null}
                <StyledCarClassButton
                  item
                  className={clsx({
                    active: value===car_class.id
                  })}
                >
                  <StyledOptions>
                    {optionIDs.filter((i)=>car_class_option_ids.includes(i)).map((optionID)=>{
                      const option = AppModel.getOptionByID(optionID);
                      if(option===null) return null;

                      return (
                        <div key={option.id}>
                          <ImgColor
                            src={requests.get_base_url(option.images.svg)}
                            color={"#ffffff"}
                            width={10}
                            height={10}
                          />
                        </div>
                      )
                    })}
                  </StyledOptions>
                  <IconButtonBox
                    title={car_class.name}
                    onClick={()=>{
                      onChange(car_class.id)
                      const open_id = (Boolean(car_class_options.length) || has_individual) && openItem!==car_class.id ? car_class.id : null;
                      setOpenItem(open_id);
                      if(open_id && rootRef && rootRef.current){
                        onClearOptions(car_class_options);
                        setTimeout(() => {
                          const element = document.getElementById(`class-${car_class.id}`);
                          (rootRef.current as any).scrollTo({
                            behavior: element ? "smooth" : "auto",
                            left: element ? element.offsetLeft : 0
                          });
                        }, 100);
                      }
                    }}
                  >
                    <div className="toggle-theme">
                      <img src={requests.get_base_url(car_class.images.svg__light)} alt="" className="light" />
                      <img src={requests.get_base_url(car_class.images.svg__dark)} alt="" className="dark" />
                    </div>
                  </IconButtonBox>
                </StyledCarClassButton>
                {openItem===car_class.id && has_individual?(
                  <SmallText
                    item
                  >
                    <IconButtonBox
                      title={"Individual"}
                      onClick={()=>{
                        onClickIndividual();
                        setOpenItem(null);
                      }}
                    >
                      <IndividualIcon
                        fill={AppTheme.theme === 'dark' ? AppTheme.darkColorDark : AppTheme.darkColor}
                        width={30}
                        height={25}
                      />
                    </IconButtonBox>
                  </SmallText>
                ):null}
              </Grid>
            </StyledItem>
          )
        })}
      </Grid>
    </Box>
  )
}