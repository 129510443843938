import React, { useMemo } from "react";


interface IProps{
  src: string;
  color: string;
  width?: number;
  height?: number;
}

export default function ImgColor(props:IProps) {
  

  const {
    src,
    color,
    width=20,
    height=20
  } = props

  const id = color.replace("#", "");
  const [r,g,b] = useMemo(()=>{
    const _color = color.replace("#", "");
    let [
      _r,
      _g,
      _b
    ] = [
      parseInt(_color.slice(0, 2), 16),
      parseInt(_color.slice(2, 4), 16),
      parseInt(_color.slice(4, 6), 16),
    ]
    return [
      (_r/255).toFixed(3),
      (_g/255).toFixed(3),
      (_b/255).toFixed(3),
    ]
  }, [color])
  
  return (
    <React.Fragment>
      <img src={src} alt="" style={{
        width: width,
        height: height,
        filter: `url(#${id})`,
      }}
      />

      <div style={{display: 'none'}}>
        <svg>
          <defs>
          <filter id={id} colorInterpolationFilters="sRGB">
            < feColorMatrix type="matrix" values={`0 0 0 0 ${r}
                                                  0 0 0 0 ${g}
                                                  0 0 0 0 ${b}
                                                  0 0 0 1 0`}/>
            
            </filter>
          </defs>
        </svg>
      </div>
    </React.Fragment>
  );
}
