import Code from "./pages/code";
import Start from "./pages/start";

export interface IRoute{
  name_key: string;
  path: string;
  component: React.ComponentType<any>;
}

let routes:IRoute[] = [
  {
    name_key: "login_number_title",
    path: "/",
    component: Start
  },
  {
    name_key: "login_code_title",
    path: "/code",
    component: Code
  },
]

export default routes;