export interface IPhoneStorage{
  code: string,
  number: string,
  city_id: number,
}

class _PhoneModel {
  key = 'Phone';
  codeSentKey = 'CodeSent';

  get data():IPhoneStorage|null {
    return this.getData();
  }

  get isCodeSent():boolean {
    const res = !!localStorage.getItem(this.codeSentKey);
    if(res){
      localStorage.removeItem(this.codeSentKey);
    }
    return res;
  }
  public codeSent = ():void => {
    localStorage.setItem(this.codeSentKey, "1");
  }

  public setData = (data:IPhoneStorage):void => {
    localStorage.setItem(this.key, JSON.stringify(data));
  }
  public getData = ():IPhoneStorage|null => {
    let storage_data = localStorage.getItem(this.key);
    if(storage_data){
      return JSON.parse(storage_data);
    }
    return null;
  }
  public removeData = ():void => {
    localStorage.removeItem(this.key);
  }
}


const PhoneModel = new _PhoneModel();
export default PhoneModel;