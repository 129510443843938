import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DEFAULT_LANGUAGE } from "config";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import AuthModel from "models/AuthModel";
import { Add } from "@mui/icons-material";
import { InitLang } from "i18n";
import useLayout from "apps/WebCabApp/layout/useLayout";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import GreyLine from "apps/WebCabApp/components/greyLine";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import AppModel from "models/AppModel";


interface IData{
  name: string;
  subphone: string;
  email: string;
  lang: string;
}

export default function Settings() {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm<IData>();
  const {goBack} = useLayout();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [visibleDopPhone, setVisibleDopPhone] = useState(!!AuthModel.info?.sub_phone);
  const [data, setData] = useState<IData>({
    name: AuthModel.info?.firstname || "",
    subphone: AuthModel.info?.sub_phone || "",
    email: AuthModel.info?.email || "",
    lang: InitLang.initLang || 'system'
  });

  const setLanguage = (lang:string):boolean => {
    if((InitLang.initLang || 'system') === lang) return false;

    if(lang === 'system'){
      const navigator_langs = i18n.services.languageDetector.detect(['navigator']);
      const langs = (i18n.options.supportedLngs || []);
      const lang = navigator_langs.filter((i:string)=>langs.includes(i))[0] || DEFAULT_LANGUAGE;
      i18n.changeLanguage(lang, ()=>{
        InitLang.initLang = null;
      })
    }else{
      i18n.changeLanguage(lang)
    }

    return true
  }

  const onSubmit = (data:IData) => {
    const languageUpdated = setLanguage(data.lang);
    const postData = {
      firstname: data.name,
      sub_phone: data.subphone,
      email: data.email,
      city: AuthModel.info?.city || 0,
    }
    AuthModel.updateInfo(postData).then((r)=>{
      if(languageUpdated){
        AppModel.load().then(goBack)
      }else{
        goBack();
      }
    }).catch(()=>{
      enqueueSnackbar(t('server_error'), {variant: 'error'})
    })
  };

  const handleChange = (name:keyof IData) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:keyof IData) => (value:any) => {
    data[name] = value;
    setData({...data});
  }

  const logout = () => {
    confirm({
      title: null,
      description: (
        <Typography align='center' component='span' style={{display: 'block'}}>
          {t('logout_title')}
        </Typography>
      ),
      cancellationText: t('no'),
      confirmationText: t('yes'),
    }).then(() => {
      AuthModel.logout();
    });
  }
  
  return (
    <Box sx={{
      flexGrow: 1,
      my: -4,
    }}>
    
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <GreyLine title={t('personal_info')} />
          
        <BaseContainer>
          <TextField
            placeholder={t('firstname')}
            fullWidth
            margin='normal'
            value={data.name}
            onChange={handleChange('name')}
            inputProps={{...register("name")}}
            error={!!errors.name}
            helperText={!!errors.name ? errors.name.message : undefined}
          />
            
          <TextField
            placeholder={t('login_number_title')}
            type="tel"
            fullWidth
            margin='normal'
            disabled
            value={AuthModel.info?.primary_phone}
            InputProps={{
              endAdornment: visibleDopPhone?null:(
                <InputAdornment position="end">
                  <IconButton
                    color='primary'
                    onClick={(e)=>{
                      e.preventDefault();
                      setVisibleDopPhone(true);
                    }}
                    edge="end"
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {visibleDopPhone?(
            <TextField
              placeholder={t('login_number_title')}
              type="tel"
              fullWidth
              margin='normal'
              value={data.subphone}
              onChange={handleChange('subphone')}
              inputProps={{...register("subphone", {
                  pattern: {
                    value: /^\+[0-9]{11,12}$/,
                    message: t('wrong_number')
                  }
                }),
                maxLength: 13
              }}
              error={!!errors.subphone}
              helperText={!!errors.subphone ? errors.subphone.message : undefined}
            />
          ):null}
            
          <TextField
            placeholder={t('enter_e_mail')}
            type="email"
            fullWidth
            margin='normal'
            value={data.email}
            onChange={handleChange('email')}
            inputProps={{...register("email", {
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('wrong_email')
              }
            })}}
            error={!!errors.email}
            helperText={!!errors.email ? errors.email.message : undefined}
          />
        </BaseContainer>

        <br/>
        <GreyLine title={t('app_settings')} />

        <BaseContainer>
          <br/>
          <FormControl fullWidth>
            <InputLabel id="lang-select-label">{t('language')}</InputLabel>
            <Select
              labelId="lang-select-label"
              id="lang-select"
              value={data.lang}
              onChange={handleChange('lang')}
              inputProps={{...register("lang")}}
            >
              <MenuItem value={'system'}>{t('system')}</MenuItem>
              <MenuItem value={'uk'}>Українська</MenuItem>
              <MenuItem value={'ru'}>Русский</MenuItem>
              <MenuItem value={'en'}>English</MenuItem>
            </Select>
          </FormControl>
        </BaseContainer>
          
        <BaseContainer>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            sx={{
              mt: 3
            }}
          >{t('save')}</Button>
          <br/>
          <Button
            variant='text'
            color='primary'
            fullWidth
            onClick={(e)=>{
              e.preventDefault();
              logout();
            }}
            sx={{
              mt: 3
            }}
          >{t('logout')}</Button>
        </BaseContainer>
      </form>
    </Box>
  );
}
