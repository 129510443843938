import { CheckCircleOutlined } from "@mui/icons-material";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function OrderFinished(){
  const { t } = useTranslation();

  return (
    <Box sx={{
      mx: 'auto',
      p: 3,
      textAlign: 'center',
      alignSelf: 'center',
    }}>
      <CheckCircleOutlined sx={{
        fontSize: 100,
        opacity: 0.5
      }}/>
      <Typography variant='h4'>
        {t('sys:order_finished')}
      </Typography>
    </Box>
  )
}