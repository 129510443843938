import { Box, Fab, Grid, styled } from "@mui/material";
import OLMap from "apps/WebCabApp/components/map";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slider from "react-slick";
import useCurrentTrips from "apps/WebCabApp/providers/currentTripsProvider/useCurrentTrips";
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import CurrentTripItem from "./currentTripItem";
import { AppTheme } from "theme";
import { Marker, Route } from "apps/WebCabApp/components/map/types";
import IOrder, { OrderStatus } from "utils/order/types";
import useCars from "apps/WebCabApp/providers/carsProvider/useCars";
import request from "utils/requests/request";
import { useHistory, useRouteMatch } from "react-router-dom";
import Sound from "apps/WebCabApp/components/sound";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import { DirectionsCarFilledOutlined, MyLocation } from "@mui/icons-material";
import { IPoint } from "utils/types";
import CurrentLocationModel from "models/CurrentLocationModel";
import { useAppModel } from "models/AppModel";


const StyledBackButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'inline-block',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

const StyledSliderContainer = styled(Box)(({ theme }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
}))

const StyledSlider = styled(Box)(({ theme }) => ({
  "& .slick-list": {
    transition: theme.transitions.create("height")
  },
  "& .slick-dots": {
    pointerEvents: 'all',
    bottom: '100%',
    marginBottom: theme.spacing(-2),
  
    '& li':{
      margin: 0,
      '& button:before': {
        fontSize: 9,
        lineHeight: '22px'
      },
      '&.slick-active button:before':{
        color: AppTheme.buttonBg,
        '@media (prefers-color-scheme: dark)': {
          color: AppTheme.buttonBgDark
        },
      },
      '&:not(.slick-active) button':{
        '&:before':{
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark
          },
        },
  
        '&:hover': {
          opacity: 0.6
        }
      }
    }
  }
}));


interface RoutesDict{
  [key: number]: Route
}

interface IProps{
  readOnly?: boolean;
  disableBack?: boolean;
  disableOnloadSound?: boolean;
}

export default function CurrentTrips(props:IProps) {
  const match:any = useRouteMatch();
  const history = useHistory();
  const {playDing} = Sound();
  const [slideRefs, setSlideRefs] = React.useState([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [mapMarginBottom, setMapMarginBottom] = useState<number>(0);
  const {trips, firstLoaded} = useCurrentTrips();
  const {getCarByCallSign} = useCars();
  const [carMarker, setCarMarker] = useState<Marker|null>(null);
  const [watchCar, setWatchCar] = useState(true);
  const [center, setCenter] = useState<IPoint|undefined>(undefined)
  const [cachedRoutes, setCachedRoutes] = useState<RoutesDict>({});
  const sliderRef = useRef(null);
  const { city } = useAppModel();

  const {
    readOnly,
    disableBack,
    disableOnloadSound,
  } = props;

  const goBack = useCallback(() => {
    if(history.length){
      history.goBack()
    }else{
      history.push('/')
    }
  }, [history])

  useEffect(()=>{
    if(!disableOnloadSound){
      playDing()
    }
  }, [playDing, disableOnloadSound])

  useEffect(()=>{
    if(!sliderRef || !sliderRef.current) return;
    const slider:any = sliderRef.current;
    const orderID = match.params.id - 0;

    trips.map((i, index)=>{
      if(i.id === orderID){
        slider.slickGoTo(index)
      }
      return i;
    })
  }, [match.params.id, trips, sliderRef]) 

  useEffect(()=>{
    if(firstLoaded && !trips.length){
      goBack();
    }
  }, [trips, firstLoaded, goBack])

  const currentOrder:IOrder|null = useMemo(()=>{
    if(currentSlide + 1 > trips.length) return null
    return trips[currentSlide]
  }, [currentSlide, trips])

  useEffect(()=>{
    if(!!currentOrder?.call_sign){
      const callsign = getCarByCallSign(currentOrder?.call_sign.call_sign);
      if(callsign !== null){
        setCarMarker({
          id: callsign.callsign,
          style: "car",
          point: {
            type: "Point",
            coordinates: callsign.point
          }
        })
      }
    }else{
      setCarMarker(null)
    }
  }, [currentOrder, getCarByCallSign])

  useEffect(()=>{
    if(currentOrder === null) return;
    const _order = {...currentOrder};
    if(cachedRoutes[_order.id || 0] === undefined && _order.routepoints && _order.routepoints.length > 1){
      request.post('/route/', {
        routepoints: _order.routepoints
      }).then((r)=>{
        setCachedRoutes({
          ...cachedRoutes,
          [_order.id || 0]: {
            style: 'default',
            route: r.body.route
          }
        })
      })
    }
  }, [currentOrder, cachedRoutes])

  useEffect(() => {
    setSlideRefs(elRefs => (
      trips.map((_, i) => elRefs[i] || createRef())
    ));
  }, [trips]);

  useEffect(() => {
    const ref = slideRefs[currentSlide] as React.RefObject<any>;
    if(!!ref){
      const element = ref.current;
      if(!!element){
        setMapMarginBottom(element.clientHeight)
      }
    }
  },[currentSlide, slideRefs])

  useEffect(()=>{
    if(watchCar){
      setCenter(carMarker?.point);
    }else{
      setCenter(undefined);
    }
  }, [carMarker, watchCar])

  const route:Route|null = useMemo(()=>{
    if(currentOrder?.status === OrderStatus.HEADING){
      return null
    }else if(cachedRoutes[currentOrder?.id || 0] === undefined){
      return null
    }
    return cachedRoutes[currentOrder?.id || 0]
  }, [currentOrder, cachedRoutes])

  const mapMarkers:Marker[] = useMemo(()=>{
    let markers:Marker[] = [];
    if(carMarker !== null){
      markers.push(carMarker)
    }
    if(route || currentOrder?.status === OrderStatus.HEADING){
      let routepoints = (currentOrder?.routepoints || []);
      if(currentOrder?.status === OrderStatus.HEADING && routepoints.length >= 2){
        routepoints = [routepoints[0]];
      }
      routepoints.map((routepoint, i)=>{
        if(routepoint.point?.coordinates){
          markers.push({
            id: `${currentOrder?.id}-${i}`,
            style: i===0?"start_point":(i+1)===routepoints.length?"end_point":"point",
            point: {
              type: "Point",
              coordinates: routepoint.point?.coordinates
            }
          })
        }
        return routepoint;
      })
    }
    return markers
  }, [currentOrder, carMarker, route])
  
  return (
    <Box sx={{
      flexGrow: 1,
      position: 'relative',
      my: -4,
    }}>
      <OLMap
        markers={mapMarkers}
        route={route||undefined}
        marginBottom={mapMarginBottom}
        center={center}
        onViewChange={()=>{
          setWatchCar(false);
        }}
        currentLocation
      />

      {!disableBack && (
        <StyledBackButton>
          <Fab
            color="default"
            size="medium"
            onClick={goBack}
          >
            <ArrowBackIcon />
          </Fab>
        </StyledBackButton>
      )}

      <StyledSliderContainer>
        <BaseContainer>
          <Grid container spacing={2} justifyContent="end">
            <Grid item>
              <Fab
                color="default"
                size="medium"
                onClick={()=>{
                  setWatchCar(true)
                }}
                sx={{
                  pointerEvents: 'all',
                }}
              >
                <DirectionsCarFilledOutlined />
              </Fab>
            </Grid>
            <Grid item>
              <Fab
                color="default"
                size="medium"
                onClick={()=>{
                  setWatchCar(false);
                  if(CurrentLocationModel.point){
                    setCenter({...CurrentLocationModel.point});
                  }else if(currentOrder?.routepoints.length){
                    setCenter({...currentOrder?.routepoints[0].point as IPoint});
                  }else if(city?.center){
                    setCenter({...city?.center});
                  }
                }}
                sx={{
                  pointerEvents: 'all',
                }}
              >
                <MyLocation />
              </Fab>
            </Grid>
          </Grid>
        </BaseContainer>
        <StyledSlider>
          <Slider
            ref={sliderRef}
            dotsClass={'slick-dots'}
            infinite={true}
            centerPadding="60px"
            slidesToShow={1}
            speed={500}
            dots={true}
            arrows={false}
            adaptiveHeight={true}
            beforeChange={(currentSlide, nextSlide)=>{
              setCurrentSlide(nextSlide)
            }}
          >
            {trips.map((order, i)=>(
              <div key={order.id} ref={slideRefs[i]}>
                <Box sx={{
                  my: 2,
                  mx: 0,
                }}>
                  <CurrentTripItem
                    order={order}
                    readOnly={readOnly}
                    onCanceled={()=>{
                      goBack();
                    }}
                  />
                </Box>
              </div>
            ))}
          </Slider>
        </StyledSlider>
      </StyledSliderContainer>
    </Box>
  );
}
