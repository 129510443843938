import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import AppModel from "models/AppModel"
import React, { useState } from "react"
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import FirebaseLog from "component/firebaseLog";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { AppTheme } from "theme";


interface IProps{
  open: boolean
  onClose: ()=>void
}

export default function HelpDialog(props:IProps) {
  const { t } = useTranslation();
  const [called, setCalled] = useState(false);

  const {
    open,
    onClose
  } = props

  const closeHandler = () => {
    if(!called){
      FirebaseLog.call_driver_click();
    }
    onClose();
  }

  return (
    <Dialog onClose={closeHandler} open={open} sx={{
      textAlign: 'center'
    }}>
      <DialogTitle>{t('help')}</DialogTitle>
      <List>
        {AppModel.city?.phones.map((phone) => (
          <ListItem
            key={phone}
            button
            component='a'
            href={`tel:${phone}`}
            dense
            onClick={()=>{
              setCalled(true);
              FirebaseLog.call_driver_click(phone);
            }}
            sx={{
              "& .MuiTypography-root": {
                fontSize: '1.5em'
              },
              "& .MuiListItemAvatar-root": {
                minWidth: 40
              }
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{
                justifyContent: 'flex-start',
                backgroundColor: 'transparent',
                color: AppTheme.buttonBg,
                '@media (prefers-color-scheme: dark)': {
                  color: AppTheme.buttonBgDark,
                }
              }}>
                <PhoneOutlinedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={(
              <NumberFormat value={phone.replace("+38", "")} displayType={'text'} format="(###) ###-#-###" />
            )} />
          </ListItem>
        ))}
      </List>
      <br/>
    </Dialog>
  )
}