import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LayoutContext, { ProviderContext } from './LayoutContext';


interface IProps{
  children: React.ReactNode;
}

export default function LayoutProvider(props: IProps) {

  const history = useHistory();
  const [title, setTitle] = useState<string|null>(null);
  const [fixedHeight, setFixedHeight] = useState<boolean>(false);
  
  const setCustomTitle = useCallback((title:string) => {
    setTitle(title)
  }, [])

  const setDefaultTitle = useCallback(() => {
    setTitle(null);
  }, [])

  const goBack = useCallback(() => {
    if(history.length){
      history.goBack()
    }else{
      history.push('/')
    }
  }, [history])

  const context:ProviderContext = {
    setTitle: setCustomTitle,
    setDefaultTitle: setDefaultTitle,
    title: title,
    goBack: goBack,
    setFixedHeight: setFixedHeight,
    fixedHeight: fixedHeight
  }

  return (
    <LayoutContext.Provider value={context}>
        {props.children}
    </LayoutContext.Provider>
  );
}