import React from 'react';

export interface ProviderContext {
  setTitle: (title:string) => void;
  setDefaultTitle: () => void;
  title: string|null;
  goBack: () => void;
  setFixedHeight: (fixed:boolean) => void;
  fixedHeight: boolean;
}

// @ts-ignore
export default React.createContext<ProviderContext>();