import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthModel from 'models/AuthModel';
import AppModel from 'models/AppModel';
import CitiesListModel from 'models/CitiesListModel';
import AuthApp from 'apps/AuthApp';
import WebCabApp from 'apps/WebCabApp';
import './i18n';
import { CssBaseline, DialogProps, styled, ThemeProvider } from '@mui/material';
import defaultThem, { AppTheme } from 'theme';
import { ProgressProvider } from 'component/progress';
import RequestsProvider from 'utils/requests/RequestsProvider';
import { SnackbarProvider } from 'notistack';
import { MessageProvider } from 'component/message';
import { ConfirmProvider } from 'material-ui-confirm';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { API_CONFIG } from 'config';
import FirebaseLog from 'component/firebaseLog';
import { NetworkAvailableProvider } from 'component/networkAvailable';
import WatcherApp from 'apps/WatcherApp';
import request from 'utils/requests/request';
import { requests } from 'utils/requests';

interface AppProps{
  children: JSX.Element;
}

const DebugLabel = styled("div")(({ theme }) => ({
  pointerEvents: 'none',
  fontWeight: 'bold',
  position: 'fixed',
  top: theme.spacing(2),
  right: theme.spacing(2),
  opacity: 0.3,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(),
  zIndex: 999999999999999,
  backgroundColor: AppTheme.alarmButtonBg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.alarmButtonBgDark
  },
}));

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  maxWidth: 400,
  "&.info": {
    backgroundColor: `${AppTheme.lightBg} !important`,
    color: `${AppTheme.color} !important`,
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: `${AppTheme.lightBgDark} !important`,
      color: `${AppTheme.colorDark} !important`,
    },
  },
  "&.success": {
    backgroundColor: `${AppTheme.buttonBgDark} !important`,
    color: `${AppTheme.colorDark} !important`,
  },
  "&.error": {
    backgroundColor: `${AppTheme.alarmButtonBgDark} !important`,
    color: `${AppTheme.colorDark} !important`,
  }
}));


function App(props:AppProps){
  return (
    <div>
      <ThemeProvider theme={defaultThem}>
        <CssBaseline/>
        <NetworkAvailableProvider>
          <ProgressProvider>
            <ConfirmProvider
              defaultOptions={{
                dialogProps: { maxWidth: 'xs' } as DialogProps
              }}
            >
              <StyledSnackbarProvider
                autoHideDuration={3000}
                maxSnack={3}
                preventDuplicate
                classes={{
                  variantInfo: "info",
                  variantSuccess: "success",
                  variantError: "error",
                }}
              >
                <MessageProvider>
                  <RequestsProvider>
                    {props.children}
                    {API_CONFIG.debug ?(
                      <DebugLabel>
                        DEBUG MODE
                      </DebugLabel>
                    ):null}
                  </RequestsProvider>
                </MessageProvider>
              </StyledSnackbarProvider>
            </ConfirmProvider>
          </ProgressProvider>
        </NetworkAvailableProvider>
      </ThemeProvider>
    </div>
  );
}

const watcher_match = window.location.pathname.match(/^\/watch\/([a-zA-Z0-9]+)/)
if(watcher_match !== null){
  requests.setWatcher(watcher_match[1]);
  AppModel.load().then(() => {
    ReactDOM.render(
      <App>
        <WatcherApp />
      </App>,
      document.getElementById('root')
    );
  })
}else{
  request.setWatcher(null);
  if(AuthModel.if_auth()){
    AppModel.load().then(() => {
      ReactDOM.render(
        <App>
          <WebCabApp />
        </App>,
        document.getElementById('root')
      );
    })
  }else{
    CitiesListModel.load().then(() => {
      ReactDOM.render(
        <App>
          <AuthApp />
        </App>,
        document.getElementById('root')
      );
    })
  }
}

window.addEventListener('error', function (event) {
  FirebaseLog.crash(event.message)
})

if(typeof String.prototype.replaceAll == "undefined") {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function(match:any, replace:any){
     return this.replace(new RegExp(match, 'g'), () => replace);
  }
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(process.env['REACT_APP_SERVICEWORKER'] === '1'){
  const conf = {
    onUpdate: (registration:ServiceWorkerRegistration) => {
      if(registration.waiting !== null){
        registration.waiting.addEventListener('statechange', (event:any) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  }
  serviceWorkerRegistration.register(conf);
}else{
  serviceWorkerRegistration.unregister();
}