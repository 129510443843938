import { Button, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";


const Logo = styled("img")(({ theme }) => ({
  width: '50%',
  maxWidth: 300,
  opacity: 0.3,
}));

interface IProps{
}

export default function NotFound(props:IProps) {
  const { t } = useTranslation();
  
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        textAlign: 'center'
      }}
    >
      <Grid item>
        <Logo src="/logo.svg" alt="" />
        <Typography
          variant='h4'
          sx={{
            opacity: 0.3,
            my: 4,
          }}
        >{t('sys:page_not_found')}</Typography>
        
        <Button
          variant='contained'
          color='primary'
          size='large'
          component={Link}
          to="/"
        >{t('sys:go_to_home')}</Button>
      </Grid>
    </Grid>
  );
}
