import useSound from "use-sound";

const dingSound = require("sound/ding.mp3");
const carAlarmSound = require("sound/car_alarm.mp3");

export default function Sound(){
  const [playDing] = useSound(dingSound.default);
  const [playCarAlarm] = useSound(carAlarmSound.default);

  return {
    playDing: playDing,
    playCarAlarm: playCarAlarm
  }
}