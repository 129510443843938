import { Box, styled } from "@mui/material";
import { AppTheme, hexToRgbA } from "theme";


export const StyledRoutepointField = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(-1),

  '& .routepoint': {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },

  '& .name':{
    position: 'relative',
    zIndex: 1,
    padding: 0,
  },

  '& .icon': {
    position: 'absolute',
    zIndex: 2,
    height: '100%',
    pointerEvents: 'none',
    '&>*': {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto'
    }
  },
  '& .bottomIconLine': {
    '&:after': {
      bottom: theme.spacing(-0.5),
      left: "0 !important",
      right: "0 !important",
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  '& .topIconLine': {
    '&:before': {
      top: theme.spacing(-0.5),
      left: "0 !important",
      right: "0 !important",
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }
}))

export const StyledInput = styled("input")(({ theme }) => ({
  width: '100%',
  fontSize: 16,
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  border: '1px solid transparent',
  outline: 'none',
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: AppTheme.lightBg,
  backgroundColor: AppTheme.lightBg,
  color: AppTheme.color,
  '@media (prefers-color-scheme: dark)': {
    borderColor: AppTheme.lightBgDark,
    backgroundColor: AppTheme.lightBgDark,
    color: AppTheme.colorDark,
  },
  '&:focus': {
    borderColor: AppTheme.buttonBg,
    backgroundColor: AppTheme.lightButtonBg,
    '@media (prefers-color-scheme: dark)': {
      borderColor: AppTheme.buttonBgDark,
      backgroundColor: AppTheme.lightButtonBgDark,
    },
    '&~.clearButton': {
      opacity: 0.2
    }
  },

  "&.frontdoor": {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    marginLeft: theme.spacing(),
    textAlign: 'center',
    width: 60,
    '&::placeholder': {
      fontSize: '0.7em',
      lineHeight: '20px'
    }
  }
}))

export const StyledIconBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(2.7),
  textAlign: 'center',
  alignSelf: 'stretch',
  position: 'relative',
  '&>*': {
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    margin: 'auto 0'
  },
  '&.topIconLine': {
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -4,
      bottom: '50%',
      left: 6,
      width: 1,
      marginBottom: 4,
      backgroundColor: AppTheme.buttonBg,
      '@media (prefers-color-scheme: dark)': {
        backgroundColor: AppTheme.buttonBgDark,
      },
    }
  },
  '&.bottomIconLine': {
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      bottom: -4,
      left: 6,
      width: 1,
      marginTop: 4,
      backgroundColor: AppTheme.buttonBg,
      '@media (prefers-color-scheme: dark)': {
        backgroundColor: AppTheme.buttonBgDark,
      },
    }
  }
}));

export const StyledPointIcon = styled("div")(({ theme }) => ({
  width: 9,
  height: 9,
  borderRadius: '50%',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: AppTheme.buttonBg,
  marginLeft: 2,
  '@media (prefers-color-scheme: dark)': {
    borderColor: AppTheme.buttonBgDark,
  },
  '&.centerPointIcon': {
    backgroundColor: AppTheme.buttonBg,
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: AppTheme.buttonBgDark,
    },
  }
}))

export const StyledRoutepointBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&.routepointOnClick': {
    cursor: 'pointer'
  }
}))

export const StyledRoutepointNameBox = styled(Box)(({ theme }) => ({
  lineHeight: 1.2,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  "& small": {
    color: AppTheme.darkColor,
  },
  "&.routepointOpacity": {
    opacity: 0.5
  }
}))

export const StyledRoutepointFrontdoorBox = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  marginLeft: theme.spacing(1),
  "&.emptyFrontdoorItem": {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.2, 0.7),
    backgroundColor: hexToRgbA(AppTheme.color, 0.1),
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: hexToRgbA(AppTheme.colorDark, 0.1),
    },
  },
}))