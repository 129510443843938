import React from "react";
import { AppBar, Box, styled, Toolbar, Typography } from "@mui/material";
import ElevationScroll from "component/elevationScroll";
import { useTranslation } from "react-i18next";

interface Props {
  titleKey: string;
  children: JSX.Element;
}

const StyledContent = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&> div': {
    flexGrow: 9999999,
    flexShrink: 1,
    flexBasis: 'auto',
    '&:first-of-type': {
      flexGrow: 0,
    }
  }
}));

function Layout(props:Props) {
  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar sx={{justifyContent: 'center'}}>
            <Typography
              variant="h6"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >{t(props.titleKey)}</Typography>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <StyledContent>
        <Toolbar />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 4
        }}>
          {props.children}
        </Box>
      </StyledContent>
    </React.Fragment>
  );
}

export default Layout;
