import { Box, IconButton, Menu, MenuItem, styled } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import { ITemplate } from "utils/types";


interface IProps{
  item: ITemplate;
  onClick: ()=>void;
  onRepeatTrip: ()=>void;
  onRepeatBackwardsTrip: ()=>void;
  onEdit: ()=>void;
  onDelete: ()=>void;
}

const Root = styled(Box)(({ theme }) => ({
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: AppTheme.lightBg,
  borderRadius: 5,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '@media (prefers-color-scheme: dark)': {
    borderColor: AppTheme.lightBgDark,
  },
  '&:hover': {
    boxShadow: theme.shadows[4],
  }
}));

const StyledHead = styled(Box)(({ theme }) => ({
  backgroundColor: AppTheme.lightBg,
  padding: theme.spacing(1, 2),
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.lightBgDark,
  },
}));

const RedText = styled("span")(({ theme }) => ({
  color: AppTheme.alarmButtonBg,
  '@media (prefers-color-scheme: dark)': {
    color: AppTheme.alarmButtonBgDark,
  },
}));

export default function TemplateListItem(props:IProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    item,
    onClick,
    onDelete,
    onEdit,
    onRepeatTrip,
    onRepeatBackwardsTrip
  } = props

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
      <Root
        onClick={()=>{
          onClick()
        }}
      >
        <StyledHead>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {item.name}
            </Box>
            <Box>
              <IconButton
                size='medium'
                onClick={(e)=>{
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget)
                }}
                sx={{
                  m: -2,
                  opacity: 0.5
                }}
              >
                <MoreVert fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </StyledHead>
        <Box sx={{
          py: 1,
          px: 2,
        }}>
          <RoutePoints
            routepoints={item.routepoints}
            defaultRoutePointName={(
              <RedText>{t('need_update')}</RedText>
            )}
          />
        </Box>
      </Root>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!item.need_update?(
          <MenuItem onClick={(e)=>{
            handleClose();
            onRepeatTrip();
          }}>
            {t('order')}
          </MenuItem>
        ):null}
        {!item.need_update && item.routepoints.length>1?(
          <MenuItem onClick={(e)=>{
            handleClose();
            onRepeatBackwardsTrip();
          }}>
            {t('ride_backwards')}
          </MenuItem>
        ):null}
        <MenuItem onClick={(e)=>{
          handleClose();
          onEdit();
        }}>
          {t('edit')}
        </MenuItem>
        <MenuItem onClick={(e)=>{
          handleClose();
          onDelete();
        }}>
          {t('delete')}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
