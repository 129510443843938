import { useEffect, useState } from "react";

const hasFocus = () => typeof document !== 'undefined' && !document.hidden;

const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus());
    const onChange = () => setFocused(hasFocus());

    document.addEventListener('visibilitychange', onChange)
    document.addEventListener('blur', onChange)
    window.addEventListener('blur', onChange)
    window.addEventListener('focus', onChange)
    document.addEventListener('focus', onChange)
    return () => {
      document.removeEventListener('visibilitychange', onChange)
      document.removeEventListener('blur', onChange)
      window.removeEventListener('blur', onChange)
      window.removeEventListener('focus', onChange)
      document.removeEventListener('focus', onChange)
    };
  }, []);

  return focused;
};
export default useWindowFocus