const THEME_NAME = process.env['REACT_APP_THEME'] as string;

let _FIREBASE_CONFIG = {
    config: {
        apiKey: "AIzaSyDqHPaAbYDwYvTu5RQzLD-H3re_ewhi3p4",
        authDomain: "web-cab-3.firebaseapp.com",
        databaseURL: "https://web-cab-3.firebaseio.com",
        projectId: "web-cab-3",
        storageBucket: "web-cab-3.appspot.com",
        messagingSenderId: "1050918620840",
        appId: "1:1050918620840:web:c2a37243a0d983419cfc9d",
        measurementId: "G-ZJLZDG3CYB"
    },
    vapidKey: 'BFY7RJBbZrSBxpOBUKZVHzruVZp7a0Gybqdxe5nFVvIsVc2ZxV7KKy70mDXLmiNEJYfkTHNHo4PwkAic14i3Q_Y'
}
if(THEME_NAME==='459'){
    _FIREBASE_CONFIG = {
        config: {
            apiKey: "AIzaSyAkFFKHcoSxANpinJFUPMa5BI-zy5ns_NY",
            authDomain: "taxi-459.firebaseapp.com",
            databaseURL: "https://taxi-459.firebaseio.com",
            projectId: "taxi-459",
            storageBucket: "taxi-459.appspot.com",
            messagingSenderId: "924093419148",
            appId: "1:924093419148:web:bd78827e07084a8ef5b5a9",
            measurementId: "G-WM216627H3"
          },
        vapidKey: 'BG4-luJxrhQJP39hRKfe8zCvWmDqxRLqoddfrIxkiQcicXiTsc61PpwrvvcPpRNfm1tg7AwjetbsqsQMXHIQSII'
    }
}
export const FIREBASE_CONFIG = _FIREBASE_CONFIG;