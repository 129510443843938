import { API_CONFIG } from "config";
import firebase from "firebase";
import 'firebase/analytics';
import AppModel from "models/AppModel";
import IOrder, { OrderStatus } from "utils/order/types";
import { IResponse } from "utils/requests";
import { PaymentTypes } from "utils/types";

class _FirebaseLog {

  public log = (event:string, data:{[key: string]: any}):void => {
    const analytics = firebase.analytics;
    if (typeof analytics === "function") {
      analytics().logEvent(event, data);
    }else{
      console.log("FIREBASE LOG:", event, data)
    }
  }

  private makeErrorMessageByResponse = (r?:IResponse):string|undefined => {
    const parse = (data:any):string => {
      let first_key = "";
      try{
        first_key = Object.keys(data)[0]
      }catch{
        return "Error no data"
      }

      if(typeof data[first_key] === "string"){
        return data[first_key]
      }

      return parse(data[first_key])
    }

    if(r===undefined) return undefined;
    if(r.body === null) return "Error == nil"
    
    return parse(r.body);
  }

  public crash = (message: string) => {
    this.log("crash", {
      message: message
    })
  }

  public request_error = (request:Request, response?:IResponse) => {
    const base_url = new URL(API_CONFIG.api_base_url);
    let request_url = `${request.method} ${request.url.replace(base_url.origin, '')}`;
    
    this.log("request_error", {
      request: request_url,
      error: this.makeErrorMessageByResponse(response)
    })
  }

  public send_sms = (sender:"first"|"resend", statusCode:number) => {
    let status = "ошибка";
    switch(statusCode){
      case 200:
        status = "успех";
        break
      case 423:
        status = "номер в блеклисте";
        break
      case 429:
        status = "лимит по смс";
        break
    }

    let count = "";
    switch(sender){
      case "first":
        count = "отправлено";
        break
      case "resend":
        count = "повторно отправлено";
        break
    }

    this.log("get_sms", {
      status: status,
      count: count
    })
  }

  public confirm_sms = (status:number, response?:IResponse) => {
    this.log("confirm_sms", {
      status: status===200?"успех":"ошибка",
      message: this.makeErrorMessageByResponse(response)
    })
  }

  public travel_cost = (status:number) => {
    this.log("travel_cost", {
      status: status===200?"успех":"ошибка",
    })
  }

  private orderButtonClickData = (order:IOrder) => {
    let additionalservices:string[] = (order.additionalservices || []).map((i)=>{
      return AppModel.getOptionByID(i)?.base_name || ""
    }).filter((i)=>Boolean(i));

    let payment_type = "???";
    switch (order.payment?.type) {
      case PaymentTypes.CASH:
        payment_type = "Наличные";
        break;
      case PaymentTypes.CARD:
        payment_type = "Карта";
        break;
      case PaymentTypes.COMPANY:
        payment_type = "Компания";
        break;
      case PaymentTypes.APPLE_PAY:
        payment_type = "Apple Pay";
        break;
      case PaymentTypes.GOOGLE_PAY:
        payment_type = "Google Pay";
        break;
    }
    
    return {
      payment_type: payment_type,
      additionalservices: additionalservices.join(", ") || "нет",
      class_auto: AppModel.getClassByID(order.car_class)?.base_name,
      preorder: order.pre_order?"да":"нет",
    }
  }

  public order_button_click = (order:IOrder) => {
    this.log("order_button_click", this.orderButtonClickData(order));
  }

  public order_processing = (order:IOrder, response?:IResponse) => {
    const createdOrder = AppModel.getCreatedOrder(order.id || 0);
    if(createdOrder===null) return;

    let status = "N/A";
    if(response && response.status !== 201){
      status = "ошибка"
    }else if(order.pre_order){
      status = "успех"
    }else{
      switch (order.status) {
        case OrderStatus.HEADING:
          status = "Авто найдено";
          break;
        case OrderStatus.CANCELED:
          status = "Oтменено клиентом";
          break;
        case OrderStatus.REMOVED:
          status = "Авто не найдено";
          break;
      }
    }

    this.log("order_processing", {
      ...this.orderButtonClickData(order),
      auto_search_time: order.pre_order?"Предзаказ":createdOrder.max_search_time,
      status: status,
      orderId: order.id
    });
  }

  public order_cancel = (order:IOrder) => {
    this.log("order_cancel", {
      car: order.call_sign?.car.brand || "нет авто"
    });
  }

  public call_driver_click = (number?:string) => {
    this.log("call_driver_click", {
      number: number || "нет"
    });
  }

  public call_driver_from_order_click = (type:"dispatcher"|"driver") => {
    let number = "N/A";
    switch(type){
      case "dispatcher":
        number = "звонок в службу"
        break;
      case "driver":
        number = "звонок водителю"
        break;
    }
    this.log("call_driver_from_order_click", {
      number: number
    });
  }
}


const FirebaseLog = new _FirebaseLog();
export default FirebaseLog;