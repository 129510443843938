import { Box, Button, Fab, Grid, styled, Typography } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import OrderDistance from "apps/WebCabApp/components/orderDistance";
import OrderInfoLine from "apps/WebCabApp/components/orderInfoLine";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import { format } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import useCountDown from "react-countdown-hook";
import { useTranslation } from "react-i18next";
import { AppTheme, hexToRgbA } from "theme";
import { pad } from "utils/helper";
import IOrder, { OrderStatus } from "utils/order/types";
import { requests } from "utils/requests";
import PhoneIcon from '@mui/icons-material/Phone';
import CommentIcon from '@mui/icons-material/Comment';
import useCurrentTrips from "apps/WebCabApp/providers/currentTripsProvider/useCurrentTrips";
import DriverMessageDialog from "./driverMessageDialog";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import FirebaseLog from "component/firebaseLog";
import { useMessage } from "component/message";
import AppModel from "models/AppModel";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import NumberFormat from "react-number-format";


const Root = styled(Box)(({ theme }) => ({
  pointerEvents: 'all',
  boxShadow: theme.shadows[4],
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: AppTheme.bg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.bgDark
  },
  '@media (max-width: 350px)': {
    margin: theme.spacing(-2),
    marginTop: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none'
  }
}));

const StyledStatus = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, -2),
  padding: theme.spacing(1, 2),
  textAlign: 'center',
  backgroundColor: hexToRgbA(AppTheme.buttonBg, 0.08),
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: hexToRgbA(AppTheme.buttonBgDark, 0.08),
  },
  "&>*": {
    display: "block"
  },
  "& b": {
    fontWeight: "bold",
    color: AppTheme.buttonBg,
    '@media (prefers-color-scheme: dark)': {
      color: AppTheme.buttonBgDark,
    },
  },
  "& small": {
    fontSize: "0.9em"
  }
}));

interface IProps{
  order: IOrder,
  onCanceled: ()=>void;
  readOnly?: boolean;
}

export default function CurrentTripItem(props:IProps) {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const [timeLeft, { start }] = useCountDown(0, 1000);
  const {callOrder, setOrder} = useCurrentTrips();
  const Message = useMessage();
  const [driverMessageOrder, setDriverMessageOrder] = useState<IOrder|null>(null);
  const {setNewOrder} = useNewOrder();
  
  const {
    order,
    onCanceled,
    readOnly
  } = props

  useEffect(()=>{
    if(order.status===OrderStatus.HEADING){
      const currentTimestamp = Math.round(new Date().getTime());
      const orderTimestamp = Math.round((order.date || new Date()).getTime());
      start(orderTimestamp-currentTimestamp);
    }
  }, [order.date, order.status, start])

  const status = useMemo(()=>{
    if(order.status===OrderStatus.HEADING){
      const timeLeftDate = new Date(timeLeft);
      const timer = `${pad(timeLeftDate.getMinutes(), 2)}:${pad(timeLeftDate.getSeconds(), 2)}`;
      return `${t('car_will_arrive_in')} ${timer}`
    }else if(order.status===OrderStatus.ARRIVED){
      return t('car_waiting')
    }else if(order.status===OrderStatus.IN_PROGRESS){
      return t('on_the_road')
    }else if(order.pre_order){
      return format(order.date as Date, "dd.MM.yyyy HH:mm")
    }else{
      return '-'
    }
  },[order, timeLeft, t])

  const onCancel = () => {
    confirm({
      title: null,
      description: (
        <Typography align='center' component='span' style={{display: 'block'}}>
          {t('cancel_order_desc')}
        </Typography>
      ),
      cancellationText: t('no'),
      confirmationText: t('yes'),
    }).then(() => {
      requests.withoutErrorMessage().post(`/order/${order.id}/cancel/`, {
        reason: "Отменили кнопкой"
      }).then(()=>{
        FirebaseLog.order_cancel(order);

        setNewOrder(order);
        enqueueSnackbar(t('order_canceled'), {variant: 'success'})
        onCanceled();
      }).catch((r)=>{
        Message({
          disableButtons: true,
          message: (
            <Box>
              <Box>{r.body?.detail}</Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                href={`tel:${AppModel.city?.primary_phone}`}
                target="_blank"
                sx={{
                  fontSize: "1.2em",
                  mt: 2
                }}
              >
                <PhoneOutlinedIcon fontSize="small" />&nbsp;&nbsp;
                <NumberFormat
                  value={AppModel.city?.primary_phone.replace("+38", "")}
                  displayType={'text'}
                  format="(###) ###-#-###"
                />
              </Button>
            </Box>
          )
        })
      })
    });
  }

  const headInfo = useMemo(()=>{
    if(!!order.call_sign){
      return (
        <Typography>
          {order?.call_sign?.driver.firstname}<br/>
          {order?.call_sign?.car.brand} {order?.call_sign?.car.color}<br/>
          <b>{order?.call_sign?.car.number}</b>
        </Typography>
      )
    }

    if(order.pre_order){
      return t('pre_order');
    }

    return null;
  }, [order, t])
  
  return (
    <BaseContainer>
      <Root>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item>{headInfo}</Grid>
          {!readOnly && (
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                spacing={2}
              >
                <Grid item>
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={()=>{
                      setDriverMessageOrder(order);
                    }}
                  >
                    <CommentIcon />
                  </Fab>
                </Grid>
                <Grid item>
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={()=>{
                      callOrder(order)
                    }}
                  >
                    <PhoneIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <StyledStatus>
          <b>{status}</b>
          {order.call_sign?.another_order && (
            <small>{ t('driver_with_another_order') }</small>
          )}
        </StyledStatus>

        <RoutePoints
          routepoints={order.routepoints}
        />

        <Box sx={{
          mt: 1,
          mb: 2,
        }}>
          <OrderDistance distance={order.distance}/>
        </Box>

        <OrderInfoLine
          order={order}
          withPrice
        />

        {!readOnly && ![OrderStatus.ARRIVED, OrderStatus.IN_PROGRESS].includes(order.status || OrderStatus.INCOMING)?(
          <Button
            color='primary'
            variant='outlined'
            fullWidth
            onClick={onCancel}
            sx={{
              mt: 2,
            }}
          >{t('cancel')}</Button>
        ):null}
      </Root>
          
      <DriverMessageDialog
        order={driverMessageOrder}
        onClose={()=>{
          setDriverMessageOrder(null)
        }}
        onSaved={(order)=>{
          setOrder(order);
          setDriverMessageOrder(null)
        }}
      />
    </BaseContainer>
  );
}
