import { Box, Grid, Link, styled } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import GreyLine from "apps/WebCabApp/components/greyLine";
import AppModel from "models/AppModel";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FacebookIcon } from 'images/facebook.svg';
import { ReactComponent as InstagramIcon } from 'images/instagram.svg';


const StyledLink = styled(Link)(({ theme }) => ({
  '@media (prefers-color-scheme: dark)': {
    filter: 'brightness(0) invert(1)'
  }
}));

export default function Soc() {
  const { t } = useTranslation();
  
  const {
    website,
    facebook,
    instagram
  } = ((AppModel.city || {}).urls || {})

  const printWebsite = (url:string):string => {
    const obj = new URL(url);
    return obj.hostname
  }


  if(!website && !facebook && !instagram){
    return null;
  }
  
  return (
    <React.Fragment>
      <br/>
      <GreyLine title={t('networks_and_web')} />
      <BaseContainer sx={{
        py: 2,
      }}>
        {!!website?(
          <Box sx={{
            pb: 2,
          }}>
            {t('web')}: <Link href={website} target="_blank">{printWebsite(website)}</Link>
          </Box>
        ):null}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {!!facebook?(
            <Grid item>
              <StyledLink href={facebook} target="_blank">
                <FacebookIcon height={20} />
              </StyledLink>
            </Grid>
          ):null}
          {!!instagram?(
            <Grid item>
              <StyledLink href={instagram} target="_blank">
                <InstagramIcon height={20} />
              </StyledLink>
            </Grid>
          ):null}
        </Grid>
      </BaseContainer>
    </React.Fragment>
  );
}
