import { Backdrop, Box, Button, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import StaticDateTimePicker from '@mui/lab/StaticDateTimePicker';


interface IProps{
  open: boolean;
  value: Date|null;
  onChange: (value:Date|null)=>void;
  onClose: ()=>void;
}


const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

export default function TimePickerDialog(props:IProps){
  const { t } = useTranslation();

  const {
    open,
    value,
    onChange,
    onClose
  } = props;

  const [v, setV] = useState<Date>(new Date());

  useEffect(()=>{
    setV(new Date((value || new Date()).getTime()));
  }, [value])

  const changeHandler = (d:any) => {
    setV(new Date((d || new Date()).getTime()));
  }

  const doneHandler = () => {
    onChange(v);
  }

  return (
    <StyledBackdrop
      onClick={onClose}
      open={open}
    >
      <Box
        onClick={(e)=>e.stopPropagation()}
        sx={{
          borderRadius: "10px",
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: AppTheme.bg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.bgDark,
          },
          "&>div": {
            backgroundColor: "inherit"
          },
          [`& .PrivatePickersToolbar-root~div>:not(.MuiCalendarPicker-root)`]: {
            "& *": {
              color: AppTheme.color,
              '@media (prefers-color-scheme: dark)': {
                color: AppTheme.colorDark,
              },
            },
            "&>div": {
              backgroundColor: AppTheme.lightBg,
              '@media (prefers-color-scheme: dark)': {
                backgroundColor: AppTheme.lightBgDark,
              },
              "&>div": {
                "&:nth-of-type(2), &:nth-of-type(3)": {
                  backgroundColor: AppTheme.buttonBg,
                  borderColor: AppTheme.buttonBg,
                  '@media (prefers-color-scheme: dark)': {
                    backgroundColor: AppTheme.buttonBgDark,
                    borderColor: AppTheme.buttonBgDark,
                  },
                  "&>div": {
                    backgroundColor: AppTheme.buttonBgDark,
                    borderColor: AppTheme.buttonBg,
                    '@media (prefers-color-scheme: dark)': {
                      backgroundColor: AppTheme.buttonBg,
                      borderColor: AppTheme.buttonBgDark,
                    },
                  }
                }
              }
            }
          },
        }}
      >
        <StaticDateTimePicker
          ampm={false}
          displayStaticWrapperAs="mobile"
          toolbarTitle={t('select_date_time')}
          openTo="day"
          minDate={new Date()}
          value={v}
          onChange={changeHandler}
          renderInput={(params) => <TextField {...params} />}
        />
        <Box sx={{
          textAlign: 'right',
          p: 1
        }}>
          <Button size="small" onClick={onClose}>{t('cancel')}</Button>
          <Button size="small" onClick={doneHandler}>{t('ok')}</Button>
        </Box>
      </Box>
    </StyledBackdrop>
  )
}