import { Grid, Rating } from "@mui/material";
import useOrderRating from "apps/WebCabApp/providers/OrderRatingProvider/useOrderRating";
import React from "react"
import { useTranslation } from "react-i18next";
import IOrder from "utils/order/types"

interface IProps{
  order: IOrder;
  onRatingAdded: ()=>void;
}

export default function RatingButton(props:IProps){
  const { t } = useTranslation();
  const orderRating = useOrderRating();

  const {
    order,
    onRatingAdded
  } = props
  
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>{order.rating?.value ? t('rated_trip') : t('rate_trip')}</Grid>
        <Grid item>
          <Rating
            name={`one-trip-rating-${order.id}`}
            max={5}
            size="small"
            readOnly={!order.rating?.available}
            value={!order.rating?.available ? order.rating?.value || 0 : 0}
            onChange={(e, newValue)=>{
              orderRating.doRating(order.id || 0, newValue || 0).then(onRatingAdded)
            }}
            sx={{
              display: 'flex'
            }}
          />
        </Grid>
      </Grid>
      
    </React.Fragment>
  )
}