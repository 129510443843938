import { Divider, List, ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import { menuItems, menuTypes } from "./menuItems";
import { Link } from "react-router-dom";
import CurrentTripsIcon from "./currentTripsIcon";
import useCurrentTrips from "apps/WebCabApp/providers/currentTripsProvider/useCurrentTrips";

interface IProps{
  onClick: (menu_type:menuTypes)=>void
}

const StyledList = styled(List)(({ theme }) => ({
  width: 250,
  "& .MuiListItemIcon-root": {
    minWidth: 40
  }
}));


export default function AppMenu(props:IProps) {
  const { t } = useTranslation();
  const {trips} = useCurrentTrips();
  const {
    onClick
  } = props;

  const renderItems = (types:menuTypes[]) => (
    <React.Fragment>
      {types.map((menu_type)=>{
        const item = menuItems[menu_type];
        if(item.isHide !== undefined && item.isHide()) return null;

        let listItemProps = {};
        if(item.getProps !== undefined){
          listItemProps = item.getProps()
        }
        return (
          <ListItem
            {...listItemProps}
            key={menu_type}
            button
            onClick={()=>{
              onClick(menu_type)
            }}
          >
            {item.icon?(
              <ListItemIcon>
                <item.icon
                  fill={AppTheme.theme === 'dark' ? AppTheme.darkColorDark : AppTheme.darkColor}
                  height="17"
                  width="17"
                />
              </ListItemIcon>
            ):null}
            <ListItemText primary={t(item.title_key)} />
          </ListItem>
        )
      })}
    </React.Fragment>
  )
  
  return (
    <React.Fragment>
      <StyledList>

        {trips.length>0?(
          <ListItem
            button
            component={Link}
            to={'/current-trips'}
            onClick={()=>{
              onClick(menuTypes.currentTrips)
            }}
          >
            <ListItemIcon>
              <CurrentTripsIcon count={trips.length}/>
            </ListItemIcon>
            <ListItemText primary={t('active_orders')} />
          </ListItem>
        ):null}

        {renderItems([
          menuTypes.trips,
          menuTypes.templates,
          menuTypes.payment,
          menuTypes.help,
          menuTypes.feedback
        ])}
      </StyledList>
      <Divider/>
      <StyledList>
        {renderItems([
          menuTypes.settings,
          menuTypes.info
        ])}
      </StyledList>
    </React.Fragment>
  );
}
