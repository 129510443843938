import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { AppTheme } from "theme";
import React, { useCallback, useMemo } from "react";
import { IRoutePoint } from "./types";
import { ReactComponent as SetOnMapIcon } from 'images/set_on_map.svg';
import { ReactComponent as PlaceIcon } from 'images/place.svg';
import { ReactComponent as GassStationIcon } from 'images/addressType/GassStation.svg'; // АЗС: gas_station
import { ReactComponent as StationIcon } from 'images/addressType/Station.svg'; // Вокзалы: station
import { ReactComponent as ShopIcon } from 'images/addressType/Shop.svg'; // Магазины: shop
import { ReactComponent as CultureIcon } from 'images/addressType/Culture.svg'; // Культура: culture
import { ReactComponent as CemeteryIcon } from 'images/addressType/Cemetery.svg'; // Кладбище: cemetery
import { ReactComponent as HousingAreaIcon } from 'images/addressType/HousingArea.svg'; // Масиви: housing_area
import { ReactComponent as MedicineIcon } from 'images/addressType/Medicine.svg'; // Медицина: medicine
import { ReactComponent as MetroIcon } from 'images/addressType/Metro.svg'; // Метро: metro
import { ReactComponent as EducationIcon } from 'images/addressType/Education.svg'; // Навч. заклади: education
import { ReactComponent as OrganisationIcon } from 'images/addressType/Organisation.svg'; // Организации: organization
import { ReactComponent as HotelIcon } from 'images/addressType/Hotel.svg'; // Отели: hotel
import { ReactComponent as ParkIcon } from 'images/addressType/Park.svg'; // Парки: park
import { ReactComponent as RestourantIcon } from 'images/addressType/Restourant.svg'; // Рестораны: restaurant
import { ReactComponent as SportIcon } from 'images/addressType/Sport.svg'; // Спорт: sport
import { ReactComponent as ReligionIcon } from 'images/addressType/Religion.svg'; // Церкви: religion
import BaseContainer from "../baseContainer";
import { ITemplate } from "utils/types";
import { useTranslation } from "react-i18next";

const POITypeIcons = {
  gas_station: (
    <GassStationIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  station: (
    <StationIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  shop: (
    <ShopIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  culture: (
    <CultureIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  cemetery: (
    <CemeteryIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  housing_area: (
    <HousingAreaIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  medicine: (
    <MedicineIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  metro: (
    <MetroIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  education: (
    <EducationIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  organization: (
    <OrganisationIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  hotel: (
    <HotelIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  park: (
    <ParkIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  restaurant: (
    <RestourantIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  sport: (
    <SportIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
  religion: (
    <ReligionIcon
      fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
    />
  ),
}

interface IResultsProps{
  results: IRoutePoint[];
  templates: ITemplate[]|null; // Если есть templates, то показываем его в место results
  setOnMapButton?: boolean;
  onClick: (item:IRoutePoint)=>void;
  onClickTemplate: (item:ITemplate)=>void;
  onClickSetOnMap: ()=>void;
  withoutContainerInResults?: boolean;
}

interface IResultProps{
  result: IRoutePoint;
  forcePrimaryText?: string;
  setOnMapIcon?: boolean;
  onClick: ()=>void
}

function Result(props:IResultProps){
  const {
    result,
    forcePrimaryText,
    onClick,
    setOnMapIcon
  } = props

  const primaryText = useMemo(()=>{
    if(!!result.poi){
      return result.poi.name
    }else if(!!result.street){
      let items = [result.street.name];
      if(!!result.house){
        items.push(result.house.number)
      }
      return items.filter((i)=>!!i).join(", ")
    }
    return null;
  }, [result])

  const secondaryText = useMemo(()=>{
    if(!!result.poi){
      return result.poi.new_name || result.poi.comment
    }else if(!!result.street){
      return result.street.new_name || result.street.comment
    }
    return null;
  }, [result])

  const icon = useMemo(()=>{
    const key = result.poi?.type;
    if(key!==undefined && POITypeIcons.hasOwnProperty(key)){
      //@ts-ignore
      return POITypeIcons[key]
    }
    if(setOnMapIcon){
      return (
        <SetOnMapIcon
          fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
        />
      )
    }

    return (
      <PlaceIcon
        fill={AppTheme.theme === 'dark' ? AppTheme.darkColor : AppTheme.darkColorDark}
      />
    )
  }, [result, setOnMapIcon])
  
  return (
    <ListItem
      button
      onClick={onClick}
      onMouseDown={(e)=>{
        e.preventDefault();
      }}
      sx={{
        px: 1.5,
        py: 0
      }}
    >
      <ListItemIcon sx={{
        minWidth: 30,
        '& img, & svg': {
          width: 18,
          height: 18,
          display: 'block'
        }
      }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={forcePrimaryText?forcePrimaryText:primaryText}
        secondary={forcePrimaryText?primaryText:secondaryText}
        secondaryTypographyProps={{
          className: "resultSecondaryText"
        }}
        sx={{
          "& .resultSecondaryText": {
            opacity: 0.5
          }
        }}
      />
    </ListItem>
  );
}

export default function SearchResults(props:IResultsProps) {
  const { t } = useTranslation();

  const {
    results,
    templates,
    onClick,
    onClickTemplate,
    withoutContainerInResults,
    setOnMapButton,
    onClickSetOnMap
  } = props

  const renderResults = useCallback(()=>{
    if(templates !== null && templates.length){
      return templates.map((i, index)=>(
        <Result
          key={index}
          forcePrimaryText={i.name}
          result={i.routepoints[0]}
          onClick={()=>onClickTemplate(i)}
        />
      ))
    }

    return results.map((i, index)=>(
      <Result
        key={index}
        result={i}
        onClick={()=>onClick(i)}
      />
    ))
  }, [results, templates, onClick, onClickTemplate])
  
  return (
    <BaseContainer fake={withoutContainerInResults}>
      <List
        disablePadding
        sx={{
          mx: -2,
          px: 1,
        }}
      >
        {setOnMapButton&&templates!==null?(
          <Result
            setOnMapIcon
            result={{}}
            forcePrimaryText={t('select_on_map')}
            onClick={()=>onClickSetOnMap()}
          />
        ):null}
        {renderResults()}
        {setOnMapButton&&templates===null?(
          <Result
            setOnMapIcon
            result={{}}
            forcePrimaryText={t('select_on_map')}
            onClick={()=>onClickSetOnMap()}
          />
        ):null}
      </List>
    </BaseContainer>
  );
}
