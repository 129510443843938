import NotFound from "apps/WebCabApp/components/notFound";
import useLayout from "apps/WebCabApp/layout/useLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { requests } from "utils/requests";
import { ITemplate } from "utils/types";
import TemplateForm from "./templateForm";


export default function TemplateEdit() {
  const match:any = useRouteMatch();
  const { t } = useTranslation();
  const {setTitle, goBack} = useLayout();
  const [notFound, setNotFound] = useState(false);
  const [item, setItem] = useState<ITemplate|null>(null)
  
  const load = useCallback(() => {
    requests.withoutErrorMessage().get(`/user/template/${match.params.id}/`).then((r)=>{
      setTitle(r.body.name || t('edit_template'));
      setItem(r.body);
    }).catch((e)=>{
      if(e.status === 404){
        setNotFound(true);
      }
    })
  }, [match.params.id, setTitle, t])

  useEffect(load, [load])

  const onSubmit = (template:ITemplate) => {
    requests.put(`/user/template/${match.params.id}/`, template).then((r)=>{
      goBack()
    })
  }

  if(notFound){
    return (<NotFound/>)
  }

  if(item === null){
    return null
  }
  
  return (
    <React.Fragment>
      <TemplateForm
        template={item}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
}
