import { Button, Container, FormControl, Grid, Typography, Alert, styled } from "@mui/material";
import PhoneModel from "models/PhoneModel";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { AppTheme } from "theme";
import CodeField from "./codeField";
import { requests } from "utils/requests";
import AuthModel from "models/AuthModel";
import useCountDown from 'react-countdown-hook';
import { pad } from "utils/helper";
import FirebaseLog from "component/firebaseLog";


const StyledEditLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  padding: '2px 10px',
  textDecoration: 'none',
  fontSize: '0.9em',
  marginLeft: theme.spacing(2),
  borderRadius: 5,
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
  
  color: AppTheme.buttonBg,
  backgroundColor: AppTheme.lightBg,
  '@media (prefers-color-scheme: dark)': {
    color: AppTheme.buttonBgDark,
    backgroundColor: AppTheme.lightBgDark,
  }
}));

function Code() {
  const { t } = useTranslation();
  const [timeLeft, { start }] = useCountDown(60 * 1000, 1000);
  const timeLeftDate = new Date(timeLeft);

  const [started, setStarted] = useState(false);

  const isCodeSent:boolean = useMemo(()=>PhoneModel.isCodeSent, [])
  const phoneNumber:string|null = useMemo(()=>{
    if(!PhoneModel.data){
      return null;
    }
    return `${PhoneModel.data.code}${PhoneModel.data.number}`
  }, [])
  const [code, setCode] = useState("");
  const [errorCode, setErrorCode] = useState(false);

  const onSubmit = (e:any) => {
    e.preventDefault();
  }

  useEffect(()=>{
    start();
    setStarted(true);
  }, [start])

  useEffect(()=>{
    if(code){
      requests.withoutErrorMessage().post("/auth/session/", {
        phone: `${PhoneModel.data?.code}${PhoneModel.data?.number}`,
        password: code
      }).then((r)=>{
        FirebaseLog.confirm_sms(r.status);
        AuthModel.login(r.body.session);
      }).catch((r)=>{
        FirebaseLog.confirm_sms(r.status, r);
        setErrorCode(true);
      })

    }else{
      setErrorCode(false);
    }
  }, [code])

  const resend = () => {
    requests.post("/auth/code/", {
      phone: `${PhoneModel.data?.code}${PhoneModel.data?.number}`,
      city: PhoneModel.data?.city_id
    }).then((r)=>{
      FirebaseLog.send_sms("resend", r.status);
      start();
    }).catch((r)=>{
      FirebaseLog.send_sms("resend", r.status);
    })
  }

  const renderTimerFinished = () => (
    <div>
      <br/><br/>
      <Typography align='center'>{t('resend_code_desc')}</Typography>
      <br/>
      <Button
        type="submit"
        variant="contained"
        size="large"
        color="primary"
        onClick={resend}
      >
        {t('resend_code')}
      </Button>
    </div>
  )

  if(!isCodeSent){
    return (<Redirect to="/" push={false} />)
  }
  
  return (
    <div>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <form onSubmit={onSubmit} noValidate={true}>
              <FormControl fullWidth>
                <CodeField
                  size={4}
                  error={errorCode}
                  onDone={setCode}
                  onClean={()=>setCode("")}
                />
                {errorCode ? (
                  <React.Fragment>
                    <br/>
                    <Alert severity="error">{t('wrong_confirmation_code')}</Alert>
                  </React.Fragment>
                ):null}
                <br/>
                <Typography>
                  {phoneNumber}
                  <StyledEditLink to="/">{t('edit')}</StyledEditLink>
                </Typography>
                <br/>
                <Typography
                  variant="h4"
                  sx={{
                    color: AppTheme.darkColor,
                    '@media (prefers-color-scheme: dark)': {
                      color: AppTheme.darkColorDark,
                    }
                  }}
                >
                  {pad(timeLeftDate.getMinutes(), 2)}:{pad(timeLeftDate.getSeconds(), 2)}
                  {started && timeLeft === 0 ? renderTimerFinished():null}
                </Typography>
                
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Code;
