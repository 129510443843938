import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { orderSerializer } from 'utils/order/orderSerializer';
import IOrder from 'utils/order/types';
import { requests } from 'utils/requests';
import ServiceWorkerPushContext, { ProviderContext } from './ServiceWorkerPushContext';


interface IProps{
  children: React.ReactNode;
}

export default function ServiceWorkerPushProvider(props: IProps) {
  const history = useHistory();

  useEffect(()=>{
    if (!('serviceWorker' in navigator)) return

    const toGO = (order:IOrder) => {
      requests.withoutErrorMessage().getAll('/order/', {
        active: true
      }).then((r)=>{
        const trips = r.body.results.map((i:any)=>orderSerializer(i));
        for(let i=0; i<trips.length; i++){
          if(trips[i].id === order.id){
            history.push(`/current-trips/${order.id}`);
            return;
          }
        }
        if(order.rating?.available){
          history.push(`/trips/${order.id}/#rating`);
        }else{
          history.push(`/trips/${order.id}`);
        }
      })
    }

    const onMessage = (event:MessageEvent<any>) => {
      if(event.data.messageType === "notification-clicked" && !!event.data.data){
        if(event.data.data.order_id){
          requests.withoutErrorMessage().get(`/order/${event.data.data.order_id}/`).then((r)=>{
            const order = orderSerializer(r.body);
            toGO(order);
          })
        }
      }
    }
    navigator.serviceWorker.addEventListener('message', onMessage);
    return () => {
      navigator.serviceWorker.removeEventListener('message', onMessage);
    }
  }, [history])

  const context:ProviderContext = {
  }

  return (
    <ServiceWorkerPushContext.Provider value={context}>
        {props.children}
    </ServiceWorkerPushContext.Provider>
  );
}