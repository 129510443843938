import { useCallback, useEffect, useState } from "react";
import IOrder from "utils/order/types";
import { IResponse, requests } from "utils/requests";
import { IPoint } from "utils/types";
import AuthModel from "./AuthModel";

export interface IClass{
  id: number,
  base_name: string,
  name: string,
  has_individual: boolean,
  is_default: boolean,
  maybe_better: boolean,
  images: {
    svg__dark: string,
    svg__light: string,
  }
}

export interface IOption{
  id: number,
  base_name: string,
  name: string,
  short_name: string,
  car_class: number,
  pre_order: boolean,
  price: number,
  extended_price: {
    city: number,
    suburb: number
  },
  images: {
    svg: string,
  }
}

interface ICityConfURLS{
  website?: string;
  instagram?: string;
  facebook?: string;
}

export interface ICityConf{
  is_payment_card: boolean,
  is_payment_apple_pay: boolean,
  is_payment_google_pay: boolean,
  is_driver_phone: boolean,
  urls: ICityConfURLS,
  primary_phone: string,
  phones: string[],
  classes: IClass[],
  options: IOption[],
  center: IPoint,
}

class _AppModel {
  private citySubscribers:((city:ICityConf|null)=>void)[] = [];
  private _city:ICityConf|null = null;
  private _createdOrders:{[id:number]:IOrder} = {};
  private _lang:string = "";

  public load = ():Promise<void> => {
    this._lang = requests.getCurrentLng();
    return new Promise((resolve, reject) => {
      let promises:Promise<any>[] = [
        requests.get('/city/conf/').then((r:IResponse) => {
          this.setCity(r.body);
          return r;
        })
      ]
      if(!requests.isWatcher()){
        promises.push(AuthModel.loadInfo())
        promises.push(AuthModel.loadCards())
        promises.push(AuthModel.loadCompanies())
      }
      Promise.all(promises).then((r:(IResponse|null)[])=>{
        const res = !!r.filter((response) => (response && response.status >= 200 && response.status <= 299)).length
        if(res){
          resolve()
        }else{
          reject();
        }
      }).catch(()=>{
        if(requests.isWatcher()){
          resolve()
        }else{
          setTimeout(() => {
            this.load().then(resolve);
          }, 2000);
        }
      }).finally(() => {
        
      })
    });
  }

  get lang():string {
    return this._lang;
  }

  get city():ICityConf|null {
    return this._city;
  }

  public setCity = (city:ICityConf|null) => {
    this._city = city;
    this.citySubscribers.forEach((callback)=>{
      callback(city);
    });
  }

  public addCreatedOrder = (order:IOrder) => {
    if(order.id){
      this._createdOrders[order.id] = order;
    }
  }

  public getCreatedOrder = (id:number):IOrder|null => {
    return this._createdOrders[id] || null;
  }

  public subscribeCity = (callback:(city:ICityConf|null)=>void) => {
    this.citySubscribers.push(callback);
  }
  public unsubscribeCity = (callback:(city:ICityConf|null)=>void) => {
    this.citySubscribers = this.citySubscribers.filter((c)=>c!==callback);
  }





  public getClassByID = (id:number):IClass|null => {
    const classes = this.city?.classes || [];
    for(let i=0; i<classes.length; i++){
      if(classes[i].id === id){
        return classes[i];
      }
    }
    return null;
  }

  public getOptionByID = (id:number):IOption|null => {
    const options = this.city?.options || [];
    for(let i=0; i<options.length; i++){
      if(options[i].id === id){
        return options[i];
      }
    }
    return null;
  }

  public getOptionByClass = (car_class_id:number):IOption[] => {
    const options = this.city?.options || [];
    return options.filter((i)=>i.car_class===car_class_id);
  }
}


const AppModel = new _AppModel();
export default AppModel;

export function useAppModel() {
  const [city, setCity] = useState<ICityConf|null>(AppModel.city);

  useEffect(() => {
    function handleStatusChange(city:ICityConf|null) {
      setCity(city !== null ? {...city} : city);
    }

    AppModel.subscribeCity(handleStatusChange);
    return () => {
      AppModel.unsubscribeCity(handleStatusChange);
    };
  });

  const getClassByID = useCallback((id:number):IClass|null => {
    const classes = city?.classes || [];
    for(let i=0; i<classes.length; i++){
      if(classes[i].id === id){
        return classes[i];
      }
    }
    return null;
  }, [city])

  const getOptionByID = useCallback((id:number):IOption|null => {
    const options = city?.options || [];
    for(let i=0; i<options.length; i++){
      if(options[i].id === id){
        return options[i];
      }
    }
    return null;
  }, [city])

  const getOptionByClass = useCallback((car_class_id:number):IOption[] => {
    const options = city?.options || [];
    return options.filter((i)=>i.car_class===car_class_id);
  }, [city])

  return {
    city: city,
    getClassByID: getClassByID,
    getOptionByID: getOptionByID,
    getOptionByClass: getOptionByClass,
  };
}