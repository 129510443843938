import { styled, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import NumberFormat from "react-number-format"


const Root = styled("span")(({ theme }) => ({
  whiteSpace: 'nowrap'
}));
const Prefix = styled("span")(({ theme }) => ({
  fontSize: '0.8em',
  opacity: 0.7,
  fontWeight: 'normal',
  marginLeft: 3
}));

interface IProps{
  value:(number|string|null|undefined),
  prefixSX?:SxProps<Theme>;
}

export default function Money(props:IProps) {
  const {
    value,
    prefixSX
  } = props;
  
  if(value === null || value === undefined){
    return null;
  }
  return (
    <Root>
      <NumberFormat
        value={value}
        displayType={'text'}
        thousandSeparator={' '}
        decimalScale={0}
        fixedDecimalScale={true}
      />
      <Prefix sx={prefixSX}>₴</Prefix>
    </Root>
  );
}