import ITheme from "./type";

const theme:ITheme = {
  theme: 'light',

  bg: '#FFFFFF',
  bgDark: '#21282D',
  lightBg: '#F1F5F4',
  lightBgDark: '#282F34',
  
  color: '#000000',
  colorDark: '#FFFFFF',
  darkColor: '#3B7280',
  darkColorDark: '#5E6B74',
  
  buttonBg: '#5ACBC7',
  buttonBgDark: '#1C918D',
  buttonColor: '#FFFFFF',
  buttonColorDark: '#FFFFFF',
  lightButtonBg: '#eefaf9',
  lightButtonBgDark: '#1f4445',
  darkButtonBg: '#1C918D',
  darkButtonBgDark: '#5ACBC7',
  alarmButtonBg: '#F55858',
  alarmButtonBgDark: '#F55858',

  orderStatusInProgressColor: '#17E262',
  orderStatusPreOrderColor: '#0BB3F2',
  orderStatusCompleteColor: '#7D7D7D',
}

export default theme;