import { Avatar, Dialog, List, ListItem, ListItemAvatar, ListItemText, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import IOrder from "utils/order/types";
import PhoneIcon from '@mui/icons-material/Phone';
import { AppTheme, hexToRgbA } from "theme";
import AppModel from "models/AppModel";
import { requests } from "utils/requests";
import { useSnackbar } from "notistack";
import { useProgress } from "component/progress";
import FirebaseLog from "component/firebaseLog";


const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: hexToRgbA(AppTheme.buttonBg, 0.08),
  color: AppTheme.buttonBg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: hexToRgbA(AppTheme.buttonBgDark, 0.08),
    color: AppTheme.buttonBgDark,
  }
}));

interface IProps{
  order: IOrder|null;
  onClose: ()=>void;
}

export default function CallOrderDialog(props: IProps) {
  const {isProgress} = useProgress();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    order,
    onClose,
  } = props

  const onDriverCall = () => {
    FirebaseLog.call_driver_from_order_click("driver");
    
    requests.post(`/order/${order?.id}/call-to-driver/`).then(()=>{
      enqueueSnackbar(t('driver_connect'), {variant: 'success'});
      onClose();
    })
  }

  return (
    <Dialog
      open={order!==null}
      onClose={onClose}
      maxWidth='xs'
    >
      <List>
        <ListItem
          button
          onClick={onDriverCall}
          disabled={!order?.call_sign || isProgress}
        >
          <ListItemAvatar>
            <StyledAvatar>
              <PhoneIcon />
            </StyledAvatar>
          </ListItemAvatar>
          <ListItemText primary={t('driver_call')} />
        </ListItem>
        <ListItem
          button
          onClick={()=>{
            FirebaseLog.call_driver_from_order_click("dispatcher");
            onClose();
          }}
          component="a"
          href={`tel:${AppModel.city?.primary_phone}`}
          disabled={isProgress}
        >
          <ListItemAvatar>
            <StyledAvatar>
              <PhoneIcon />
            </StyledAvatar>
          </ListItemAvatar>
          <ListItemText primary={t('dispatcher_call')} />
        </ListItem>
      </List>
    </Dialog>
  )
}