import { Box, List, ListItem, ListItemText } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import GreyLine from "apps/WebCabApp/components/greyLine";
import { APP_VERSION } from "config";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Soc from "./soc";


export default function Info() {
  const { t } = useTranslation();
  
  return (
    <Box sx={{
      flexGrow: 1,
      my: -4,
    }}>
      <GreyLine title={t('about_app')} />
      
      <BaseContainer>
        <List>
          <ListItem
            disableGutters
            button
            component={Link}
            to={'/privacy-policy'}
          >
            <ListItemText primary={t('policy')} />
            <ChevronRight/>
          </ListItem>
          <ListItem
            disableGutters
            button
            component={Link}
            to={'/feedback'}
          >
            <ListItemText primary={t('write_to_devs')} />
            <ChevronRight/>
          </ListItem>
          <ListItem disableGutters style={{opacity: 0.5}}>{t('app_version')}: {APP_VERSION}</ListItem>
        </List>
      </BaseContainer>
      
      <Soc/>
    </Box>
  );
}
