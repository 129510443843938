import { List, styled, TextField } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import CheckListItem from "apps/WebCabApp/components/checkListItem";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IOtherPassenger } from "utils/order/types";
import BaseDialog from "./baseDialog";

interface IProps{
  open: boolean;
  onClose: ()=>void;
}

interface IPassengerFormProps{
  value: IOtherPassenger|null;
  onChange: (value:IOtherPassenger)=>void;
  onSubmit: (value:IOtherPassenger)=>void;
}

const StyledForm = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const PassengerForm = React.forwardRef((props:IPassengerFormProps, ref:any) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm<IOtherPassenger>();

  const {
    onChange,
    onSubmit
  } = props

  const value:IOtherPassenger = useMemo(()=>{
    return props.value?props.value:{
      name: "",
      phone: ""
    }
  }, [props.value])

  const handleChange = (name:keyof IOtherPassenger) => (event:any) => {
    let v = event.target.value;
    if(name === 'phone'){
      if(v[0] === "0"){
        v = "+38" + v;
      }
    }
    onChange({
      ...value,
      [name]: v
    });
  };

  if(props.value === null) return null;

  return (
    <StyledForm
      ref={ref}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        label={t('firstname')}
        placeholder={t('enter_firstname')}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        margin='normal'
        value={value.name}
        onChange={handleChange('name')}
        inputProps={{...register("name", {
            required: {
              value: true,
              message: t('enter_firstname')
            }
          }),
        }}
        error={!!errors.name}
        helperText={!!errors.name ? errors.name.message : undefined}
      />

      <TextField
        label={t('phone_number')}
        placeholder={t('login_number_title')}
        InputLabelProps={{
          shrink: true,
        }}
        type="tel"
        fullWidth
        margin='normal'
        value={value.phone}
        onChange={handleChange('phone')}
        inputProps={{...register("phone", {
            required: {
              value: true,
              message: t('login_number_title')
            },
            pattern: {
              value: /^\+[0-9]{11,12}$/,
              message: t('wrong_number')
            }
          }),
          maxLength: 13
        }}
        error={!!errors.phone}
        helperText={!!errors.phone ? errors.phone.message : undefined}
      />
    </StyledForm>
  )
})

export default function PassengerDialog(props:IProps){
  const { t } = useTranslation();
  const {newOrder, setNewOrder} = useNewOrder();
  const [passenger, setPassenger] = useState<IOtherPassenger|null>(null);
  const formRef = useRef(null);

  const {
    open,
    onClose
  } = props;

  useEffect(()=>{
    setPassenger(newOrder.other_passenger || null)
  }, [newOrder])

  const onDone = () => {
    setNewOrder({
      ...newOrder,
      other_passenger: passenger===null?undefined:{...passenger}
    })
    onClose();
  }

  const onBack = () => {
    if(!passenger){
      onClose()
    }else{
      (formRef.current as any)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }
  

  return (
    <BaseDialog
      open={open}
      onClose={onBack}
      title={t('other_passenger')}
      onConfirm={passenger!==null?onBack:undefined}
    >
      <BaseContainer>
        <List>
          <CheckListItem
            title={t('im_going')}
            type="list"
            checked={passenger===null}
            onChange={()=>{
              setNewOrder({
                ...newOrder,
                other_passenger: undefined
              })
              onClose();
            }}
          />
          <CheckListItem
            title={t('other_passenger')}
            type="list"
            checked={passenger!==null}
            onChange={()=>{
              setPassenger({
                name: "",
                phone: ""
              })
            }}
          />
        </List>

        <PassengerForm
          ref={formRef}
          value={passenger}
          onChange={setPassenger}
          onSubmit={onDone}
        />
      </BaseContainer>
    </BaseDialog>
  )
}