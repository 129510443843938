import { Badge, Box, Drawer, Fab, Grid, styled, Typography } from "@mui/material";
import { Marker, Route } from "apps/WebCabApp/components/map/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import AppMenu from "./appMenu";
import AuthModel from "models/AuthModel";
import NumberFormat from "react-number-format";
import HelpDialog from "./helpDialog";
import { menuItems, menuTypes } from "./menuItems";
import useCurrentTrips from "apps/WebCabApp/providers/currentTripsProvider/useCurrentTrips";
import { useHistory, useLocation } from "react-router-dom";
import useCars from "apps/WebCabApp/providers/carsProvider/useCars";
import WSModel, { WSMessage } from "apps/WebCabApp/ws/WSModel";
import { orderDeserializer, orderSerializer } from "utils/order/orderSerializer";
import { useMessage } from "component/message";
import { useTranslation } from "react-i18next";
import IOrder, { OrderStatus } from "utils/order/types";
import AvtoSearch from "./trip/avtoSearch";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import NewOrderForm from "./trip/newOrderForm";
import { IRoutePoint } from "apps/WebCabApp/components/routePoints/types";
import { IPoint, IRoutePrice } from "utils/types";
import CurrentLocationModel from "models/CurrentLocationModel";
import AddressOnMapField from "apps/WebCabApp/components/addressOnMapField";
import { requests } from "utils/requests";
import { MapClass } from "apps/WebCabApp/components/map/map";
import { MapEvent } from "ol";
import { format } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import FirebaseLog from "component/firebaseLog";
import { AppTheme } from "theme";

const StyledMenuFabContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'inline-block',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

function Start() {
  const {trips, firstLoaded} = useCurrentTrips();
  const history = useHistory();
  const {cars} = useCars();
  const Message = useMessage();
  const { t } = useTranslation();
  const {hash} = useLocation();
  
  const {newOrder, setNewOrder, resetNewOrder} = useNewOrder();
  const [newOrderCopy, setNewOrderCopy] = useState(newOrder);
  const [map, setMap] = useState<MapClass|null>(null);
  const [orderAvtoSearch, setOrderAutoSearch] = useState<IOrder|null>(null);
  const [openMenu, setOpenMenu] = useState(false)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [forceCenter, setForceCenter] = useState<IPoint|undefined>(newOrder.routepoints[0]?.point ?? undefined);
  const [routePrice, setRoutePrice] = useState<IRoutePrice|null>(null)
  

  useEffect(()=>{
    if(!firstLoaded && !!trips.length && !!history){
      history.push('/current-trips');
    }
  }, [firstLoaded, trips, history])

  useEffect(() => {
    let need_calc_price = false;
    const abortController = new AbortController();

    if(newOrder.routepoints.length!==newOrderCopy.routepoints.length){
      need_calc_price=true;
    }
    if(!need_calc_price){
      newOrder.routepoints.forEach((point, index)=>{
        const point2 = newOrderCopy.routepoints[index];
        if(point2 === undefined) need_calc_price=true;
        else if(point.house?.number !== point2.house?.number) need_calc_price=true;
        else if(point.poi?.id !== point2.poi?.id) need_calc_price=true;
        else if(point.street?.id !== point2.street?.id) need_calc_price=true;
      })
    }

    if(need_calc_price){
      const o = orderSerializer(
        JSON.parse(
          JSON.stringify(newOrder)
        )
      );
      setNewOrderCopy(o);
      setRoutePrice(null);
      return
    }

    if(newOrder.routepoints.length>1){
      if(routePrice===null){
        requests.post('/route/price/', orderDeserializer(newOrder), {}, abortController.signal).then((r)=>{
          FirebaseLog.travel_cost(r.status);
          setRoutePrice(r.body)
        }).catch((r)=>{
          if(r !== null){
            FirebaseLog.travel_cost(r.status);
          }
          setRoutePrice(null);
        })
      }
    }else{
      setRoutePrice(null);
    }

    return () => {
      abortController.abort();
    }
  }, [newOrder, newOrderCopy, routePrice])

  const markers:Marker[] = useMemo(()=>{
    let _markers:Marker[] = [];
    _markers = _markers.concat(cars.map((car)=>({
      id: car.callsign,
      style: car.status === 0 ? "free" : "busy",
      point: {
        type: "Point",
        coordinates: car.point
      }
    })));

    if(newOrder.routepoints.length > 1){
      _markers = _markers.concat(newOrder.routepoints.map((i, index)=>{
        let point_style:any = 'point';
        switch(index){
          case 0:
            point_style = 'start_point';
            break;
          case (newOrder.routepoints.length - 1):
            point_style = 'end_point';
            break;
        }
        let marker_id:string = `${index}-${i.point?.coordinates}`
        const r:Marker = {
          id: marker_id,
          style: point_style,
          point: i.point as IPoint
        }
        return r
      }))
    }

    return _markers;
  }, [newOrder, cars])

  const route:Route|undefined = useMemo(()=>{
    if(routePrice === null) return undefined;
    const r:Route = {
      style: 'default',
      route: routePrice?.route,
    }
    return r;
  }, [routePrice])

  const onCloseMenu = () => {
    setOpenMenu(false);
  }

  const onOpenMenu = () => {
    setOpenMenu(true);
  }

  useEffect(()=>{
    const WS = (message:WSMessage) => {
      const order = orderSerializer(message.data);
      if(order.status === OrderStatus.REMOVED){
        Message({
          message: t('car_not_found')
        })
      }

      if(order.status === OrderStatus.CARSEARCH){
        setOrderAutoSearch(order);
      }else{
        if(order.status === OrderStatus.HEADING){
          setTimeout(()=>{
            resetNewOrder();
            setOrderAutoSearch(null);
            history.push('/current-trips');
          }, 1000)
        }else{
          setOrderAutoSearch(null);
        }

        if(order.status && [
          OrderStatus.REMOVED,
          OrderStatus.HEADING,
          OrderStatus.CANCELED,
        ].includes(order.status)){
          FirebaseLog.order_processing(order);
        }
      }
    }
    WSModel.subscribe(WS, /^webcab\.order$/);
    return () => {
      WSModel.unsubscribe(WS);
    }
  }, [t, Message, resetNewOrder, history])


  const updateFirstRoutePoint = useCallback((routepoint:IRoutePoint|null)=>{
    if(hash) return;

    const item:IRoutePoint = routepoint || {
      poi: {
        id: 0,
        name: t('address_not_found')
      }
    };
    setNewOrder((order)=>{
      const normal_routepoints = order.routepoints.filter((i)=>i.poi?.id||(i.street?.id&&i.house?.number));
      if(normal_routepoints.length === 1){
        normal_routepoints[0] = item;
      }else if(normal_routepoints.length === 0){
        normal_routepoints.push(item)
      }else{
        return order
      }
      return {
        ...order,
        routepoints: [...normal_routepoints]
      };
    })
  }, [t, setNewOrder, hash])

  const onAddressUpdated = useCallback((routepoints:IRoutePoint[])=>{
    if(!routepoints.length || (!routepoints[0].poi?.id && !routepoints[0].street?.id && !routepoints[0].house?.number)){
      if(CurrentLocationModel.point !== null){
        setForceCenter({...CurrentLocationModel.point})
      }
    }

    const firstRoutePoint = routepoints.length===1?routepoints[0]:null;
    if(!map || firstRoutePoint === null || !firstRoutePoint.point){
      setNewOrder((order)=>{
        order.routepoints = [...routepoints];
        return {...order};
      })
      return
    }

    const movestart = (e:MapEvent) => {
      e.preventDefault();
      e.stopPropagation();
    }
    const moveend = (e:MapEvent) => {
      e.preventDefault();
      e.stopPropagation();

      map?.map.un('movestart', movestart)
      map?.map.un('moveend', moveend)
    }
    map?.map.on('movestart', movestart)
    map?.map.on('moveend', moveend)

    map?.setCenter(firstRoutePoint.point)
    setNewOrder((order)=>{
      if(order.routepoints.length){
        order.routepoints[0] = firstRoutePoint;
      }else{
        order.routepoints.push(firstRoutePoint)
      }
      return {...order};
    })
    
  }, [map, setNewOrder])
  
  return (
    <Box sx={{
      flexGrow: 1,
      position: 'relative',
      my: -4,
    }}>

      <AddressOnMapField
        readOnly={!!route || newOrder.routepoints.length>1}
        onChange={updateFirstRoutePoint}
        forceCenter={forceCenter}
        onMapInit={setMap}
        mapProps={{
          markers: markers,
          route: route
        }}
      >
        <div>
          {orderAvtoSearch===null?(
            <NewOrderForm
              onAddressUpdated={onAddressUpdated}
              routePrice={routePrice}
              onCreated={(order)=>{
                if(!order.pre_order){
                  setOrderAutoSearch(order);
                }
                let message = order.message_after_create
                if(message){
                  if(order.pre_order && new RegExp(/[0-9]{2}:[0-9]{2}$/).test(message)){
                    const now = new Date();
                    const localFormat = format(now, "dd.MM.yyyy HH:mm");
                    let kievFormat = formatToTimeZone(now, "DD.MM.YYYY HH:mm", {
                      timeZone: "Europe/Kiev",
                      // timeZone: "Europe/Berlin"
                    });
                    if(localFormat !== kievFormat){
                      message += " " + t('kyiv_time_short')
                    }
                  }
                  Message({
                    message: message
                  })
                }
              }}
            />
          ):(
            <AvtoSearch
              order={orderAvtoSearch}
              onCanceled={(o)=>{
                setNewOrder(o);
                setOrderAutoSearch(null);
              }}
            />
          )}
        </div>
      </AddressOnMapField>


      <StyledMenuFabContainer>
        <Fab
          color="default"
          size="medium"
          onClick={onOpenMenu}
        >
          <Badge badgeContent={trips.length} color="error">
            <MenuIcon />
          </Badge>
        </Fab>
      </StyledMenuFabContainer>

      <Drawer open={openMenu} onClose={onCloseMenu}>
        <Box sx={{
          padding: 2,
          backgroundColor: AppTheme.lightBg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.lightBgDark,
          },
        }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <img src="/logo.svg" width={40} alt="" />
            </Grid>
            <Grid item>
              {AuthModel.info?.firstname?(
                <Typography><b>{AuthModel.info?.firstname}</b></Typography>
              ):null}
              <Typography variant='body2' noWrap>
                <NumberFormat value={AuthModel.info?.primary_phone} displayType={'text'} format="+## (###) ### ## ##" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <AppMenu
          onClick={(menu_type)=>{
            const menu_item = menuItems[menu_type];
            const isClose = menu_item.isAutoCloseMenu === undefined || menu_item.isAutoCloseMenu();
            if(menu_type === menuTypes.help && isClose){
              setHelpDialogOpen(true);
            }
            if(isClose){
              onCloseMenu()
            }
          }}
        />
      </Drawer>

      <HelpDialog
        open={helpDialogOpen}
        onClose={()=>{
          setHelpDialogOpen(false);
        }}
      />
    </Box>
  );
}

export default Start;
