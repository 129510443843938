import elit from "./themes/elit";
import _459 from "./themes/459";
import { red } from "@mui/material/colors";
import type {} from "@mui/lab/themeAugmentation";

import { alertClasses, buttonClasses, createTheme, inputClasses } from "@mui/material";
import { pickersDayClasses } from "@mui/lab/PickersDay";

export function hexToRgbA(hex:string, opacity:number):string{
  let c:any;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length===3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
  }
  throw new Error('Bad Hex');
}

const THEME_NAME = process.env['REACT_APP_THEME'] as string;
export let AppTheme = elit;
if(THEME_NAME === '459'){
  AppTheme = _459;
}
AppTheme.theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';


const defaultThem = createTheme({
  palette: {
    primary: {
      main: red[600]
    },
    secondary: {
      main: red[600]
    },
  },
  typography: {
    fontFamily: "'SF UI Text', Verdana, Arial, sans-serif",
    fontSize: 12
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: AppTheme.bg,
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.bgDark,
            color: AppTheme.colorDark,
          }
        },
        "*:not(input,textarea,select)": {
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          MsUserSelect: 'none',
          userSelect: 'none',
          WebkitAppearance: 'none'
        },
        "input,textarea,select": {
          WebkitAppearance: 'none',
          WebkitTextFillColor: 'inherit !important',
        },
        ".toggle-theme .light": {
          '@media (prefers-color-scheme: dark)': {
            display: 'none'
          }
        },
        ".toggle-theme .dark": {
          display: 'none',
          '@media (prefers-color-scheme: dark)': {
            display: 'inline'
          }
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root:{
          textDecorationColor: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            textDecorationColor: AppTheme.buttonBgDark,
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: AppTheme.bg,
          color: AppTheme.darkColor,
          borderBottom: `2px solid ${AppTheme.lightBg}`,
          fontWeight: 'bold',
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.bgDark,
            borderColor: AppTheme.lightBgDark,
            color: AppTheme.darkColorDark,
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h6:{
          fontWeight: 'bold'
        },
        root: {
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
          },
          

          "&.Mui-selected": {
            color: AppTheme.color + "!important",
            '@media (prefers-color-scheme: dark)': {
              color: AppTheme.colorDark + "!important",
            },
          },

        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: "inherit"
      },
      styleOverrides: {
        root: {
          borderRadius: 10,
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
          }
        },
        outlinedPrimary: {
          color: AppTheme.buttonBg,
          borderColor: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.buttonBgDark,
            borderColor: AppTheme.buttonBgDark,
          },
          '&:hover': {
            color: AppTheme.darkButtonBg,
            borderColor: AppTheme.darkButtonBg,
            backgroundColor: hexToRgbA(AppTheme.buttonBg, 0.12) + " !important",
            '@media (prefers-color-scheme: dark)': {
              color: AppTheme.darkButtonBgDark,
              borderColor: AppTheme.darkButtonBgDark,
              backgroundColor: hexToRgbA(AppTheme.buttonBgDark, 0.12) + " !important",
            },
          },
        },
        containedPrimary: {
          backgroundColor: AppTheme.buttonBg,
          color: AppTheme.buttonColor,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.buttonBgDark,
            color: AppTheme.buttonColorDark,
          },
          '&:hover': {
            backgroundColor: AppTheme.darkButtonBg,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.darkButtonBgDark,
            },
  
            '@media (hover: none)': {
              backgroundColor: AppTheme.darkButtonBg,
              '@media (prefers-color-scheme: dark)': {
                backgroundColor: AppTheme.darkButtonBgDark,
              }
            },
          },
  
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: AppTheme.buttonBg,
            color: AppTheme.buttonColor,
            opacity: 0.5,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.buttonBgDark,
              color: AppTheme.buttonColorDark,
            },
          },
        },
        textPrimary: {
          color: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.buttonBgDark,
          },
          '&:hover': {
            backgroundColor: AppTheme.lightBg,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.lightBgDark,
            }
          },
        },
        textSecondary: {
          color: AppTheme.darkColor,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.darkColorDark,
          },
          '&:hover': {
            backgroundColor: AppTheme.lightBg,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.lightBgDark,
            }
          },
        },
      }
    },
    MuiIconButton: {
      defaultProps: {
        color: "inherit"
      },
      styleOverrides: {
        root: {
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark
          }
        },
        colorPrimary: {
          color: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.buttonBgDark
          }
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: AppTheme.color,
          backgroundColor: AppTheme.bg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
            backgroundColor: AppTheme.bgDark,
          },
          '&:hover': {
            backgroundColor: AppTheme.lightBg,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.lightBgDark,
            }
          },
        },
        primary: {
          backgroundColor: AppTheme.buttonBg,
          color: AppTheme.buttonColor,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.buttonBgDark,
            color: AppTheme.buttonColorDark,
          },
          '&:hover': {
            backgroundColor: AppTheme.darkButtonBg,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.darkButtonBgDark,
            },
          },
        },
        secondary: {
          backgroundColor: hexToRgbA(AppTheme.buttonBg, 0.08),
          color: AppTheme.buttonBg,
          boxShadow: 'none',
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: hexToRgbA(AppTheme.buttonBgDark, 0.08),
            color: AppTheme.buttonBgDark,
          },
          '&:hover': {
            backgroundColor: hexToRgbA(AppTheme.buttonBg, 0.12) + " !important",
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: hexToRgbA(AppTheme.buttonBgDark, 0.12) + " !important",
            },
          },
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark
          },
          [`&.${inputClasses.disabled}`]: {
            color: AppTheme.color,
            opacity: 0.8,
            '@media (prefers-color-scheme: dark)': {
              color: AppTheme.colorDark
            },
          },
        },
        input: {
          fontSize: "16px",
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: `2px solid ${AppTheme.buttonBg}`,
            '@media (prefers-color-scheme: dark)': {
              borderColor: AppTheme.buttonBgDark
            }
          },
          [`&:hover:not(.${inputClasses.disabled}):before`]: {
            borderBottom: `2px solid ${AppTheme.buttonBg}`,
            '@media (prefers-color-scheme: dark)': {
              borderColor: AppTheme.buttonBgDark
            }
          },
          '&:after': {
            borderBottom: `2px solid ${AppTheme.darkButtonBg}`,
            '@media (prefers-color-scheme: dark)': {
              borderColor: AppTheme.darkButtonBgDark
            }
          },
          [`&.${inputClasses.error}:after`]: {
            borderColor: AppTheme.alarmButtonBg,
            '@media (prefers-color-scheme: dark)': {
              borderColor: AppTheme.alarmButtonBgDark
            }
          },
        },
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      },
      styleOverrides: {
        icon: {
          opacity: 0.7,
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: AppTheme.darkButtonBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.darkButtonBgDark,
          },
          '&.Mui-focused': {
            color: AppTheme.buttonBg,
            '@media (prefers-color-scheme: dark)': {
              color: AppTheme.buttonBgDark,
            },
          }
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.buttonBgDark,
          }
        },
      }
    },
  
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: AppTheme.lightBg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.lightBgDark,
          },
        },
        barColorPrimary: {
          backgroundColor: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.buttonBgDark,
          },
        }
      }
    },
  
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.buttonBgDark,
          },
        },
      }
    },
  
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: AppTheme.bg,
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.bgDark,
            color: AppTheme.colorDark,
          }
        }
      }
    },
  
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,

          [`&.${alertClasses.standardError}`]: {
            backgroundColor: 'rgba(146, 28, 22, 0.2)',
            opacity: 0.7,
            '@media (prefers-color-scheme: dark)': {
              color: '#ffffff',
            }
          }
        },
      }
    },
  
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: AppTheme.lightBg,
          '@media (prefers-color-scheme: dark)': {
            borderColor: AppTheme.lightBgDark,
          }
        }
      }
    },
    
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: 'inherit',
          color: 'inherit',
          backgroundColor: 'transparent',
          marginTop: 32,
          '&:first-of-type': {
            marginTop: 0
          }
        }
      }
    },
  
    MuiListItem: {
      styleOverrides: {
        button: {
          borderRadius: 3
        }
      }
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 3
        }
      }
    },
  
    MuiBadge: {
      styleOverrides: {
        colorError: {
          backgroundColor: AppTheme.alarmButtonBg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.alarmButtonBgDark,
          },
        }
      }
    },
  
    MuiDialog: {
      styleOverrides: {
        container: {
          minHeight: '-webkit-fill-available',
          maxHeight: '-webkit-fill-available',
        },
      },
    },
    
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: AppTheme.bg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.bgDark,
          }
        }
      }
    },
    
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: AppTheme.color,
          backgroundColor: AppTheme.lightBg,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
            backgroundColor: AppTheme.lightBgDark,
          },
          [`&.${pickersDayClasses.selected}`]: {
            backgroundColor: AppTheme.buttonBg,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: AppTheme.buttonBgDark,
            },
            "&:hover": {
              backgroundColor: AppTheme.darkButtonBg,
              '@media (prefers-color-scheme: dark)': {
                backgroundColor: AppTheme.darkButtonBgDark,
              }
            }
          }
        },
        [`&.${pickersDayClasses.disabled}`]: {
          opacity: 0.2,
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
          }
        },
        today: {
          borderColor: `${AppTheme.color} !important`,
          '@media (prefers-color-scheme: dark)': {
            borderColor: `${AppTheme.colorDark} !important`,
          }
        },
      }
    },
    
    MuiYearPicker: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: `${AppTheme.buttonBg} !important`,
            '@media (prefers-color-scheme: dark)': {
              backgroundColor: `${AppTheme.buttonBgDark} !important`,
            },
            "&:hover": {
              backgroundColor: `${AppTheme.darkButtonBg} !important`,
              '@media (prefers-color-scheme: dark)': {
                backgroundColor: `${AppTheme.darkButtonBgDark} !important`,
              }
            }
          }
        }
      }
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: AppTheme.lightBg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.lightBgDark,
          },
        },
        indicator: {
          backgroundColor: AppTheme.buttonBg,
          '@media (prefers-color-scheme: dark)': {
            backgroundColor: AppTheme.buttonBgDark,
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: AppTheme.color,
          '@media (prefers-color-scheme: dark)': {
            color: AppTheme.colorDark,
          },
          "&.Mui-selected": {
            color: AppTheme.buttonBg,
            '@media (prefers-color-scheme: dark)': {
              color: AppTheme.buttonBgDark,
            }
          }
        },
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
  },
});

export default defaultThem;