import CarsProvider from "apps/WebCabApp/providers/carsProvider/CarsProvider";
import CurrentTripsProvider from "apps/WebCabApp/providers/currentTripsProvider/CurrentTripsProvider";
import NewOrderProvider from "apps/WebCabApp/providers/newOrderProvider/newOrderProvider";
import { WSProvider } from "apps/WebCabApp/ws";
import FirebaseAnalytics from "component/firebaseAnalytics";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./layout";
import routes from "./routes";

interface ProvidersProps{
  children: JSX.Element;
}

function Providers(props:ProvidersProps){
  return (
    <WSProvider>
      <NewOrderProvider>
        <CurrentTripsProvider>
          <CarsProvider>
            {props.children}
          </CarsProvider>
        </CurrentTripsProvider>
      </NewOrderProvider>
    </WSProvider>
  );
}

export default function WatcherApp() {
  return (
    <BrowserRouter>
      <FirebaseAnalytics/>
      <Providers>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              exact={true}
              path={route.path}
              render={(props) => (
                <Layout>
                  <route.component
                    {...props}
                  />
                </Layout>
              )}
            />
          ))}
          <Route path='*' exact={true} render={() => (<Redirect to="/" />)} />
        </Switch>
      </Providers>
    </BrowserRouter>
  );
}
