import { Box, Typography, Rating, styled } from "@mui/material";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import useOrderRating from "apps/WebCabApp/providers/OrderRatingProvider/useOrderRating";
import AppModel from "models/AppModel";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { AppTheme } from "theme";
import Money from "utils/money";
import IOrder from "utils/order/types";


const Root = styled("div")(({ theme }) => ({
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: AppTheme.lightBg,
  borderRadius: 5,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
  cursor: 'pointer',
  '@media (prefers-color-scheme: dark)': {
    borderColor: AppTheme.lightBgDark,
  },
  '&:hover': {
    boxShadow: theme.shadows[4],
  }
}));

const Footer = styled("div")(({ theme }) => ({
  borderWidth: 0,
  borderTopWidth: 2,
  borderStyle: 'solid',
  borderColor: AppTheme.lightBg,
  padding: theme.spacing(1, 2),
  '@media (prefers-color-scheme: dark)': {
    borderColor: AppTheme.lightBgDark,
  },
}));

interface IProps{
  order: IOrder;
  onRatingAdded: (rating:number)=>void;
}

export default function OrderListItem(props:IProps) {
  const history = useHistory();
  const orderRating = useOrderRating();

  const {
    order,
    onRatingAdded
  } = props

  const orderClass = AppModel.getClassByID(order.car_class)
  
  return (
    <Root
      onClick={(e)=>{
        history.push(`/trips/${order.id}`)
      }}
    >
      <Box sx={{
        py: 1,
        px: 2,
        backgroundColor: AppTheme.lightBg,
        '@media (prefers-color-scheme: dark)': {
          backgroundColor: AppTheme.lightBgDark,
        },
      }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Moment
              date={order.date}
              format="DD.MM.YYYY, HH:mm"
            />
          </Box>
          <Box onClick={(e)=>{
            if(order.rating?.available){
              e.stopPropagation()
              orderRating.doRating(order.id || 0, 0).then(onRatingAdded);
            }
          }}>
            <Rating
              name={`rating-${order.id}`}
              max={5}
              size="small"
              readOnly={true}
              value={!order.rating?.available ? order.rating?.value || 0 : 0}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <RoutePoints
          routepoints={order.routepoints}
        />
      </Box>
      <Footer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            { orderClass ? orderClass.name : null }
          </Box>
          <Box>
            <Typography variant="subtitle1">
              <b>
                <Money
                  value={order.price}
                />
              </b>
            </Typography>
          </Box>
        </Box>
      </Footer>
    </Root>
  );
}
