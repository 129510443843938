import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import CodeFieldItem from "./codeFieldItem";


interface Props{
  size:number;
  error?:boolean;
  onDone?: (code:string) => void;
  onClean?: () => void;
}

interface ReducerAction{
  one?: {
    index:number;
    value:string;
  },
  all?: string[];
}

function reducer(state:string[], action:ReducerAction):string[] {
  if(action.one){
    let _state = [...state];
    _state[action.one.index] = action.one.value;
    return _state;
  }else if(action.all){
    return action.all;
  }
  throw new Error();
}

function CodeField(props:Props) {
  const {
    size,
    error,
    onDone,
    onClean
  } = props;

  const initCodeArray:string[] = useMemo(()=>{
    let _items = Array.from({length: size}, (v) => "");
    return _items;
  }, [size])
  
  const [codeArray, setCodeArray] = useReducer(reducer, initCodeArray);
  
  const refs:React.RefObject<any>[] = initCodeArray.map((i)=>React.createRef())
  
  const handlerChange = (index:number) => (v:string) => {
    setCodeArray({
      one: {
        index: index,
        value: v
      }
    });
  }

  const setCursor = useCallback(()=>{
    const first_empty = codeArray.indexOf("");

    const next_ref = refs[first_empty];
    if(next_ref){
      next_ref.current.focus();
    }else{
      refs.map((i)=>i.current.blur())
    }
  }, [codeArray, refs])

  const reset = () => {
    setCodeArray({
      all: initCodeArray
    });
    setCursor();
  }

  useEffect(()=>{
    if (typeof onDone === "function") {
      const str_value = codeArray.join("");
      if(str_value.length === size){
        onDone(str_value)
      }
    }
    if (typeof onClean === "function") {
      const str_value = codeArray.join("");
      if(!str_value.length){
        onClean()
      }
    }
  }, [codeArray, onDone, onClean, size])

  useEffect(setCursor, [setCursor])
  
  return (
    <Grid
      container
      justifyContent="space-between"
    >
      {codeArray.map((i, index)=>(
        <Grid
          key={index}
          item
        >
          <CodeFieldItem
            ref={refs[index]}
            value={i}
            error={error}
            onChange={handlerChange(index)}
            onFocus={() => {
              const first_empty = codeArray.indexOf("");
              if(index !== first_empty){
                reset()
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

CodeField.defaultProps = {
  error: false
}

export default CodeField;
