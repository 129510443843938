import { Box, Button, Dialog, Slide } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import LayoutAppBar from "apps/WebCabApp/layout/layoutAppBar";
import TripLayout from "apps/WebCabApp/layout/tripLayout";
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next";
import RoutePoints from ".";
import AddressOnMapField from "../addressOnMapField";
import { IRoutePoint } from "./types";

interface IProps{
  open: boolean;
  title: string;
  onClose: ()=>void;
  onSelect: (point:IRoutePoint|null)=>void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SetPointOnMapDialog(props:IProps){
  const { t } = useTranslation();
  const [routepoint, setRoutepoint] = useState<IRoutePoint|null>(null);

  const {
    open,
    title,
    onClose,
    onSelect
  } = props

  const addressOnChange = useCallback((routepoint:IRoutePoint|null)=>{
    setRoutepoint(routepoint);
  }, [])

  const routepointInfo:IRoutePoint = useMemo(()=>{
    if(routepoint===null){
      return {
        poi:{
          id: 0,
          name: t('address_not_found')
        }
      }
    }
    return routepoint
  }, [routepoint, t])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        className: "PaperDialog"
      }}
      sx={{
        "& .PaperDialog": {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <LayoutAppBar
        title={title}
        onBack={onClose}
        sx={{
          position: 'static',
          flexGrow: 0,
          flexShrink: 0,
        }}
      />
      <Box sx={{
        position: 'relative',
        flexGrow: 1,
        flexShrink: 1,
      }}>
        {open?(
          <AddressOnMapField
            onChange={addressOnChange}
          >
            <TripLayout>
              <RoutePoints
                routepoints={[routepointInfo]}
              />
              <Button
                onClick={()=>{
                  onSelect(routepoint)
                }}
                color="primary"
                variant="contained"
                size="medium"
                disabled={routepoint===null}
                fullWidth
                sx={{
                  mt: 2
                }}
              >{t('confirm')}</Button>
            </TripLayout>
          </AddressOnMapField>
        ):null}
      </Box>
    </Dialog>
  )
}