import { Box, Button, Divider, styled, TextField } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import GreyLine from "apps/WebCabApp/components/greyLine";
import React, { useCallback, useEffect, useState } from "react";
import { ITemplate } from "utils/types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import RoutePointsField from "apps/WebCabApp/components/routePoints/field";
import useLayout from "apps/WebCabApp/layout/useLayout";
import { IRoutePoint } from "apps/WebCabApp/components/routePoints/types";
import { ClearRoutePoints } from "apps/WebCabApp/components/routePoints/utils";


const RootForm = styled("form")(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

interface IProps{
  template: ITemplate;
  onSubmit: (item:ITemplate)=>void;
}

export default function TemplateForm(props:IProps) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm<ITemplate>();
  const {setFixedHeight} = useLayout();
  const [template, setTemplate] = useState<ITemplate>({...props.template})

  const [resultsElement, setResultsElement] = useState<JSX.Element|null>(null)

  useEffect(()=>{
    setFixedHeight(resultsElement!==null)
  }, [setFixedHeight, resultsElement])

  const onSubmit = (data:ITemplate) => {
    props.onSubmit({
      ...data,
      routepoints: ClearRoutePoints(template.routepoints)
    })
  };

  const handleChange = (name:keyof ITemplate) => (event:any) => {
    handleChangeValue(name)(event.target.value);
  };

  const handleChangeValue = (name:keyof ITemplate) => (value:any) => {
    template[name] = value as never;
    setTemplate({...template});
  }

  const handleChangeRoutePoints = useCallback((value:IRoutePoint[])=>{
    setTemplate({
      ...template,
      routepoints: value,
    })
  }, [template])
  
  
  return (
    <RootForm
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >

      <Box sx={{
        flexGrow: 0,
        flexShrink: 0,
      }}>
        <BaseContainer>
          <RoutePointsField
            value={template.routepoints}
            onChange={handleChangeRoutePoints}
            onRenderResults={setResultsElement}
          />
        </BaseContainer>
        
        <Divider sx={{
          my: 4,
        }} />
      </Box>

      {resultsElement!==null?resultsElement:(
        <div>
          <BaseContainer>
            <TextField
              label={t('template_name')}
              placeholder={t('enter_template_name')}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={template.name || ""}
              onChange={handleChange('name')}
              inputProps={{...register("name", {
                required: {
                  value: true,
                  message: t('enter_template_name')
                }
              }),
            }}
            error={!!errors.name}
            helperText={!!errors.name ? errors.name.message : undefined}
            />
          </BaseContainer>
          
          <GreyLine
            title={t('clarification')}
            sx={{
              my: 4,
            }}
          />

          <BaseContainer>
            <TextField
              placeholder={t('write_note')}
              multiline
              fullWidth
              inputProps={{...register("comment")}}
              value={template.comment || ""}
              onChange={handleChange('comment')}
            />

            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              sx={{
                mt: 4
              }}
            >{t('save')}</Button>
          </BaseContainer>
        </div>
      )}
    </RootForm>
  );
}
