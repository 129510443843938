import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Layout from "./layout";
import { WSProvider } from "./ws";
import routes from "./routes";
import ServiceWorkerPushProvider from "./providers/serviceWorkerPush/serviceWorkerPushProvider";
import React, { useMemo } from "react";
import CarsProvider from "./providers/carsProvider/CarsProvider";
import CurrentTripsProvider from "./providers/currentTripsProvider/CurrentTripsProvider";
import OrderRatingProvider from "./providers/OrderRatingProvider/OrderRatingProvider";
import NewOrderProvider from "./providers/newOrderProvider/newOrderProvider";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ukLocale from "date-fns/locale/uk";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "config";
import FirebaseAnalytics from "component/firebaseAnalytics";

const localeMap:any = {
  en: enLocale,
  uk: ukLocale,
  ru: ruLocale,
};

interface AppProps{
  children: JSX.Element;
}

function Providers(props:AppProps){
  const { i18n } = useTranslation();
  

  const muiPickerLocale:any = useMemo(()=>{
    return localeMap[i18n.language] || localeMap[DEFAULT_LANGUAGE]
  }, [i18n.language])
  
  return (
    <WSProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={muiPickerLocale}>
        <NewOrderProvider>
          <OrderRatingProvider>
            <CurrentTripsProvider>
              <CarsProvider>
                {props.children}
              </CarsProvider>
            </CurrentTripsProvider>
          </OrderRatingProvider>
        </NewOrderProvider>
      </LocalizationProvider>
    </WSProvider>
  );
}

function WebCabApp() {
  return (
    <Providers>
      <BrowserRouter>
        <FirebaseAnalytics/>
        <ServiceWorkerPushProvider>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                exact={true}
                path={route.path}
                render={(props) => (
                  <Layout
                    titleKey={route.name_key}
                  >
                    {route.component===null?null:(
                      <route.component
                        {...props}
                      />
                    )}
                  </Layout>
                )}
              />
            ))}
            <Route path='*' exact={true} render={() => (<Redirect to="/" />)} />
          </Switch>
        </ServiceWorkerPushProvider>
      </BrowserRouter>
    </Providers>
  );
}

export default WebCabApp;
