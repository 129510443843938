import BaseContainer from "apps/WebCabApp/components/baseContainer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentField from "apps/WebCabApp/components/paymentField";
import { IPayment } from "utils/types";
import { useSnackbar } from "notistack";
import AuthModel from "models/AuthModel";

export default function Payments() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  
  const [payment, setPayment] = useState<IPayment>(AuthModel.getDefaultPayment())

  const setDefaultPayment = (value:IPayment) => {
    setPayment(value);
    AuthModel.setDefaultPayment(value);
    enqueueSnackbar(t('default_payment_set'), {variant: 'success'})
  }
  
  return (
      <BaseContainer>
        <PaymentField
          value={payment}
          onChange={setDefaultPayment}
        />
      </BaseContainer>
  );
}
