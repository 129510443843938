import { styled } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import React from "react";
import { AppTheme } from "theme";


const Root = styled("div")(({ theme }) => ({
  pointerEvents: 'all',
  boxShadow: theme.shadows[4],
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: AppTheme.bg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.bgDark
  },
  '@media (max-width: 450px)': {
    margin: theme.spacing(-2),
    marginTop: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none'
  }
}));

interface IProps{
  children: React.ReactNode;
}

export default function TripLayout(props:IProps) {
  return (
    <BaseContainer>
      <Root>
        {props.children}
      </Root>
    </BaseContainer>
  );
}
