import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import React, { createRef, RefObject, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { IRoutePoint, IStreet, IStreetHouse } from "./types";
import { ReactComponent as FirstPointIcon } from 'images/first_point.svg';
import { AppTheme } from "theme";
import { Add, Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { requests } from "utils/requests";
import SearchResults from "./searchResults";
import { setNativeValue } from "utils/helper";
import { ITemplate } from "utils/types";
import SetPointOnMapDialog from "./setPointOnMapDialog";
import CurrentLocationModel from "models/CurrentLocationModel";
import AppModel from "models/AppModel";
import { StyledIconBox, StyledInput, StyledPointIcon, StyledRoutepointBox, StyledRoutepointField, StyledRoutepointNameBox } from "./styles";

interface IProps{
  value: IRoutePoint[];
  autoFocus?: number|"frontdoor"|null;
  withoutContainerInResults?: boolean;

  // onChange обязательно должен быть useCallback
  // иначе компонент падает в бесконечную рекурсию
  onChange: (value:IRoutePoint[], index:number|"frontdoor")=>void;

  onChangeDriverComment?: (value:string)=>void;
  
  onRenderResults: (element:JSX.Element|null)=>void;
  onChangeFocusIndex?: (index:number)=>void;
  onFinish?: ()=>void;
}

interface IQSettings{
  page: number;
  end: boolean;
  q: string;
}

interface IResultsReducerAction{
  action: "plus"|"replace"|"clear",
  data?: IRoutePoint[]
}

function resultsReducer(state:(IRoutePoint[]|null), action:IResultsReducerAction):(IRoutePoint[]|null) {
  if(action.action === 'plus'){
    return (state || []).concat(action.data || [])
  }else if(action.action === 'replace'){
    return action.data || []
  }else if(action.action === 'clear'){
    return null
  }

  return state;
}

export default function RoutePointsField(props:IProps) {
  const { t } = useTranslation();
  const [elRefs, setElRefs] = useState<RefObject<HTMLInputElement>[]>([]);
  const [results, setResults] = useReducer(resultsReducer, [] as (IRoutePoint[]));
  const scrollRef = useRef(null);
  const frontdoorRef = useRef(null);
  const [activeRef, setActiveRef] = useState<RefObject<HTMLInputElement>|null>(null);
  const [autoFocus, setAutoFocus] = useState<number|"frontdoor"|null>(null);
  const [activeStreet, setActiveStreet] = useState<IStreet|null>(null);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [pointOnMapDialogOpen_FieldIndex, setPointOnMapDialogOpen_FieldIndex] = useState<number|null>(null);
  const [pointFromMap, setPointFromMap] = useState<IRoutePoint|null>(null);

  const [settings, setSettings] = useState<IQSettings>({
    q: '',
    page: 0,
    end: false,
  })

  const {
    value,
    onChange,
    onChangeDriverComment,
    onRenderResults,
    withoutContainerInResults,
    onChangeFocusIndex,
    onFinish
  } = props
  const routepoints = [...value];

  const currentIndex = useMemo<number|null>(()=>{
    const element = activeRef?.current;
    if(!!element){
      //@ts-ignore
      const index:number = element.attributes.index.value - 0;
      return index
    }
    return null
  }, [activeRef])

  const addHandler = useCallback(() => {
    value.push({})
    onChange(value, value.length);
  }, [value, onChange])

  useEffect(()=>{
    requests.getAll('/user/template/').then((r)=>{
      let res = r.body.results.filter((i:ITemplate)=>!i.need_update&&i.routepoints.length===1)
      setTemplates(res);
    })
  }, [])

  useEffect(()=>{
    const element = activeRef?.current;
    if(!!element && onChangeFocusIndex!==undefined){
      //@ts-ignore
      const index:number = element.attributes.index.value - 0;
      onChangeFocusIndex(index);
    }
  }, [activeRef, onChangeFocusIndex])

  useEffect(()=>{
    setAutoFocus(props.autoFocus===undefined?null:props.autoFocus)
  }, [props.autoFocus])

  useEffect(()=>{
    if(typeof autoFocus === "number" && value.length <= autoFocus){
      addHandler();
    }
  }, [autoFocus, value, addHandler])


  useEffect(() => {
    setElRefs(elRefs => (
      value.map((_, i) => elRefs[i] || createRef())
    ));
  }, [value]);

  useEffect(() => {
    if(props.autoFocus !== null && props.autoFocus !== undefined) return;

    for(let i=0; i<value.length; i++){
      const v = value[i];
      if(!getValue(v)){
        const element = elRefs[i];
        if(element !== undefined && element.current !== null){
          element.current.focus();
          break
        }
      }
    }
  }, [value, elRefs, props.autoFocus]);

  const clickResultHandler = useCallback((item:IRoutePoint) => {
    const element = activeRef?.current;

    if(!!element && currentIndex !== null){
      const name = getValue(item);
      if(!!item.street && !item.house){
        // Выбрали улицу - курсор не убираем
      }else{
        element.blur();
      }
      setActiveStreet(item.street || null);
      setNativeValue(element, name);

      let _value = [...value];
      _value[currentIndex] = item;
      onChange(_value, currentIndex);

      if(item.poi?.id || (item.street?.id && item.house?.number)){
        for(let i=0; i<_value.length; i++){
          const routepoint = _value[i];
          if(!routepoint.poi?.id && !routepoint.street?.id && !routepoint.house?.number){
            const element = elRefs[i];
            if(element !== undefined && element.current !== null){
              element.current.focus();
            }
            return;
          }
        }
        if(onFinish!==undefined){
          onFinish();
        }
      }
    }
  }, [activeRef, currentIndex, elRefs, onChange, onFinish, value])

  useEffect(()=>{

    const handleScroll = (e:any) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
      if (bottom) { 
          setSettings({
            ...settings,
            page: settings.page + 1
          })
      }
    }

    const renderResults = () => {
      if(results === null) return null;

      let _results = [...results];
      let _templates:ITemplate[]|null = null;

      if(settings.q.length <= 2){
        _templates = [...templates]
      }

      return (
        <Box
          onScroll={handleScroll}
          ref={scrollRef}
          sx={{
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            my: -4,
            py: 2,
          }}
        >
          <SearchResults
            withoutContainerInResults={withoutContainerInResults}
            results={_results}
            templates={_templates}
            setOnMapButton={_templates!==null||settings.end}
            onClickSetOnMap={()=>{
              setPointOnMapDialogOpen_FieldIndex(currentIndex || 0)
            }}
            onClick={clickResultHandler}
            onClickTemplate={(template)=>{
              if(onChangeDriverComment!==undefined && currentIndex===0){
                onChangeDriverComment(template.comment)
              }
              clickResultHandler(template.routepoints[0])
            }}
          />
          {(!settings.end && results!==null && !!results.length)?(
            <div style={{textAlign: 'center'}}>
              <CircularProgress size={25} />
            </div>
          ):null}
        </Box>
      )
    }
  
    onRenderResults(renderResults());
  }, [
    withoutContainerInResults,
    results,
    settings,
    onRenderResults,
    currentIndex,
    onChangeDriverComment,
    templates,
    clickResultHandler
  ])

  useEffect(() => {

    if(activeRef === null){
      const timeoutController = setTimeout(()=>{
        setResults({
          action: 'clear'
        })
      }, 200)
      return () => {
        clearTimeout(timeoutController);
      };
    }

    if(settings.q.length <= 2){
      setResults({
        action: 'replace',
        data: []
      })
      return;
    }
    if(settings.end){
      return;
    }

    const abortController = new AbortController();
    const timeoutController = setTimeout(()=>{
      let params:any = {
        q: settings.q,
        page: settings.page
      }
      if(activeStreet !== null){
        params.street_id = activeStreet.id
      }
      if(CurrentLocationModel.point !== null){
        params.lat = CurrentLocationModel.point.coordinates[0];
        params.lng = CurrentLocationModel.point.coordinates[1];
      }else if(AppModel.city?.center){
        params.lat = AppModel.city?.center.coordinates[0];
        params.lng = AppModel.city?.center.coordinates[1];
      }
      requests.get('/address/search/', params, abortController.signal).then((r)=>{
        if(r.body.end !== settings.end){
          setSettings({
            ...settings,
            end: r.body.end
          })
        }
        setResults({
          action: settings.page === 0 ? 'replace' : 'plus',
          data: r.body.results
        })
      })
    }, 300)

    return () => {
      clearTimeout(timeoutController);
      abortController.abort();
    }
  }, [settings, activeStreet, activeRef])

  useEffect(()=>{
    const scrollElement:any = scrollRef.current;
    if(settings.page === 0 && scrollElement !== null){
      scrollElement.scrollTop = 0;
    }
  }, [settings, scrollRef])

  useEffect(()=>{
    if(autoFocus===undefined || autoFocus===null) return;

    if(autoFocus==="frontdoor"){
      if(!!frontdoorRef && !!frontdoorRef.current){
        (frontdoorRef.current as any).focus();
        setAutoFocus(null);
      }
      return
    }

    const element = elRefs[autoFocus];
    if(element !== undefined && element.current !== null){
      element.current.focus();
      setAutoFocus(null);
    }

  }, [autoFocus, elRefs, frontdoorRef])

  useEffect(()=>{
    if(pointFromMap!==null){
      setPointOnMapDialogOpen_FieldIndex(null)
    }
    if(!pointFromMap || !activeRef?.current || currentIndex===null) return;
    
    clickResultHandler({...pointFromMap})
    setPointFromMap(null);
  }, [pointFromMap, activeRef, currentIndex, clickResultHandler])

  const removeHandler = (index:number) => {
    routepoints.splice(index, 1);
    onChange(routepoints, index);
  }

  const clearHandler = (index:number, e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    routepoints[index] = {};
    onChange(routepoints, index);
    const element = elRefs[index].current;
    if(element !== null){
      setNativeValue(element, '');
      element.focus();
    }
  }

  const getValue = (routepoint:IRoutePoint):string => {
    let name:string = "";
    if(routepoint.poi){
      name = routepoint.poi.name
    }else if(routepoint.street){
      name = [
        routepoint.street.name,
        routepoint.street.comment || ""
      ].filter((i)=>!!i).join(" ")
      name += `, ${routepoint.house?.number || ''}`
    }
    return name;
  }

  const getIconBox = (index:number, length:number) => {
    var _classes = ["icon"];
    if(index > 0){
      _classes.push('topIconLine')
    }
    if((index + 1) < length){
      _classes.push('bottomIconLine')
    }
    const boxProps = {
      className: _classes.join(" "),
    }

    if(index === 0){
      return (
        <StyledIconBox {...boxProps}>
          <FirstPointIcon
            fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
            width={12}
            height={12}
            style={{left: -1}}
          />
        </StyledIconBox>
      )
    }else if((index+1) === length){
      return (
        <StyledIconBox {...boxProps}>
          <StyledPointIcon></StyledPointIcon>
        </StyledIconBox>
      )
    }

    return (
      <StyledIconBox {...boxProps}>
        <StyledPointIcon className="centerPointIcon"></StyledPointIcon>
      </StyledIconBox>
    )
  }
  
  return (
    <React.Fragment>
      <StyledRoutepointField>
        {routepoints.map((routepoint, index) => (
          <StyledRoutepointBox
            key={`${index}-${getValue(routepoint)}`}
            display="flex"
            alignItems="center"
            className="routepoint"
          >
            {getIconBox(index, routepoints.length)}
            <StyledRoutepointNameBox
              flexGrow={1}
              flexShrink={1}
              className="name"
            >
              <StyledInput
                autoFocus={index===currentIndex}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                //@ts-ignore
                index={index}
                ref={elRefs[index]}
                defaultValue={getValue(routepoint)}
                placeholder={index===0?t('starting_point'):t('destination_point')}
                onChange={(e)=>{
                  const val = e.target.value;

                  if(currentIndex !== null){
                    const valueString = getValue(routepoints[currentIndex])
                    if(activeStreet !== null){
                      const valueStringWithoutComma = valueString.replace(/,[^,]*$/, '');
                      if(!valueString || !val || !val.startsWith(valueStringWithoutComma)){
                        setActiveStreet(null);
                      }
                    }
                  }

                  setSettings({
                    ...settings,
                    q: val,
                    page: 0,
                    end: false
                  })
                }}
                onFocus={(e)=>{
                  setTimeout(()=>{
                    window.scrollTo(0,0)
                  }, 100)

                  setActiveRef(elRefs[index])
                  setSettings({
                    ...settings,
                    q: e.target.value,
                    page: 0,
                    end: false
                  })
                  if(!!value[index]?.street){
                    setActiveStreet(value[index].street!)
                  }
                }}
                onBlur={(e)=>{
                  if(currentIndex !== null){
                    const valueString = getValue(routepoints[currentIndex])
                    setNativeValue(e.target, valueString);
                  }
                  setActiveRef(null);
                }}
              />
              <IconButton
                  size='small'
                  onMouseDown={(e)=>{
                    e.preventDefault();
                  }}
                  onClick={(e)=>{
                    e.stopPropagation();
                    clearHandler(index, e)
                  }}
                  className="clearButton"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 4,
                    margin: 'auto 0',
                    width: 26,
                    height: 26,
                    opacity: 0.05
                  }}
                >
                  <Clear fontSize='small' />
                </IconButton>
            </StyledRoutepointNameBox>
            {(index === 0 && !!routepoints[0].house?.number)?(
              <Box>
                <StyledInput
                  ref={frontdoorRef}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  className="frontdoor"
                  placeholder={t('frontdoor')}
                  value={routepoint.house?.frontdoor || ""}
                  onChange={(e)=>{
                    let house:IStreetHouse = routepoints[index].house || {
                      number: ''
                    }
                    house.frontdoor = e.target.value
                    routepoints[index].house = house;
                    onChange(routepoints, 'frontdoor');
                  }}
                  onFocus={()=>{
                    setTimeout(()=>{
                      window.scrollTo(0,0)
                    }, 100)
                    
                    if(onChangeFocusIndex!==undefined){
                      onChangeFocusIndex(index);
                    }
                  }}
                />
              </Box>
            ):null}
            <Box sx={{
              pl: 0.5
            }}>
              {index === (routepoints.length - 1) && routepoints.length < 5 ?(
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={(e)=>{
                    e.stopPropagation();
                    addHandler();
                  }}
                  sx={{
                    minWidth: 0,
                    padding: 0,
                    margin: "3px",
                    borderRadius: "3px"
                  }}
                >
                  <Add fontSize='small' />
                </Button>
              ):(
                <IconButton
                  size='small'
                  onClick={(e)=>{
                    e.stopPropagation();
                    removeHandler(index);
                  }}
                  sx={{
                    minWidth: 0,
                    padding: "3px",
                  }}
                >
                  <Clear fontSize='small' />
                </IconButton>
              )}
            </Box>
          </StyledRoutepointBox>
        ))}
      </StyledRoutepointField>
      
      <SetPointOnMapDialog
        open={pointOnMapDialogOpen_FieldIndex!==null}
        title={!pointOnMapDialogOpen_FieldIndex?t('starting_point'):t('destination_point')}
        onClose={()=>{
          setPointOnMapDialogOpen_FieldIndex(null);
        }}
        onSelect={setPointFromMap}
      />
    </React.Fragment>
  );
}
