import { Box, styled } from "@mui/material"
import clsx from "clsx";
import React from "react"

interface IProps{
  title: string|JSX.Element;
  children: JSX.Element;
  titleSize?: 'normal'|'small';
  onClick?: ()=>void;
}

const StyledTitle = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
  lineHeight: 1,
  "&.small": {
    fontSize: "0.9em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.7em",
    }
  }
}));

export default function IconButtonBox(props:IProps){
  const {
    title,
    children,
    titleSize='normal',
    onClick
  } = props

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <Box
        flexGrow={1}
        flexShrink={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        sx={{
          mb: 0.5
        }}
      >
        {children}
      </Box>
      <StyledTitle
        className={clsx({
          small: titleSize==='small'
        })}
        flexGrow={0}
        flexShrink={0}
      >
        {title}
      </StyledTitle>
    </Box>
  )
}