import React from 'react';

export interface ProviderContext {
  showProgress: () => void;
  hideProgress: () => void;
  setProgress: (val:boolean) => void;
  isProgress: boolean;
}

// @ts-ignore
export default React.createContext<ProviderContext>();