import { Box } from "@mui/material";
import { useMemo } from "react";
import { IPayment, PaymentTypes } from "utils/types";
import { useTranslation } from "react-i18next";
import { ReactComponent as CashIcon } from 'images/payment/cash.svg';
import { ReactComponent as CardIcon } from 'images/payment/card.svg';
import { ReactComponent as CompanyIcon } from 'images/payment/company.svg';
import { ReactComponent as MastercardIcon } from 'images/payment/mastercard.svg';
import { ReactComponent as VisaIcon } from 'images/payment/visa.svg';
import { ReactComponent as ApplepayIcon } from 'images/payment/applePay.svg';
import { ReactComponent as GooglepayIcon } from 'images/payment/googlePay.svg';
import { AppTheme } from "theme";
import AuthModel from "models/AuthModel";
import React from "react";


interface IProps{
  payment?: IPayment
}

export function PaymentTypeIcon(props:IProps) {
  const {
    payment
  } = props

  if(!payment) return null;

  switch (payment.type) {
    case PaymentTypes.CASH:
      return (
        <CashIcon
          fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
          height={23}
        />
      );
    case PaymentTypes.CARD:
      const card = AuthModel.cardByID(payment.card);
      let icon = (
        <CardIcon
          stroke={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
          height={25}
        />
      );
      switch (card?.type) {
        case 'VISA':
          icon = (
            <VisaIcon
              width={45}
            />
          );
          break
        case 'MASTERCARD':
          icon = (
            <MastercardIcon
              height={25}
            />
          );
          break
      }
      return icon;
    case PaymentTypes.COMPANY:
      return (
        <CompanyIcon
          fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
          stroke={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
          height={25}
        />
      );
    case PaymentTypes.APPLE_PAY:
      return (
        <ApplepayIcon
          fill={AppTheme.theme === 'dark' ? "#FFFFFF" : "#000000"}
          stroke={AppTheme.theme === 'dark' ? "#FFFFFF" : "#000000"}
          height={26}
        />
      );
    case PaymentTypes.GOOGLE_PAY:
      return (
        <GooglepayIcon
          width={50}
          height={25}
        />
      );
    default:
      return null;
  }
}

export function PaymentTypeTitle(props:IProps) {
  const { t } = useTranslation();

  const {
    payment
  } = props

  const title = useMemo(()=>{
    if(!payment) return null;

    switch (payment.type) {
      case PaymentTypes.CASH:
        return t('cash');
      case PaymentTypes.CARD:
        const card = AuthModel.cardByID(payment.card);
  
        let card_name = t('card');
        if(card?.name){
          card_name = card?.name;
        }else if(card?.masked){
          const text = card?.masked;
          card_name = "..." + text.slice(text.length-4, text.length);
        }
  
        return card_name;
      case PaymentTypes.COMPANY:
        const company = AuthModel.companyByID(payment.company);
        return company?.name || t('company');
      case PaymentTypes.APPLE_PAY:
        return 'Apple Pay';
      case PaymentTypes.GOOGLE_PAY:
        return 'Google Pay';
      default:
        return null;
    }
  }, [payment, t])

  return (
    <React.Fragment>{title}</React.Fragment>
  )
}

export default function PaymentType(props:IProps) {
  const {
    payment
  } = props
  
  return (
    <React.Fragment>
      <Box sx={{
        marginBottom: "5px"
      }}>
        <PaymentTypeIcon payment={payment}/>
      </Box>
      <PaymentTypeTitle payment={payment}/>
    </React.Fragment>
  );
}
