import CurrentTrips from "apps/WebCabApp/pages/currentTrips";
import useCurrentTrips from "apps/WebCabApp/providers/currentTripsProvider/useCurrentTrips";
import AppModel from "models/AppModel";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./components/loader";
import NotFound from "./components/notFound";
import OrderFinished from "./components/OrderFinished";

export default function Watcher(){
  const { i18n } = useTranslation();
  const {trips, firstLoaded, loadedStatus} = useCurrentTrips();

  useEffect(()=>{
    if(AppModel.lang !== i18n.language){
      AppModel.load();
    }
  }, [i18n.language])

  if(!firstLoaded) return <Loader/>
  if(loadedStatus===200 && !trips.length) return <OrderFinished/>
  if(firstLoaded && !trips.length) return <NotFound/>
  
  return <CurrentTrips
    readOnly={true}
    disableBack={true}
    disableOnloadSound={true}
  />
}