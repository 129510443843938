import AppModel from 'models/AppModel';
import AuthModel from 'models/AuthModel';
import React, { useCallback, useEffect, useState } from 'react';
import IOrder from 'utils/order/types';
import newOrderContext, { ProviderContext } from './newOrderContext';


interface IProps{
  children: React.ReactNode;
}


export default function NewOrderProvider(props: IProps) {
  const getDefaultNewOrder = useCallback(()=>{
    return {
      car_class: AppModel.city?.classes[0].id || 0,
      routepoints: [],
      payment: AuthModel.getDefaultPayment()
    } as IOrder
  }, [])

  const [newOrder, setNewOrder] = useState<IOrder>(getDefaultNewOrder())
  useEffect(()=>{
    if(newOrder.payment){
      AuthModel.setDefaultPayment(newOrder.payment);
    }
  }, [newOrder])

  const resetNewOrder = useCallback(()=>{
    setNewOrder(getDefaultNewOrder())
  }, [getDefaultNewOrder])

  const context:ProviderContext = {
    newOrder: newOrder,
    setNewOrder: setNewOrder,
    resetNewOrder: resetNewOrder
  }

  return (
    <newOrderContext.Provider value={context}>
        {props.children}
    </newOrderContext.Provider>
  );
}