import { Box, Button, Divider, styled } from "@mui/material";
import { Add } from "@mui/icons-material";
import OrderDistance from "apps/WebCabApp/components/orderDistance";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import { IRoutePoint, IRoutePointComponent } from "apps/WebCabApp/components/routePoints/types";
import TripLayout from "apps/WebCabApp/layout/tripLayout";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import AppModel from "models/AppModel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { orderDeserializer, orderSerializer } from "utils/order/orderSerializer";
import IOrder from "utils/order/types";
import { IPayment, IRoutePrice } from "utils/types";
import AddressSearchDialog from "./dialog/addressSearchDialog";
import CarClassChoice from "./carClassChoice";
import ConfigLine from "./configLine";
import PaymentDialog from "./dialog/paymentDialog";
import PassengerDialog from "./dialog/passengerDialog";
import TimeDialog from "./dialog/timeDialog";
import ServicesDialog from "./dialog/servicesDialog";
import { format } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { AppTheme } from "theme";
import { requests } from "utils/requests";
import { useMessage } from "component/message";
import FailPaimentDialog from "./failPaymentDialog";
import { useHistory, useLocation } from "react-router-dom";
import FirebaseLog from "component/firebaseLog";
import IndividualDialog from "./dialog/individualDialog";
import clsx from "clsx";

interface IProps{
  onAddressUpdated?: (routepoints:IRoutePoint[]) => void;
  routePrice: IRoutePrice|null;
  onCreated: (order:IOrder)=>void;
}

interface IFailPayment{
  message: string;
  payment?: IPayment;
}

const KievTime = styled("span")(({ theme }) => ({
  color: AppTheme.alarmButtonBg,
  '@media (prefers-color-scheme: dark)': {
    color: AppTheme.alarmButtonBgDark,
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0)
}));

const StyledOrderButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  minHeight: 46,
  lineHeight: 1,
  "&.orderButtonLarge": {
    fontSize: '1.3em'
  }
}));

export default function NewOrderForm(props:IProps) {
  const {hash} = useLocation();
  const history = useHistory();
  
  const { t } = useTranslation();
  const {newOrder, setNewOrder, resetNewOrder} = useNewOrder();
  const Message = useMessage();
  const [activeAddress, setActiveAddress] = useState<number|"frontdoor"|null>(null);
  const [newOrderCopy, setNewOrderCopy] = useState<IOrder>({routepoints:[],car_class:0});
  const [texometerActive, setTexometerActive] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [passengerDialogOpen, setPassengerDialogOpen] = useState(false);
  const [timeDialogOpen, setTimeDialogOpen] = useState(false);
  const [servicesDialogOpen, setServicesDialogOpen] = useState(false);
  const [individualDialogOpen, setIndividualDialogOpen] = useState(false);
  const [failPayment, setFailPayment] = useState<IFailPayment|null>(null);
  const [disableButton, setDisableButton] = useState(true);

  const {
    onAddressUpdated,
    routePrice,
    onCreated
  } = props

  useEffect(()=>{
    setDisableButton(false);
    const o = orderSerializer(
      JSON.parse(
        JSON.stringify(
          orderDeserializer(newOrder)
        )
      )
    );
    setNewOrderCopy(o);
  }, [newOrder])

  const hashDialogs = useMemo(()=>{
    return [
      {
        hash: /^#payment$/,
        value: paymentDialogOpen,
        set: (v:RegExpMatchArray|null)=>{
          setPaymentDialogOpen(!!v)
        }
      },
      {
        hash: /^#passenger$/,
        value: passengerDialogOpen,
        set: (v:RegExpMatchArray|null)=>{
          setPassengerDialogOpen(!!v)
        }
      },
      {
        hash: /^#time$/,
        value: timeDialogOpen,
        set: (v:RegExpMatchArray|null)=>{
          setTimeDialogOpen(!!v)
        }
      },
      {
        hash: /^#services$/,
        value: servicesDialogOpen,
        set: (v:RegExpMatchArray|null)=>{
          setServicesDialogOpen(!!v)
        }
      },
      {
        hash: /^#individual$/,
        value: individualDialogOpen,
        set: (v:RegExpMatchArray|null)=>{
          setIndividualDialogOpen(!!v)
        }
      },
      {
        hash: /^#address-(frontdoor|[0-9]+)$/,
        value: servicesDialogOpen,
        set: (v?:RegExpMatchArray|null)=>{
          if(v && v.length > 1){
            //@ts-ignore
            setActiveAddress(v[1]==="frontdoor"?v[1]:v[1]-0)
          }else{
            setActiveAddress(null)
          }
        }
      }
    ]
  }, [
    paymentDialogOpen,
    passengerDialogOpen,
    servicesDialogOpen,
    timeDialogOpen,
    individualDialogOpen
  ])

  useEffect(()=>{
    hashDialogs.forEach((i)=>{
      const match = hash.match(i.hash)
      i.set(match)
    })
  }, [hash, hashDialogs])

  const realRoutePoints = useMemo(()=>{
    return newOrder.routepoints.filter((i)=>i.poi?.id||(i.street?.id&&i.house?.number))
  }, [newOrder])

  const getRoutepoints = useCallback((order:IOrder)=>{
    let res:IRoutePointComponent[] = order.routepoints.map((i)=>{
      let routepoint = {
        ...i,
        opacity: i.poi?.id === 0
      };
      return routepoint;
    });

    if(!res.length){
      res.push({
        poi: {
          id: 0,
          name: t('starting_point')
        },
        opacity: true
      })
    }

    if(res.length < 2){
      res.push({
        poi: {
          id: 0,
          name: t('destination')
        },
        opacity: true
      })
    }

    return res
  }, [t])


  const preOrderTimeFormat:JSX.Element|null = useMemo(()=>{
    if(!newOrder.pre_order || !newOrder.date) return null;

    const localFormat = format(newOrder.date, "dd.MM.yyyy HH:mm");
    let kievFormat = formatToTimeZone(newOrder.date, "DD.MM.YYYY HH:mm", {
      timeZone: "Europe/Kiev",
      // timeZone: "Europe/Berlin"
    });

    return (
      <React.Fragment>
        {kievFormat}
        {localFormat !== kievFormat ? (
          <KievTime>&nbsp;{t('kyiv_time_short')}</KievTime>
        ) : null}
      </React.Fragment>
    )
  }, [newOrder, t])

  const onAddressSearchFinish = (_new_order:IOrder) => {
    let o = {..._new_order};
    o.routepoints = [...o.routepoints.filter((i, index)=>index===0||i.poi?.id||(i.street?.id&&i.house?.number))];
    // setNewOrder({...o});
    if(onAddressUpdated !== undefined){
      onAddressUpdated(o.routepoints);
    }
  }

  const addRoutePoint = () => {
    history.push(`#address-${newOrder.routepoints.length}`)
  }

  const createOrder = () => {
    setDisableButton(true);
    FirebaseLog.order_button_click(newOrder);

    requests.withoutErrorMessage().post('/order/', orderDeserializer(newOrder)).then((r)=>{
      const o = orderSerializer(r.body);
      AppModel.addCreatedOrder(o);
      onCreated(o);
      if(o.pre_order){
        FirebaseLog.order_processing(newOrder, r);
        resetNewOrder();
      }
    }).catch((r)=>{
      setDisableButton(false);
      FirebaseLog.order_processing(newOrder, r);
      
      if(r.status === 403){
        setFailPayment({
          message: r.body?.detail,
          payment: newOrder.payment
        })
      }else if(r.body?.detail){
        Message({
          message: r.body?.detail
        })
      }
    })
  }

  return (
    <React.Fragment>
      <TripLayout>
        <RoutePoints
          routepoints={getRoutepoints(newOrder)}
          forceFrontDoor={true}
          onItemClick={(index)=>{
            history.push(`#address-${index}`)
          }}
          onFrontDoorClick={() => {
            history.push(`#address-frontdoor`)
          }}
        />

        {routePrice?(
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              mt: 1
            }}
          >
            <Box>
              <OrderDistance distance={routePrice?.distance}/>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={addRoutePoint}
                sx={{
                  borderRadius: "5px",
                  padding: 0,
                  minWidth: 0,
                  lineHeight: 1,
                }}
              >
                <Add/>
              </Button>
            </Box>
          </Box>
        ):null}
        
        {(AppModel.city?.classes || []).length>1?(
          <React.Fragment>
            <StyledDivider/>
            <CarClassChoice
              value={newOrder.car_class}
              optionIDs={(newOrder.additionalservices || [])}
              onChange={(value)=>{
                setNewOrder((o)=>{
                  const _services = (AppModel.city?.options || []).filter(
                    (i)=>(o.additionalservices || []).includes(i.id) && (!i.car_class || i.car_class===value)
                  ).map((i)=>i.id);

                  o.car_class = value;
                  o.additionalservices = _services;
                  return {...o}
                })
              }}
              onClearOptions={(options)=>{
                const ids = options.map((i)=>i.id);
                setNewOrder((o)=>{
                  const _services = (o.additionalservices || []).filter(
                    (i)=>!ids.includes(i)
                  );
                  o.additionalservices = _services;
                  return {...o}
                })
              }}
              onClickIndividual={()=>history.push(`#individual`)}
              onClickOption={(option)=>{
                setNewOrder((o)=>{
                  const _services = [...(o.additionalservices || [])];
                  if(!_services.includes(option.id)){
                    _services.push(option.id);
                  }
                  o.additionalservices = _services;
                  return {...o}
                })
              }}
            />
          </React.Fragment>
        ):null}
        <StyledDivider/>
        
        <ConfigLine
          routePrice={routePrice}
          texometerActive={texometerActive}
          onClickTaxometer={()=>setTexometerActive(!texometerActive)}
          onClickPayment={()=>history.push(`#payment`)}
          onClickPassenger={()=>history.push(`#passenger`)}
          onClickTime={()=>history.push(`#time`)}
          onClickServices={()=>history.push(`#services`)}
        />


        <div onClick={()=>{
          const length = newOrder.routepoints.length;
          if(length<2){
            history.push(`#address-${length}`)
          }
        }}>
          <StyledOrderButton
            className={clsx({
              orderButtonLarge: !preOrderTimeFormat
            })}
            onClick={(e)=>{
              e.stopPropagation();
              createOrder();
            }}
            color="primary"
            variant="contained"
            size="large"
            disabled={disableButton || (realRoutePoints.length<2&&(!texometerActive || !realRoutePoints.length))}
            fullWidth
          >
            <span>
              {t('order')}
              {preOrderTimeFormat?(
                <React.Fragment>
                  <br/><small>{preOrderTimeFormat}</small>
                </React.Fragment>
              ):null}
            </span>
          </StyledOrderButton>
        </div>
      </TripLayout>

      <AddressSearchDialog
        open={activeAddress!==null}
        routepoints={getRoutepoints(newOrderCopy)}
        driverComment={newOrderCopy.comment}
        activeAddress={activeAddress}
        onChangeDriverComment={(value)=>{
          setNewOrderCopy((o)=>{
            o.comment = value
            return {...o};
          })
        }}
        onChange={(routepoints, index)=>{
          const _new_order = {
            ...newOrderCopy,
            routepoints: [...routepoints]
          }
          setNewOrderCopy(_new_order)

          const normal_routepoints = routepoints.filter((i)=>i.poi?.id||(i.street?.id&&i.house?.number));
          if(index!=="frontdoor" && normal_routepoints.length===routepoints.length){
            onAddressSearchFinish(_new_order);
            history.goBack()
          }
        }}
        onClose={()=>{
          onAddressSearchFinish(newOrderCopy);
          history.goBack()
        }}
      />

      <PaymentDialog
        open={paymentDialogOpen}
        onClose={()=>history.goBack()}
      />

      <PassengerDialog
        open={passengerDialogOpen}
        onClose={()=>history.goBack()}
      />

      <TimeDialog
        open={timeDialogOpen}
        onClose={()=>history.goBack()}
      />

      <ServicesDialog
        open={servicesDialogOpen}
        onClose={()=>history.goBack()}
      />

      <FailPaimentDialog
        message={failPayment?.message}
        payment={failPayment?.payment}
        onChange={(payment)=>{
          setNewOrder((o)=>{
            o.payment = payment;
            return {...o}
          })
          setFailPayment(null)
        }}
        onClose={()=>{setFailPayment(null)}}
      />

      <IndividualDialog
        open={individualDialogOpen}
        onClose={()=>history.goBack()}
      />
    </React.Fragment>
  );
}
