import React from 'react';
import IOrder from 'utils/order/types';

export interface ProviderContext {
  trips: IOrder[];
  firstLoaded: boolean;
  loadedStatus: null|number;
  load: () => Promise<any>;
  callOrder: (order:IOrder) => void;
  setOrder: (order:IOrder) => void;
}

// @ts-ignore
export default React.createContext<ProviderContext>();