import React from 'react';
import IOrder from 'utils/order/types';

export interface ProviderContext {
  newOrder: IOrder;
  setNewOrder: React.Dispatch<React.SetStateAction<IOrder>>;
  resetNewOrder: ()=>void;
}

// @ts-ignore
export default React.createContext<ProviderContext>();