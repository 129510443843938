import { ReactComponent as FirstPointIcon } from 'images/first_point.svg';
import { AppTheme } from "theme";
import React from "react";
import { IRoutePoint, IRoutePointComponent } from "./types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { StyledIconBox, StyledPointIcon, StyledRoutepointBox, StyledRoutepointFrontdoorBox, StyledRoutepointNameBox } from "./styles";


interface IProps{
  routepoints: IRoutePointComponent[];
  defaultRoutePointName?: string|JSX.Element;
  forceFrontDoor?: boolean;
  onItemClick?: (index:number, routepoint:IRoutePointComponent) => void;
  onFrontDoorClick?: (routepoint:IRoutePointComponent) => void;
}

export default function RoutePoints(props:IProps) {
  const { t } = useTranslation();

  const {
    routepoints,
    defaultRoutePointName,
    forceFrontDoor,
    onItemClick,
    onFrontDoorClick
  } = props

  const renderName = (routepoint:IRoutePoint) => {
    if(routepoint.poi){
      return (
        <React.Fragment>
          {routepoint.poi.name}
          {routepoint.poi.comment ? (
            <small><br/>{routepoint.poi.comment}</small>
          ):null}
        </React.Fragment>
      )
    }else if(routepoint.street && routepoint.house){
      return (
        <React.Fragment>
          {routepoint.street.name}, {routepoint.house.number}
          {routepoint.street.comment ? (
            <small><br/>{routepoint.street.comment}</small>
          ):null}
        </React.Fragment>
      )
    }
    return defaultRoutePointName || null;
  }

  const getIconBox = (index:number, length:number) => {
    var _classes = [];
    if(index > 0){
      _classes.push('topIconLine')
    }
    if((index + 1) < length){
      _classes.push('bottomIconLine')
    }
    const boxProps = {
      className: _classes.join(" "),
      flexGrow: 0,
      flexShrink: 1
    }

    if(index === 0){
      return (
        <StyledIconBox {...boxProps}>
          <FirstPointIcon
            fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
            width={12}
            height={12}
          />
        </StyledIconBox>
      )
    }else if((index+1) === length){
      return (
        <StyledIconBox {...boxProps}>
          <StyledPointIcon></StyledPointIcon>
        </StyledIconBox>
      )
    }

    return (
      <StyledIconBox {...boxProps}>
        <StyledPointIcon className="centerPointIcon"></StyledPointIcon>
      </StyledIconBox>
    )
  }
  
  return (
    <div>
      {routepoints.map((routepoint, index) => (
        <StyledRoutepointBox
          key={index}
          display="flex"
          alignItems="center"
          className={clsx({
            routepointOnClick: onItemClick!==undefined
          })}
        >
          {getIconBox(index, routepoints.length)}
          <StyledRoutepointNameBox
            flexGrow={1}
            flexShrink={1}
            className={clsx({
              routepointOpacity: routepoint.opacity
            })}
            onClick={()=>{
              if(onItemClick!==undefined){
                onItemClick(index, routepoint)
              }
            }}
          >
            {renderName(routepoint)}
          </StyledRoutepointNameBox>
          {index===0 && !!routepoints[0].house?.number && (routepoint.house?.frontdoor || forceFrontDoor)?(
            <StyledRoutepointFrontdoorBox
              flexGrow={0}
              flexShrink={1}
              className={clsx({
                emptyFrontdoorItem: !routepoint.house?.frontdoor
              })}
              onClick={()=>{
                if(onFrontDoorClick!==undefined){
                  onFrontDoorClick(routepoint)
                }
              }}
            >
              {routepoint.house?.frontdoor
                ? `${t('frontdoor_short')} ${routepoint.house?.frontdoor}`
                : t('frontdoor')
              }
            </StyledRoutepointFrontdoorBox>
          ):null}
        </StyledRoutepointBox>
      ))}
    </div>
  );
}
