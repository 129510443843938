import { IResponse, requests } from "utils/requests";
import { IPayment, PaymentTypes } from "utils/types";
import PhoneModel from "./PhoneModel";

export interface IUser{
  firstname: string,
  lastname: string,
  middlename: string,
  primary_phone: string,
  sub_phone: string,
  email: string,
  city: number,
}

export interface IUserUpdate{
  firstname: string,
  sub_phone: string,
  email: string,
}

export interface IUserCard{
  id: number;
  name: string;
  default: boolean;
  masked?: string;
  type?: 'VISA'|'MASTERCARD';
}

export interface IUserCompany{
  id: number;
  name: string;
  balance: number|null;
}

class _AuthModel {
  key = 'Session';
  private _info:IUser|null = null;
  private _cards:IUserCard[] = [];
  private _companies:IUserCompany[] = [];
  private _pushToken:string|null = null;

  public loadInfo = ():Promise<IResponse|null> => {
    if(!this.if_auth()){
      return Promise.resolve(null);
    }else{
      return requests.get('/user/info/').then((r:IResponse) => {
        this._info = r.body;
        return r;
      });
    }
  }

  public updateInfo = (data:IUserUpdate):Promise<IResponse|null> => {
    const postData = {
      firstname: data.firstname,
      sub_phone: data.sub_phone,
      email: data.email,
      city: this.info?.city || 0,
    }
    return requests.put('/user/info/', postData).then((r)=>{
      this._info = r.body;
      return r;
    })
  }

  public loadCards = ():Promise<IResponse|null> => {
    if(!this.if_auth()){
      return Promise.resolve(null);
    }else{
      return requests.get('/user/card/').then((r:IResponse) => {
        this._cards = r.body;
        return r;
      });
    }
  }

  public loadCompanies = ():Promise<IResponse|null> => {
    if(!this.if_auth()){
      return Promise.resolve(null);
    }else{
      return requests.get('/user/company/').then((r:IResponse) => {
        this._companies = r.body;
        return r;
      });
    }
  }

  get info():IUser|null {
    return this._info;
  }

  get cards():IUserCard[] {
    return this._cards;
  }

  get companies():IUserCompany[] {
    return this._companies;
  }

  public getDefaultPayment = ():IPayment => {
    const StorageDefaultPayment = localStorage.getItem("DefaultPayment");
    const _DefaultPayment:IPayment = {
      type: PaymentTypes.CASH
    }
    let DefaultPayment:IPayment = _DefaultPayment;
    if(StorageDefaultPayment !== null){
      DefaultPayment = JSON.parse(StorageDefaultPayment)
    }

    if(DefaultPayment.type === PaymentTypes.CARD && this.cardByID(DefaultPayment.card) === null){
      DefaultPayment = _DefaultPayment;
    }else if(DefaultPayment.type === PaymentTypes.COMPANY && this.companyByID(DefaultPayment.company) === null){
      DefaultPayment = _DefaultPayment;
    }

    return DefaultPayment
  }

  public setDefaultPayment = (payment:IPayment) => {
    localStorage.setItem("DefaultPayment", JSON.stringify(payment));
  }

  public cardByID = (id?:number):IUserCard|null => {
    if(id === undefined) return null;
    let filteredCard = this.cards.filter((i)=>i.id===id);
    return filteredCard[0] || null;
  }

  public companyByID = (id?:number):IUserCompany|null => {
    if(id === undefined) return null;
    let filteredCompany = this.companies.filter((i)=>i.id===id);
    return filteredCompany[0] || null;
  }

  public if_auth = ():boolean => {
    return !!this.session()
  }
  public login = (session:string):void => {
    localStorage.setItem(this.key, session);
    window.location.reload();
  }
  public session = ():string|null => {
    return localStorage.getItem(this.key);
  }
  public removeSession = ():void => {
    localStorage.removeItem(this.key);
    window.location.reload();
  }
  public logout = ():void => {
    requests.get('/auth/logout/').then(() => {
      PhoneModel.removeData();
      this.removeSession();
    });
  }

  public auth = (email:string, password:string):Promise<IResponse> => {
    return requests.post('/auth/session/', {
      email: email,
      password: password,
      push_token: this.pushToken
    }).then((r:any) => {
      if(r.status === 200){
        this.login(r.body?.session);
      }
      return r;
    })
  }

  get pushToken():string|null {
    return this._pushToken;
  }

  public setPushToken = (token:string|null) => {
    this._pushToken = token;
    if(token!==null && (this.if_auth() || requests.isWatcher())){
      requests.post('/session/pushtoken/', {
        token: token
      })
    }
  }
}


const AuthModel = new _AuthModel();
export default AuthModel;