import { Box } from "@mui/material";
import OLMap from "apps/WebCabApp/components/map";
import { Marker, Route } from "apps/WebCabApp/components/map/types";
import NotFound from "apps/WebCabApp/components/notFound";
import useLayout from "apps/WebCabApp/layout/useLayout";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { orderSerializer } from "utils/order/orderSerializer";
import IOrder from "utils/order/types";
import { requests } from "utils/requests";
import { IPoint, IRoute } from "utils/types";
import { TRIP_ORDER_STATUSES } from "../trips";


export default function MapTrip() {
  const match:any = useRouteMatch();
  const {setTitle} = useLayout();
  const [order, setOrder] = useState<IOrder|null>(null)
  const [routeInfo, setRouteInfo] = useState<IRoute|null>(null)
  const [notFound, setNotFound] = useState(false);

  const load = useCallback(() => {
    requests.withoutErrorMessage().get(`/order/${match.params.id}/`, {
      status__in: TRIP_ORDER_STATUSES.join(",")
    }).then((r)=>{
      const _order = orderSerializer(r.body);
      setTitle(format(_order.date as Date, "dd.MM.yyyy HH:mm"));
      setOrder(_order);
      requests.post('/route/', {
        routepoints: _order.routepoints
      }).then((r)=>{
        setRouteInfo(r.body)
      })
    }).catch((e)=>{
      if(e.status === 404){
        setNotFound(true);
      }
    })
  }, [match.params.id, setTitle])

  useEffect(load, [load])
  
  const merkers:Marker[] = useMemo(()=>{
    if(order === null) return [];
    
    return order.routepoints.map((i, index)=>{
      let point_style:any = 'point';
      switch(index){
        case 0:
          point_style = 'start_point';
          break;
        case (order.routepoints.length - 1):
          point_style = 'end_point';
          break;
      }
      const r:Marker = {
        id: index.toString(),
        style: point_style,
        point: i.point as IPoint
      }
      return r
    })
    
  }, [order])

  const route:Route|undefined = useMemo(()=>{
    if(routeInfo === null) return undefined;
    const r:Route = {
      style: 'default',
      route: routeInfo?.route,
    }
    return r;
  }, [routeInfo])

  if(notFound){
    return (<NotFound/>)
  }

  if(order === null){
    return null
  }
  
  return (
    <Box sx={{
      flexGrow: 1,
      my: -4,
    }}>
      <OLMap
        markers={(order !== null && routeInfo !== null) ? merkers : undefined}
        route={(order !== null && routeInfo !== null) ? route : undefined}
      />
    </Box>
  );
}
