import { AppBar, Box, IconButton, Theme, Toolbar, Typography } from "@mui/material";
import { SxProps } from '@mui/system';
import { ArrowBack } from "@mui/icons-material";
import React from "react";

interface IProps{
  title: string;
  onBack: ()=>void;
  sx?: SxProps<Theme>;
}

export default function LayoutAppBar(props:IProps){

  return (
    <AppBar sx={props.sx}>
      <Toolbar>
        <Box sx={{flex: '0 0 3em'}}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={()=>{
              props.onBack();
            }}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >{props.title}</Typography>
        <Box sx={{flex: '0 99999 3em'}}></Box>
      </Toolbar>
    </AppBar>
  )
}