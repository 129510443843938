import { Button } from '@mui/material';
import Sound from 'apps/WebCabApp/components/sound';
import { WSModel } from 'apps/WebCabApp/ws';
import { WSMessage } from 'apps/WebCabApp/ws/WSModel';
import AppModel from 'models/AppModel';
import { useSnackbar } from 'notistack';
import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { orderSerializer } from 'utils/order/orderSerializer';
import IOrder, { OrderStatus } from 'utils/order/types';
import { IResponse, requests } from 'utils/requests';
import useWindowFocus from 'utils/useWindowFocus';
import useOrderRating from '../OrderRatingProvider/useOrderRating';
import ArrivedOrderDialog from './arrivedOrderDialog';
import CallOrderDialog from './callOrderDialog';
import CurrentTripsContext, { ProviderContext } from './CurrentTripsContext';

interface IProps{
  children: React.ReactNode;
}

export const CURRENT_ORDER_STATUSES = [
  OrderStatus.ARRIVED,
  OrderStatus.IN_PROGRESS,
  OrderStatus.HEADING
]
const CURRENT_PREORDER_STATUSES = CURRENT_ORDER_STATUSES.concat([
  OrderStatus.CARSEARCH,
  OrderStatus.CHECKOUT,
  OrderStatus.INCOMING
])

interface IOrderReducerAction{
  action: "set"|"replace_one",
  data: IOrder[]|IOrder
}

function orderReducer(state:(IOrder[]), action:IOrderReducerAction):(IOrder[]) {
  if(action.action === "set"){
    return action.data as IOrder[]
  }else if(state !== null && action.action === "replace_one"){
    const order = action.data as IOrder;
    let _state = state.map((i) => {
      if(i.id === order.id){
        return order
      }
      return i;
    })
    if(order.status === undefined || (!order.pre_order && !CURRENT_ORDER_STATUSES.includes(order.status)) || (order.pre_order && !CURRENT_PREORDER_STATUSES.includes(order.status))){
      _state = state.filter((i)=>i.id!==order.id);
    }else{
      if(state !== null){
        const is_order_exists = !!state.filter((i)=>i.id===order.id).length
        if(!is_order_exists){
          _state.unshift(order)
        }
      }
    }
    return _state;
  }
  return state;
}

export default function CurrentTripsProvider(props: IProps) {
  const {playCarAlarm} = Sound();
  const orderRating = useOrderRating();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const windowFocused = useWindowFocus();
  const [trips, setTrips] = useReducer(orderReducer, [] as IOrder[]);
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const [loadedStatus, setLoadedStatus] = useState<null|number>(null);
  const [arrivedOrder, setArrivedOrder] = useState<IOrder|null>(null);
  const [callOrder, setCallOrder] = useState<IOrder|null>(null);

  const load = useCallback(() => {
    let _requests = requests;
    if(requests.isWatcher()){
      _requests = requests.withoutErrorMessage();
    }
    return _requests.getAll('/order/', {
      active: true,
      lang: i18n.language
    }).then((r:IResponse) => {
      const _trips = r.body.results.map((i:any)=>orderSerializer(i));
      setTrips({
        action: 'set',
        data: _trips
      });
      setLoadedStatus(r.status)
      setFirstLoaded(true)
      return _trips;
    }).catch(()=>{
      setFirstLoaded(true)
    });
  }, [i18n.language])

  useEffect(()=>{
    const WS = (message:WSMessage) => {
      const order = orderSerializer(message.data);
      if(order.status === OrderStatus.ARRIVED){
        playCarAlarm()
        setArrivedOrder(order);
      }else if(order.status === OrderStatus.IN_PROGRESS){
        setArrivedOrder(null);
      }else if(order.status === OrderStatus.FULFILLED){
        if(order.id && !requests.isWatcher()){
          orderRating.doRating(order.id || 0, 0);
        }
      }else if(order.status === OrderStatus.HEADING){
        const orderClass = AppModel.getClassByID(order.car_class)
        if(orderClass?.maybe_better && order.car_class !== order.call_sign?.car.car_class){
          enqueueSnackbar(t('car_class_better'), {
            variant: 'info',
            autoHideDuration: 10000,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            action: (key) => (
              <Button onClick={() => { closeSnackbar(key) }}>
                {t('ok')}
              </Button>
            )
          })
        }
      }

      setTrips({
        action: 'replace_one',
        data: order
      });
    }
    WSModel.subscribe(WS, /^webcab\.order$/);
    return () => {
      WSModel.unsubscribe(WS);
    }
  }, [load, playCarAlarm, orderRating, enqueueSnackbar, closeSnackbar, t])

  useEffect(()=>{
    if(windowFocused){
      load()
    }
  }, [windowFocused, load])

  const setOrder = useCallback((order:IOrder) => {
    setTrips({
      action: 'replace_one',
      data: order
    });
  }, [])

  const context:ProviderContext = {
    trips: trips,
    firstLoaded: firstLoaded,
    loadedStatus: loadedStatus,
    load: load,
    callOrder: setCallOrder,
    setOrder: setOrder
  }

  return (
    <CurrentTripsContext.Provider value={context}>
        {props.children}
        <ArrivedOrderDialog
          order={arrivedOrder}
          onClose={()=>{
            setArrivedOrder(null);
          }}
          onCall={()=>{
            setCallOrder(arrivedOrder);
            setArrivedOrder(null);
          }}
        />
        <CallOrderDialog
          order={callOrder}
          onClose={()=>{
            setCallOrder(null)
          }}
        />
    </CurrentTripsContext.Provider>
  );
}