import { Checkbox, ListItemButton, listItemButtonClasses, ListItemIcon, ListItemSecondaryAction, ListItemText, styled, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckIcon from "@mui/icons-material/Check";
import clsx from "clsx";
import { AppTheme } from "theme";

interface IProps{
  title: string;
  image?: JSX.Element;
  checked: boolean;
  subtitle?: string|JSX.Element|null;
  type: 'check_list'|'list';
  onChange: (checked:boolean) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  "&.listType": {
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 5,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    borderColor: 'transparent',
    backgroundColor: AppTheme.lightBg,
    '@media (prefers-color-scheme: dark)': {
      borderColor: 'transparent',
      backgroundColor: AppTheme.lightBgDark,
    },

    '&.checked': {
      borderColor: AppTheme.buttonBg,
      '@media (prefers-color-scheme: dark)': {
        borderColor: AppTheme.buttonBgDark,
      },
    },
    [`&.${listItemButtonClasses.root}:hover`]: {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },

  "& .primary": {
    lineHeight: 1.3
  },
  "& .secondary": {
    opacity: 0.6
  }
}));

export default function CheckListItem(props:IProps) {
  return (
    <StyledListItem
      disabled={props.disabled}
      onClick={()=>{
        props.onChange(!props.checked);
      }}
      className={clsx({
          'listType': props.type==='list',
          'checked': props.checked,
      })}
      sx={props.sx}
    >
      {props.image?(
        <ListItemIcon sx={{
          minWidth: 45,
          '& > *': {
            width: 25,
            display: 'block'
          }
        }}>
          {props.image}
        </ListItemIcon>
      ):null}
      <ListItemText
        primary={props.title}
        secondary={props.subtitle}
        primaryTypographyProps={{
          className: "primary"
        }}
        secondaryTypographyProps={{
          className: "secondary"
        }}
      />
      {(props.type==='check_list' || props.checked) ? (
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            onChange={()=>{
              props.onChange(!props.checked);
            }}
            checked={props.checked}
            color="default"
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={props.type==='list' ? (<CheckIcon/>) : (<CheckBoxOutlinedIcon />)}
          />
        </ListItemSecondaryAction>
      ):null}
    </StyledListItem>
  );
}
