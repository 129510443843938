import { AppBar, Box, Fab, Grid, Menu, MenuItem, Toolbar, styled } from "@mui/material";
import ElevationScroll from "component/elevationScroll";
import { API_CONFIG } from "config";
import { ReactComponent as AppStoreEN } from 'images/store/app-store-en.svg';
import { ReactComponent as AppStoreRU } from 'images/store/app-store-ru.svg';
import { ReactComponent as AppStoreUK } from 'images/store/app-store-uk.svg';
import { ReactComponent as GooglePlayEN } from 'images/store/google-play-en.svg';
import { ReactComponent as GooglePlayRU } from 'images/store/google-play-ru.svg';
import { ReactComponent as GooglePlayUK } from 'images/store/google-play-uk.svg';
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const storeIcons: any = {
  uk: {
    appStore: AppStoreUK,
    googlePlay: GooglePlayUK,
  },
  ru: {
    appStore: AppStoreRU,
    googlePlay: GooglePlayRU,
  },
  en: {
    appStore: AppStoreEN,
    googlePlay: GooglePlayEN,
  },
}

interface Props {
  children: JSX.Element;
}

const StyledContent = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&> div': {
    flexGrow: 9999999,
    flexShrink: 1,
    flexBasis: 'auto',
  }
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  "&.fixed": {
    overflow: 'hidden'
  }
}));

const LogoImage = styled("img")(({ theme }) => ({
  height: 34,
  display: "block",
}));

const StoreGridStyled = styled(Grid)(({ theme }) => ({
  width: 'auto',
  alignItems: 'center',
  flexWrap: 'nowrap',
  margin: theme.spacing(0, 3),
  ">div": {
    margin: theme.spacing(0, 1)
  },
  "& svg": {
    display: 'block',
    width: 130,
    maxWidth: '100%'
  },

  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 2),
    ">div": {
      margin: theme.spacing(0, 0.5)
    },
  },
}));

const langs: any = {
  'uk': "UA",
  'ru': "RU",
  'en': "EN",
}

function Layout(props: Props) {
  const { i18n } = useTranslation();
  const [anchorLangsEl, setAnchorLangsEl] = useState<null | HTMLElement>(null);

  const AppStoreIcon = useMemo(() => storeIcons[i18n.language].appStore, [i18n.language])
  const GooglePlayIcon = useMemo(() => storeIcons[i18n.language].googlePlay, [i18n.language])

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            border: '0 !important',
          }}
        >
          <Toolbar sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Box
              sx={{
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <a href="/">
                <LogoImage src="/logo.svg" />
              </a>
            </Box>
            <StoreGridStyled
              container
              sx={{
                flexGrow: 0,
                flexShrink: 1,
              }}
            >
              {!!API_CONFIG.google_play && (
                <Grid item>
                  <a
                    href={API_CONFIG.google_play}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GooglePlayIcon />
                  </a>
                </Grid>
              )}
              {!!API_CONFIG.app_store && (
                <Grid item>
                  <a
                    href={API_CONFIG.app_store}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppStoreIcon />
                  </a>
                </Grid>
              )}
            </StoreGridStyled>
            <Box
              sx={{
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Fab
                size="small"
                onClick={(e: any) => setAnchorLangsEl(e.currentTarget)}
                sx={{
                  boxShadow: 'none'
                }}
              >
                {langs[i18n.language] || ""}
              </Fab>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <StyledContent>
        <Toolbar style={{ flexGrow: 0 }} />
        <StyledContainer>
          {props.children}
        </StyledContainer>
      </StyledContent>

      <Menu
        anchorEl={anchorLangsEl}
        keepMounted
        open={Boolean(anchorLangsEl)}
        onClose={() => setAnchorLangsEl(null)}
      >
        {['uk', 'ru', 'en'].map((lang) => (
          <MenuItem
            key={lang}
            onClick={() => {
              i18n.changeLanguage(lang);
              setAnchorLangsEl(null)
            }}
          >
            {langs[lang]}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default Layout;
