import { Dialog, DialogContent, Typography } from "@mui/material";
import PaymentField from "apps/WebCabApp/components/paymentField";
import React from "react";
import { IPayment } from "utils/types";

interface IProps{
  message?: string;
  payment?: IPayment;
  onClose: ()=>void;
  onChange: (payment:IPayment)=>void;
}

export default function FailPaimentDialog(props: IProps) {

  const {
    message,
    payment,
    onClose,
    onChange
  } = props

  return (
    <Dialog
      open={Boolean(message)}
      onClose={onClose}
      maxWidth='xs'
    >
      <DialogContent>
        <Typography align="center">{message}</Typography>
        <br/><br/>

        <PaymentField
          exclude={payment}
          onChange={onChange}
        /><br/>
      </DialogContent>
    </Dialog>
  )
}