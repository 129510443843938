import CheckListItem from "apps/WebCabApp/components/checkListItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as CashIcon } from 'images/payment/cash.svg';
import { ReactComponent as CardIcon } from 'images/payment/card.svg';
import { ReactComponent as CompanyIcon } from 'images/payment/company.svg';
import { ReactComponent as MastercardIcon } from 'images/payment/mastercard.svg';
import { ReactComponent as VisaIcon } from 'images/payment/visa.svg';
import { AppTheme } from "theme";
import { List, ListSubheader, styled, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { IPayment, PaymentTypes } from "utils/types";
import AuthModel from "models/AuthModel";

interface IProps{
  value?: IPayment;
  exclude?: IPayment;
  onChange: (value:IPayment)=>void;
  sx?: SxProps<Theme>;
}


const RootList = styled(List)(({ theme }) => ({
  marginTop: -theme.spacing(1),
  marginBottom: -theme.spacing(1),
}));

export default function PaymentField(props:IProps) {
  const { t } = useTranslation();

  const money = (value:number):string => {
    const m = new Intl.NumberFormat('ua-UK', { maximumFractionDigits: 0  }).format(value)
    return `${m} ${t('uah')}`
  }
  
  return (
    <RootList sx={props.sx}>

      {props.exclude?.type !== PaymentTypes.CASH?(
        <CheckListItem
          title={t('payment_cash')}
          image={(
            <CashIcon
              fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
              width={25}
              height={18}
            />
          )}
          type="list"
          checked={props.value?.type===PaymentTypes.CASH}
          onChange={()=>{
            props.onChange({
              type: PaymentTypes.CASH
            })
          }}
        />
      ):null}

      {AuthModel.companies.length?(
        <ListSubheader component="div">{t('company_accounts')}</ListSubheader>
      ):null}
      {AuthModel.companies.map((company) => {
        if(props.exclude?.type === PaymentTypes.COMPANY && props.exclude?.company === company.id){
          return null
        }

        return (
          <CheckListItem
            key={company.id}
            title={company.name}
            subtitle={company.balance !== null ? `${t('balance')}: ${money(company.balance)}` : undefined}
            image={(
              <CompanyIcon
                fill={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
                stroke={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
              />
            )}
            type="list"
            checked={props.value?.type===PaymentTypes.COMPANY && props.value?.company === company.id}
            onChange={()=>{
              props.onChange({
                type: PaymentTypes.COMPANY,
                company: company.id
              })
            }}
          />
        )
      })}

      {AuthModel.cards.length?(
        <ListSubheader component="div">{t('bank_cards')}</ListSubheader>
      ):null}
      {AuthModel.cards.map((card) => {

        if(props.exclude?.type === PaymentTypes.CARD && props.exclude?.card === card.id){
          return null
        }

        let icon = (
          <CardIcon
            stroke={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
            height={25}
          />
        );
        switch (card.type) {
          case 'VISA':
            icon = (
              <VisaIcon
                width={45}
              />
            );
            break
          case 'MASTERCARD':
            icon = (
              <MastercardIcon
                height={25}
              />
            );
            break
        }

        return (
          <CheckListItem
            key={card.id}
            title={card.name}
            subtitle={card.masked}
            image={icon}
            type="list"
            checked={props.value?.type===PaymentTypes.CARD && props?.value.card === card.id}
            onChange={()=>{
              props.onChange({
                type: PaymentTypes.CARD,
                card: card.id
              })
            }}
          />
        )
      })}
    </RootList>
  );
}
