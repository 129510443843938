import { Box, List, ListItem, ListItemIcon, ListItemText, Popover, styled } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import React from "react";
import { ReactComponent as ServicesIcon } from 'images/services.svg';
import { IOption, useAppModel } from "models/AppModel";
import { requests } from "utils/requests";
import ImgColor from "./imgColor";
import { SxProps, Theme } from "@mui/system";


const ServicesBadgeIconRoot = styled(Box)(({ theme }) => ({
  marginRight: "-5px",
  marginTop: "-5px",
  '&>div': {
    width: 35,
    height: 30,
    margin: 'auto',
    position: 'relative',
  }
}));

const StyledBadge = styled("div")(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  borderRadius: 7,
  width: 15,
  height: 15,
  lineHeight: '15px',
  fontSize: '10px',
  color: '#ffffff',
  textAlign: 'center',
  backgroundColor: AppTheme.buttonBg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.buttonBgDark,
  },
}));

interface IProps{
  services: number[]
}

interface IServicesBadgeIconProps{
  sx?: SxProps<Theme>;
  color?: string;
  badge?: number
}

export function ServicesBadgeIcon(props:IServicesBadgeIconProps){

  const {
    sx,
    color = (AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg),
    badge = 0
  } = props

  const count_text = useMemo(()=>{
    if(badge > 9){
      return "9+";
    }else{
      return badge;
    }
  }, [badge])

  return (
    <ServicesBadgeIconRoot sx={sx}>
      <div>
        <ServicesIcon
          fill={color}
          stroke={color}
          height="100%"
          width="100%"
        />
        {badge?(
          <StyledBadge
            style={{backgroundColor: color}}
          >{count_text}</StyledBadge>
        ):null}
      </div>
    </ServicesBadgeIconRoot>
  )
}

export default function Services(props:IProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { getOptionByID } = useAppModel();

  const {
    services
  } = props

  const servicesData:IOption[] = useMemo(()=>{
    const res:IOption[] = [];
    services.map((i)=>{
      const info = getOptionByID(i);
      if(info !== null){
        res.push(info);
      }
      return i;
    })
    return res;
  }, [services, getOptionByID])

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(servicesData.length){
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <ServicesBadgeIcon
        badge={services.length}
        sx={{
          marginBottom: "5px"
        }}
      />
      <Box sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}>{t('services')}</Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
        }}
      >
        <List>
          {servicesData.map((option)=>(
            <ListItem key={option.id}>
              <ListItemIcon sx={{
                minWidth: 35,
                '& img': {
                  width: 20,
                  display: 'block'
                }
              }}>
                <ImgColor
                  src={requests.get_base_url(option.images.svg)}
                  color={AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg}
                />
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
}
