import useLayout from "apps/WebCabApp/layout/useLayout";
import React from "react";
import { requests } from "utils/requests";
import { ITemplate } from "utils/types";
import TemplateForm from "./templateForm";


export default function TemplateAdd() {
  const {goBack} = useLayout();
  const item:ITemplate = {
    name: '',
    routepoints: [{
      
    }],
    comment: ''
  }

  const onSubmit = (template:ITemplate) => {
    requests.post(`/user/template/`, template).then((r)=>{
      goBack()
    })
  }
  
  return (
    <React.Fragment>
      <TemplateForm
        template={item}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
}
