import { MutableRefObject, useRef } from "react"

export const useFocus = (with_select:boolean=false):[MutableRefObject<any>, ()=>void] => {
  const htmlElRef = useRef(null as any)
  const setFocus = () => {
    if(htmlElRef.current){
      htmlElRef.current.focus()
      if(with_select){
        htmlElRef.current.select();
      }
    }
  }

  return [ htmlElRef, setFocus ] 
}