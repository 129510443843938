import BaseContainer from "apps/WebCabApp/components/baseContainer";
import PaymentField from "apps/WebCabApp/components/paymentField";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import React from "react";
import { useTranslation } from "react-i18next";
import { IPayment } from "utils/types";
import BaseDialog from "./baseDialog";

interface IProps{
  open: boolean;
  onClose: ()=>void;
}

export default function PaymentDialog(props:IProps){
  const { t } = useTranslation();
  const {newOrder, setNewOrder} = useNewOrder();

  const {
    open,
    onClose
  } = props;

  const onPaymentChoice = (value:IPayment) => {
    setNewOrder({
      ...newOrder,
      payment: value
    });
    onClose();
  }

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={t('payment_method_title')}
    >
      <BaseContainer>
        <PaymentField
          value={newOrder.payment!}
          onChange={onPaymentChoice}
        />
      </BaseContainer>
    </BaseDialog>
  )
}