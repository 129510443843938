import { styled } from "@mui/material"
import { AppTheme } from "theme";


const Root = styled("div")(({ theme }) => ({
  position: 'relative',
  width: 19,
  height: 19,
  lineHeight: '19px',
  marginLeft: -1,
  borderRadius: '50%',
  fontSize: 13,
  fontWeight: 'bold',
  color: "#fff",
  textAlign: 'center',
  backgroundColor: AppTheme.alarmButtonBg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.alarmButtonBgDark,
  },

  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: -3,
    right: -3,
    top: -3,
    bottom: -3,
    zIndex: -1,
    borderRadius: '50%',
    opacity: 0.6,

    backgroundColor: AppTheme.alarmButtonBg,
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: AppTheme.alarmButtonBgDark,
    },
  }
}));

interface IProps{
  count: number
}

export default function CurrentTripsIcon(props:IProps) {
  return (
    <Root>{props.count}</Root>
  )
}