import { Box, Button, Divider, LinearProgress, styled, Typography } from "@mui/material";
import OrderDistance from "apps/WebCabApp/components/orderDistance";
import OrderInfoLine from "apps/WebCabApp/components/orderInfoLine";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import TripLayout from "apps/WebCabApp/layout/tripLayout";
import { WSModel } from "apps/WebCabApp/ws";
import FirebaseLog from "component/firebaseLog";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Money from "utils/money";
import { orderSerializer } from "utils/order/orderSerializer";
import IOrder from "utils/order/types";
import { requests } from "utils/requests";

interface IProps{
  order: IOrder;
  onCanceled: (order:IOrder)=>void;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function AvtoSearch(props:IProps) {
  const { t } = useTranslation();
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()

  const {
    order,
    onCanceled
  } = props;

  useEffect(()=>{
    const checkOrder = () => {
      requests.get(`/order/${order.id}/`).then((r)=>{
        const o = orderSerializer(r.body);
        if(o.status !== order.status){
          WSModel.onMessage({
            type: 'webcab.order',
            data: r.body
          })
        }
      })
    }

    let interval:NodeJS.Timeout|null = null;
    const timeout = setTimeout(()=>{
      interval = setInterval(()=>{
        checkOrder()
      }, 5000)
    }, (order.max_search_time || 5) * 1000)
    return ()=>{
      clearTimeout(timeout);
      if(interval!==null){
        clearInterval(interval);
      }
    }
  }, [order])

  const onCancel = () => {
    confirm({
      title: null,
      description: (
        <Typography align='center' component='span' style={{display: 'block'}}>
          {t('cancel_order_desc')}
        </Typography>
      ),
      cancellationText: t('no'),
      confirmationText: t('yes'),
    }).then(() => {
      requests.withoutErrorMessage().post(`/order/${order.id}/cancel/`, {
        reason: "Отменили кнопкой"
      }).then(()=>{
        FirebaseLog.order_cancel(order);
        
        enqueueSnackbar(t('order_canceled'), {variant: 'success'})
        onCanceled(order)
      }).catch((r)=>{
        enqueueSnackbar(t('order_cancel_failed'), {variant: 'error'})
      })
    });
  }
  
  return (
    <TripLayout>
      <RoutePoints
        routepoints={order.routepoints}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          my: 1,
        }}
      >
        <Box>
          <OrderDistance distance={order.distance}/>
        </Box>
        <Box>
          <Typography variant="h5" noWrap>
            <b>
              <Money
                value={order.price}
              />
            </b>
          </Typography>
        </Box>
      </Box>
      <StyledDivider/>

      <OrderInfoLine
        order={order}
        withPassenger
      />

      <StyledDivider/>

      <Box sx={{
        textAlign: 'center',
        mt: 3,
        mb: 4,
        "& .MuiLinearProgress-root": {
          height: "10px",
          borderRadius: "5px",
          width: '70%',
          margin: 'auto',
          mt: 1
        }
      }}>
        <Typography variant="button" align="center">{t('searching_car')}</Typography>
        <LinearProgress />
      </Box>

      <Button
        color='primary'
        variant='outlined'
        fullWidth
        onClick={onCancel}
      >{t('cancel')}</Button>
    </TripLayout>
  );
}
