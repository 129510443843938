import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState, useCallback } from 'react';
import MessageContext, { IMessage } from './MessageContext';


interface MessageProviderProps{
  children: React.ReactNode;
}

export default function MessageProvider(props: MessageProviderProps) {
  const [message, setMessage] = useState<IMessage|null>(null);
  const [resolveReject, setResolveReject] = useState<any[]>([]);
  const [resolve] = resolveReject;

  const promise:(message:IMessage) => Promise<IMessage> = useCallback((message:IMessage) => {
    setMessage(message);
    return new Promise((resolve, reject)=>{
      setResolveReject([resolve, reject]);
    });
  }, [])

  const handleClose = () => {
    if(resolve && message){
      resolve(message);
    }
    setMessage(null);
  }

  return (
    <MessageContext.Provider value={promise}>
        {props.children}

        <Dialog
          open={message!==null}
          onClose={handleClose}
          maxWidth='xs'
          fullWidth={true}
        >
          { message?.title ? (
            <DialogTitle>{message?.title}</DialogTitle>
          ):null}
          <DialogContent>
            <DialogContentText align='center'>
              {message?.message}
            </DialogContentText>
          </DialogContent>
          { !message?.disableButtons && (
            <DialogActions>
              <Button onClick={handleClose} variant="text" color="primary">
                OK
              </Button>
            </DialogActions>
          )}
        </Dialog>
    </MessageContext.Provider>
  );
}