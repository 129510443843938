import { CircularProgress, Typography } from "@mui/material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import WSModel, { WSMessage } from "apps/WebCabApp/ws/WSModel";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import OnWindowScrollEnd from "utils/onWindowScrollEnd";
import { orderSerializer } from "utils/order/orderSerializer";
import IOrder, { OrderStatus } from "utils/order/types";
import { requests } from "utils/requests";
import OrderListItem from "./orderListItem";

interface IOrderReducerAction{
  action: "plus"|"replace_one",
  data: IOrder[]|IOrder
}

export const TRIP_ORDER_STATUSES = [
  OrderStatus.FULFILLED,
  // OrderStatus.CHECKOUT,
  // OrderStatus.INCOMING,
  // OrderStatus.ARRIVED,
  // OrderStatus.IN_PROGRESS,
  // OrderStatus.HEADING
]

function orderReducer(state:(IOrder[]|null), action:IOrderReducerAction):(IOrder[]|null) {
  if(action.action === "plus"){
    return (state || []).concat(action.data)
  }else if(state !== null && action.action === "replace_one"){
    const order = action.data as IOrder;
    let _state = state.map((i) => {
      if(i.id === order.id){
        return order
      }
      return i;
    })
    if(order.status === undefined || !TRIP_ORDER_STATUSES.includes(order.status)){
      _state = state.filter((i)=>i.id!==order.id);
    }else{
      if(state !== null){
        const is_order_exists = !!state.filter((i)=>i.id===order.id).length
        if(!is_order_exists){
          _state.unshift(order)
        }
      }
    }
    return _state;
  }
  return state;
}

export default function Trips() {
  const [orders, setOrders] = useReducer(orderReducer, null as (IOrder[]|null));
  const [orderCount, setOrderCount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const { t } = useTranslation();
  
  const loadNext = () => {
    load((orders || []), orderCount)
  }

  const load = useCallback((_orders:IOrder[], _orderCount:(number|null)) => {
    if(_orderCount !== null && _orderCount <= _orders.length){
      return;
    }
    setSpinner(true);
    let query_params = {
      status__in: TRIP_ORDER_STATUSES.join(","),
      offset: _orders.length
    };
    requests.get("/order/", query_params).then((r)=>{
      setOrderCount(r.body.count);
      const results = r.body.results.map((i:any)=>orderSerializer(i));
      setOrders({
        action: 'plus',
        data: results
      });
      setSpinner(false);
    })
  }, [])

  useEffect(()=>{
    load([], null)
  }, [load])

  useEffect(()=>{
    const WS = (message:WSMessage) => {
      setOrders({
        action: 'replace_one',
        data: orderSerializer(message.data)
      });
    }
    WSModel.subscribe(WS, /^webcab\.order$/);
    return () => {
      WSModel.unsubscribe(WS);
    }
  }, [])

  const onRatingAdded = (orderID:number) => {
    requests.get(`/order/${orderID}/`).then((r)=>{
      setOrders({
        action: 'replace_one',
        data: orderSerializer(r.body)
      });
    })
  }

  if(orders === null){
    return null;
  }

  if(!orders.length){
    return (
      <div>
        {t('no_orders_yet')}
      </div>
    )
  }
  
  return (
    <OnWindowScrollEnd
      handle={()=>{
        if(!spinner){
          loadNext();
        }
      }}
    >
      <BaseContainer>
        {orders?.map((order, index) => (
          <OrderListItem
            key={index}
            order={order}
            onRatingAdded={()=>{
              onRatingAdded(order.id || 0)
            }}
          />
        ))}

        { spinner ? (
          <Typography align='center' component='div'>
            <CircularProgress />
          </Typography>
        ):null}
      </BaseContainer>
    </OnWindowScrollEnd>
  );
}
