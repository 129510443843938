import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Rating, styled } from "@mui/material";
import RoutePoints from "apps/WebCabApp/components/routePoints";
import { useProgress } from "component/progress";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTheme } from "theme";
import IOrder from "utils/order/types";
import { requests } from "utils/requests";

interface IProps{
  loading: boolean;
  order: IOrder|null;
  defaultRating: number;
  onClose: ()=>void;
  onSave: (rating:number)=>void;
}

interface IRatingItemsDict{
  id: number;
  name: string;
  for_rating: number[];
}

interface IRatingDict{
  titles: string[];
  items: IRatingItemsDict[]
}

const StyledGrayLine = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 3),
  fontWeight: 'bold',
  backgroundColor: AppTheme.lightBg,
  '@media (prefers-color-scheme: dark)': {
    backgroundColor: AppTheme.lightBgDark
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  "& .MuiListItem-root": {
    padding: 0,
    paddingRight: 32,
  },
  "& .MuiListItemSecondaryAction-root": {
    right: 0
  }
}));

export default function OrderRatingDialog(props: IProps) {
  const { t } = useTranslation();
  const {isProgress} = useProgress();
  const [rating, setRating] = useState<number|null>(null);
  const [comment, setComment] = useState<string>("");
  const [ratingItemsValue, setRatingItemsValue] = useState<number[]>([]);
  const [ratingDict, setRatingDict] = useState<IRatingDict|null>(null);

  const {
    loading,
    order,
    defaultRating,
    onClose,
    onSave
  } = props

  useEffect(()=>{
    setRating(defaultRating)
  }, [defaultRating])

  useEffect(()=>{
    requests.get('/dictionary/rating-items/').then((r)=>{
      setRatingDict(r.body)
    })
  }, [])

  const itemsList:IRatingItemsDict[] = useMemo(()=>{
    const items = [...(ratingDict?.items || [])];
    return items.filter((i)=>{
      return i.for_rating.includes(rating || -1);
    })
  }, [ratingDict, rating])

  const isItemsShow:boolean = useMemo(()=>{
    if(rating===null) return false;
    if(!itemsList.length) return false;
    return true;
  }, [rating, itemsList])

  const handleToggleItem = (itemID: number) => () => {
    const currentIndex = ratingItemsValue.indexOf(itemID);
    const newChecked = [...ratingItemsValue];

    if (currentIndex === -1) {
      newChecked.push(itemID);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRatingItemsValue(newChecked);
  };

  const Save = () => {
    const items = (ratingDict?.items || [])
      .filter((i)=>ratingItemsValue.includes(i.id) && i.for_rating.includes(rating||-1))
      .map((i)=>i.id)
    requests.post(`/order/${order?.id}/rating/`, {
      rating: rating,
      comment: comment,
      items: items
    }).then(()=>{
      onSave(rating||0)
    })
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='xs'
    >
      {(loading || ratingDict===null)?(
        <React.Fragment>
          <DialogContent>
            <CircularProgress color="primary" />
          </DialogContent>
        </React.Fragment>
      ):(
        <React.Fragment>
          <DialogTitle>{t('trip_over')}</DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              {order?.routepoints?(
                <Grid item>
                  <RoutePoints
                    routepoints={order?.routepoints}
                  />
                </Grid>
              ):null}
              <Grid item sx={{textAlign: 'center'}}>
                <Rating
                  name={`dialog-rating-${order?.id}`}
                  max={ratingDict.titles.length || 0}
                  disabled={isProgress}
                  readOnly={!order?.rating?.available}
                  value={order?.rating?.available
                    ? rating
                    : order?.rating?.value}
                  onChange={(e, newValue)=>{
                    if(newValue!==null){
                      setRating(newValue);
                    }
                  }}
                  sx={{fontSize: '2.7rem'}}
                />
              </Grid>
              <Grid item>
                <TextField
                  placeholder={t('add_comment_placeholder')}
                  multiline
                  fullWidth
                  disabled={isProgress}
                  value={comment}
                  onChange={(e)=>{
                    setComment(e.target.value);
                  }}
                />
              </Grid>
              {isItemsShow?(
                <Grid item sx={{
                  "&>*": {
                    my: 0,
                    mx: -3,
                  }
                }}>
                  <StyledGrayLine>
                    {ratingDict.titles[(rating||0)-1]}
                  </StyledGrayLine>

                  <StyledList dense>
                    {itemsList.map((item) => {
                      const labelId = `checkbox-${item.id}`;
                      return (
                        <ListItem
                          key={item.id}
                          button
                          onClick={handleToggleItem(item.id)}
                        >
                          <ListItemText id={labelId} primary={item.name} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              name={labelId}
                              edge="end"
                              color="default"
                              onChange={handleToggleItem(item.id)}
                              checked={ratingItemsValue.includes(item.id)}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </StyledList>
                </Grid>
              ):null}
            </Grid>
            <br/>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={Save}
              color="primary"
              variant="contained"
              size="large"
              fullWidth
            >{t('confirm')}</Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  )
}