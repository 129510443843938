import { MapEvent } from 'ol';
import { useEffect, useState } from 'react';
import { MapClass } from './map';
import { Route, Marker } from './types';
import 'elm-pep';
import 'ol/ol.css';
import { IPoint } from 'utils/types';
import { Box, styled } from '@mui/system';
import BaseEvent from 'ol/events/Event';

export interface IOLMapProps {
  id?:string;
  markers?:Marker[];
  route?:Route;
  marginBottom?:number;
  center?:IPoint;
  currentLocation?:boolean;
  includeCurrentLocationToFit?:boolean;
  onInit?: (map:MapClass)=>void;
  onMoveEnd?: (map:MapEvent)=>void;
  onMoveStart?: (map:MapEvent)=>void;
  onViewChange?: (event:BaseEvent)=>void;
}

const StyledMap = styled("div")(({ theme }) => ({
  width: '100%',
  height: '100%',
  "& .ol-zoom": {
    top: 'auto',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
  }
}));

const OLMap = (props:IOLMapProps) => {
  const [map, setMap] = useState(null as MapClass|null);

  const {
    id,
    onInit,
    onMoveEnd,
    onMoveStart,
    onViewChange
  } = props;
  

  useEffect(() => {
    const _map = new MapClass(id||'map');
    setMap(_map);
  }, [id]);
  
  useEffect(() => {
    if(onInit !== undefined && map !== null){
      onInit(map);
    }
  }, [map, onInit]);
  
  useEffect(() => {
    if(!map) return;
    map.updateMarkers((props.markers || []));
  }, [map, props.markers]);

  useEffect(() => {
    if(!map) return;
    map.marginBottom = props.marginBottom || 0;
    map.updateRoute(props.route)
  }, [map, props.route, props.marginBottom])

  useEffect(() => {
    if(!map || !props.center) return;
    map.setCenter(props.center)
  }, [map, props.center])

  useEffect(() => {
    if(!map) return;
    map.marginBottom = props.marginBottom || 0;
  }, [map, props.marginBottom])

  useEffect(() => {
    if(!map) return;
    if(props.currentLocation===undefined){
      map.hideCurrentLocation()
    }else{
      map.showCurrentLocation()
    }
  }, [map, props.currentLocation])

  useEffect(() => {
    if(!map) return;
    map.includeCurrentLocationToFit = Boolean(props.includeCurrentLocationToFit)
  }, [map, props.includeCurrentLocationToFit])

  useEffect(()=>{
    if(onMoveEnd !== undefined){
      const callback = (e:MapEvent) => {
        if(onMoveEnd !== undefined){
          onMoveEnd(e)
        }
      }
      map?.map.on('moveend', callback);
      return ()=>{
        map?.map.un('moveend', callback)
      }
    }
  }, [map, onMoveEnd])

  useEffect(()=>{
    if(onMoveStart !== undefined){
      const callback = (e:MapEvent) => {
        if(onMoveStart !== undefined){
          onMoveStart(e)
        }
      }
      map?.map.on('movestart', callback);
      return ()=>{
        map?.map.un('movestart', callback)
      }
    }
  }, [map, onMoveStart])

  useEffect(()=>{
    if(onViewChange !== undefined){
      const callback = (e:BaseEvent) => {
        if(onViewChange !== undefined){
          onViewChange(e)
        }
      }
      map?.map.getView().on('change', callback);
      return ()=>{
        map?.map.getView().un('change', callback);
      }
    }
  }, [map, onViewChange])

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      position: 'relative',
    }}>
      <StyledMap id={id||'map'}></StyledMap>
    </Box>
  );
}

OLMap.defaultProps = {
  markers: []
}

export default OLMap;