import { Box, Fab } from "@mui/material";
import { MyLocation } from "@mui/icons-material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import React from "react";

interface IProps{
  onClick: ()=>void;
}

export default function CurrentLocationButton(props:IProps) {
  
  return (
    <Box sx={{
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '100%',
    }}>
      <BaseContainer>
        <Box sx={{position: 'relative'}}>
          <Fab
            color="default"
            size="medium"
            onClick={props.onClick}
            sx={{
              pointerEvents: 'all',
              position: 'absolute',
              right: 0,
              bottom: '100%',
              mb: 1
            }}
          >
            <MyLocation />
          </Fab>
        </Box>
      </BaseContainer>
    </Box>
  );
}
