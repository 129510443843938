import { AppTheme } from "theme";

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const THEME_NAME = process.env['REACT_APP_THEME'] as string;


let _API_CONFIG = {
    api_base_url: process.env['REACT_APP_API_URL'] as string,
    ws_url: process.env['REACT_APP_WS_URL'] as string,
    api_key: process.env['REACT_APP_API_KEY'] as string,
    app_store: process.env['REACT_APP_APPSTORE'] as string,
    google_play: process.env['REACT_APP_GOOGLEPLAY'] as string,
    debug: false
}
if(localStorage.getItem("DEBUG")){
    _API_CONFIG = {
        api_base_url: "https://wcab-api3.elit-taxi.ua/v2",
        ws_url: "wss://wcab-api3.elit-taxi.ua/ws/v2/ws/",
        api_key: "10a6b641084240fd8088785bf97da299",
        app_store: process.env['REACT_APP_APPSTORE'] as string,
        google_play: process.env['REACT_APP_GOOGLEPLAY'] as string,
        debug: true
    }
}
export const API_CONFIG = _API_CONFIG

export const MAP_TILE = (AppTheme.theme === 'dark' ? "https://a.basemaps.cartocdn.com/dark_all/" : "https://ua.map.cle.com.ua/tile/")

interface ILang {
    code: string;
    name: string;
    shortName: string;
}
export const LANGUAGES:ILang[] = [{
    code: 'uk',
    name: 'Українська',
    shortName: 'Укр',
},{
    code: 'ru',
    name: 'Русский',
    shortName: 'Рус',
},{
    code: 'en',
    name: 'English',
    shortName: 'Eng',
}]
export const DEFAULT_LANGUAGE = 'en';