import { Box, CircularProgress, Grid, styled } from "@mui/material";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { ReactComponent as TaxometrIcon } from 'images/taximeter.svg';
import { AppTheme } from "theme";
import { PaymentTypeIcon, PaymentTypeTitle } from "apps/WebCabApp/components/paymentType";
import { IRoutePrice } from "utils/types";
import IconButtonBox from "./iconButtonBox";
import { useTranslation } from "react-i18next";
import Money from "utils/money";
import { ReactComponent as TimeIcon } from 'images/time.svg';
import { ReactComponent as PassengerIcon } from 'images/passenger.svg';
import { ServicesBadgeIcon } from "apps/WebCabApp/components/services";

interface IProps{
  routePrice: IRoutePrice|null;
  texometerActive: boolean;
  onClickTaxometer: ()=>void;
  onClickPayment: ()=>void;
  onClickPassenger: ()=>void;
  onClickTime: ()=>void;
  onClickServices: ()=>void;
}


const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(-2, -0.4),
  width: 'auto',
  "& > .MuiGrid-item": {
    padding: theme.spacing(2, 0.4),
    maxWidth: '20%',
    overflow: 'hidden',
  },
  "& > .MuiGrid-item:first-of-type": {
    maxWidth: '100%',
  }
}));

const StyledPriceContainer = styled(Grid)(({ theme }) => ({
  minWidth: 85,
  flexShrink: 0,
  "&.texometr": {
    opacity: 0.4,
    "&.active": {
      opacity: 1
    }
  }
}));

export default function ConfigLine(props:IProps){
  const { t } = useTranslation();

  const {newOrder} = useNewOrder();

  const {
    routePrice,
    texometerActive,
    onClickTaxometer,
    onClickPassenger,
    onClickPayment,
    onClickServices,
    onClickTime
  } = props

  const iconColor = useCallback((active:boolean)=>{
    if(active){
      return AppTheme.theme === 'dark' ? AppTheme.buttonBgDark : AppTheme.buttonBg
    }
    return AppTheme.theme === 'dark' ? AppTheme.colorDark : AppTheme.darkColor;
  }, [])

  const price:number|null = useMemo(()=>{
    if(routePrice===null) return null;
    for(let i=0; i<routePrice.class_prices.length; i++){
      const class_price = routePrice.class_prices[i];
      if(class_price.id === newOrder.car_class){
        return class_price.price;
      }
    }
    return null;
  }, [newOrder, routePrice])

  return (
    <Root
      container
      alignItems="stretch"
      justifyContent="space-between"
      wrap="nowrap"
    >
      
      {newOrder.routepoints.length<2?(
        <StyledPriceContainer
          item
          className={clsx("texometr", {
            active: texometerActive
          })}
        >
          <IconButtonBox
            title={t('by_taximeter')}
            titleSize="small"
            onClick={onClickTaxometer}
          >
            <TaxometrIcon
              fill={AppTheme.darkColor}
              height={23}
            />
          </IconButtonBox>
        </StyledPriceContainer>
      ):(
        <StyledPriceContainer item>
          
          {price===null?(
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CircularProgress
                size={20}
              />
            </Box>
          ):(
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-start"
              height="100%"
            >
              <Box sx={{
                fontFamily: "'Reforma Grotesk Demi', Verdana, Arial, sans-serif",
                fontSize: '3em',
                lineHeight: 1,
                whiteSpace: 'nowrap',
                marginBottom: "-5px",
                color: AppTheme.darkColor,
                '@media (prefers-color-scheme: dark)': {
                  color: AppTheme.colorDark,
                }
              }}>
                <Money
                  value={price}
                  prefixSX={{
                    fontFamily: "'SF UI Text', Verdana, Arial, sans-serif",
                    fontSize: "0.5em"
                  }}
                />
              </Box>
            </Box>
          )}
        </StyledPriceContainer>
      )}

      <Grid item>
        <IconButtonBox
          title={(<PaymentTypeTitle payment={newOrder.payment}/>)}
          titleSize="small"
          onClick={onClickPayment}
        >
          <PaymentTypeIcon payment={newOrder.payment}/>
        </IconButtonBox>
      </Grid>

      <Grid item>
        <IconButtonBox
          title={!!newOrder.other_passenger?newOrder.other_passenger.name:t('passenger')}
          titleSize="small"
          onClick={onClickPassenger}
        >
          <PassengerIcon
            stroke={iconColor(!!newOrder.other_passenger)}
            height={23}
          />
        </IconButtonBox>
      </Grid>

      <Grid item>
        <IconButtonBox
          title={!!newOrder.pre_order?t('beforehand'):t('order_start_time')}
          titleSize="small"
          onClick={onClickTime}
        >
          <TimeIcon
            stroke={iconColor(!!newOrder.pre_order)}
            height={23}
          />
        </IconButtonBox>
      </Grid>

      <Grid item>
        <IconButtonBox
          title={t('services_title')}
          titleSize="small"
          onClick={onClickServices}
        >
          <ServicesBadgeIcon
            color={iconColor(!!newOrder.additionalservices?.length)}
            badge={newOrder.additionalservices?.length}
          />
        </IconButtonBox>
      </Grid>

    </Root>
  )
}