import React, { useEffect } from 'react';
import WSContext from './WSContext';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { API_CONFIG } from 'config';
import WSModel from './WSModel';
import { requests } from 'utils/requests';
import { useTranslation } from 'react-i18next';
import AppModel from 'models/AppModel';


interface WSProviderProps{
  children: React.ReactNode;
}

export default function MessageProvider(props: WSProviderProps) {
  const { i18n } = useTranslation();

  let auth_params:any = {};
  const auth_data = requests.authorization_data();
  if(auth_data !== null){
    auth_params[auth_data[0]] = auth_data[1]
  }

  const ws = useWebSocket(API_CONFIG.ws_url, {
    onMessage: (r) => {
      WSModel.onMessage(JSON.parse(r.data));
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => AppModel.city !== null,
    reconnectAttempts: 9999999,
    reconnectInterval: 2000,
    queryParams: {
      'api-key': API_CONFIG.api_key,
      ...auth_params
    },
  });
  const {
    readyState,
    sendJsonMessage
  } = ws

  useEffect(()=>{
    if(readyState === ReadyState.OPEN){
      sendJsonMessage(
        {"lang": i18n.language}
      )
    }
  }, [readyState, sendJsonMessage, i18n.language])
  

  return (
    <WSContext.Provider value={ws}>
        {props.children}
    </WSContext.Provider>
  );
}