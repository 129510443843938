import React, { useMemo } from "react";
import { Box, styled, Toolbar } from "@mui/material";
import ElevationScroll from "component/elevationScroll";
import { useTranslation } from "react-i18next";
import LayoutProvider from "./LayoutProvider";
import useLayout from "./useLayout";
import clsx from "clsx";
import LayoutAppBar from "./layoutAppBar";

interface Props {
  titleKey?: string;
  children: JSX.Element|null;
}

const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '-webkit-fill-available',
  maxHeight: '-webkit-fill-available',
  '&> div': {
    flexGrow: 9999999,
    flexShrink: 1,
    flexBasis: 'auto'
  }
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  "&.fixed": {
    overflow: 'hidden'
  }
}));

function Layout(props:Props) {
  const { t } = useTranslation();
  const { title, goBack, fixedHeight } = useLayout();

  const titleText = useMemo(()=>{
    if(title) return title;
    if(props.titleKey) return t(props.titleKey)
    return null;
  }, [title, props.titleKey, t])

  const renderToolbar = () => {
    if(titleText===null) return null;
    
    return (
      <ElevationScroll {...props}>
        <LayoutAppBar
          title={titleText}
          onBack={goBack}
        />
      </ElevationScroll>
    );
  }
  
  return (
    <React.Fragment>
      {renderToolbar()}
      <StyledContent>
        {titleText!==null ? (<Toolbar style={{ flexGrow: 0 }} />) : null}
        <StyledContainer className={clsx({
          fixed: fixedHeight
        })}>
          {props.children}
        </StyledContainer>
      </StyledContent>
    </React.Fragment>
  );
}

export default function _Layout(props:Props){
  return (
    <LayoutProvider>
      <Layout
        {...props}
      />
    </LayoutProvider>
  );
}