import { Box, Button, CircularProgress, Fab, styled, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import BaseContainer from "apps/WebCabApp/components/baseContainer";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import OnWindowScrollEnd from "utils/onWindowScrollEnd";
import { requests } from "utils/requests";
import { ITemplate } from "utils/types";
import TemplateListItem from "./templateListItem";
import { ReactComponent as SetOnMapIcon } from 'images/set_on_map.svg';
import { AppTheme } from "theme";
import { Link, useHistory } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import useNewOrder from "apps/WebCabApp/providers/newOrderProvider/useNewOrder";
import useLayout from "apps/WebCabApp/layout/useLayout";


interface ITemplatesReducerAction{
  action: "plus"|"remove",
  data: ITemplate[]|ITemplate
}

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
}));


function itemsReducer(state:(ITemplate[]|null), action:ITemplatesReducerAction):(ITemplate[]|null) {
  if(action.action === 'plus'){
    return (state || []).concat(action.data)
  }else if(action.action === 'remove'){
    return (state || []).filter((i)=>i.id!==(action.data as ITemplate).id)
  }

  return state;
}

export default function Templates() {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const history = useHistory();
  const {goBack} = useLayout();
  const {setNewOrder} = useNewOrder();
  const [items, setItems] = useReducer(itemsReducer, null as (ITemplate[]|null));
  const [itemsCount, setItemsCount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  
  const loadNext = () => {
    load((items || []), itemsCount)
  }

  const load = useCallback((_items:ITemplate[], _itemsCount:(number|null)) => {
    if(_itemsCount !== null && _itemsCount <= _items.length){
      return;
    }
    setSpinner(true);
    let query_params = {
      offset: _items.length
    };
    requests.get("/user/template/", query_params).then((r)=>{
      setItemsCount(r.body.count);
      setItems({
        action: 'plus',
        data: r.body.results
      });
      setSpinner(false);
    })
  }, [])

  useEffect(()=>{
    load([], null)
  }, [load])

  const onRepeatTrip = (item:ITemplate) => {
    if(item.need_update){
      onEdit(item);
      return;
    }else{
      setNewOrder((o)=>{
        o.routepoints = [...item.routepoints];
        o.comment = item.comment;
        return {...o}
      })
      goBack();
    }
  }

  const onRepeatBackwardsTrip = (item:ITemplate) => {
    const template:ITemplate = {
      ...item,
      routepoints: [...item.routepoints]
    };
    template.routepoints.reverse();
    onRepeatTrip(template);
  }

  const onEdit = (item:ITemplate) => {
    history.push(`/templates/${item.id}`)
  }

  const onDelete = (item:ITemplate) => {
    confirm({
      title: null,
      description: (
        <Typography align='center' component='span' style={{display: 'block'}}>
          {t('delete_template_title')}
        </Typography>
      ),
      cancellationText: t('no'),
      confirmationText: t('yes'),
    }).then(() => {
      requests.delete(`/user/template/${item.id}/`).then(()=>{
        setItems({
          action: 'remove',
          data: item
        })
      })
    });
  }

  if(items === null){
    return null;
  }

  if(!items.length){
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        
      >
        <Box style={{width: '50%', height: '50%', opacity: 0.1}}>
          <SetOnMapIcon
            fill={AppTheme.theme === 'dark' ? AppTheme.darkButtonBg : AppTheme.darkButtonBgDark}
            width="100%"
            height="100%"
          />
        </Box>
        <Box>
          <Button
            color='primary'
            variant='contained'
            startIcon={<Add />}
            component={Link}
            to={`/templates/add`}
          >
            {t('new_template')}  
          </Button>
        </Box>
      </Box>
    )
  }
  
  return (
    <OnWindowScrollEnd
      handle={()=>{
        if(!spinner){
          loadNext();
        }
      }}
    >
      <BaseContainer>
        {items?.map((item, index) => (
          <TemplateListItem
            key={index}
            item={item}
            onClick={()=>onRepeatTrip(item)}
            onRepeatTrip={()=>onRepeatTrip(item)}
            onRepeatBackwardsTrip={()=>onRepeatBackwardsTrip(item)}
            onEdit={()=>onEdit(item)}
            onDelete={()=>onDelete(item)}
          />
        ))}

        { spinner ? (
          <Typography align='center' component='div'>
            <CircularProgress />
          </Typography>
        ):null}

        <StyledFab
          color='primary'
          // @ts-ignore
          component={Link}
          to={`/templates/add`}
        >
          <Add/>
        </StyledFab>
      </BaseContainer>
    </OnWindowScrollEnd>
  );
}
