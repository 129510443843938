import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import firebase from "firebase";
import 'firebase/analytics';

export default function FirebaseAnalytics() {
    let location = useLocation();
    useEffect(() => {
        const analytics = firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location]);
    return null;
}
