import { IRoutePoint } from "apps/WebCabApp/components/routePoints/types";
import { ICallSign, IPayment } from "utils/types";

export enum OrderStatus {
  CARSEARCH = 'CARSEARCH',
  INCOMING = 'INCOMING',
  CHECKOUT = 'CHECKOUT',
  ACCEPTED = 'ACCEPTED',
  HEADING = 'HEADING',
  IN_PROGRESS = 'IN_PROGRESS',
  FULFILLED = 'FULFILLED',
  UNFULFILLED = 'UNFULFILLED',
  REMOVED = 'REMOVED',
  UNDEFINED = 'UNDEFINED',
  EDITABLE = 'EDITABLE',
  UNDEFAULT = 'UNDEFAULT',
  HOT = 'HOT',
  ONGOING = 'ONGOING',
  DISPATCHER1 = 'DISPATCHER1',
  DISPATCHER2 = 'DISPATCHER2',
  OPERATOR = 'OPERATOR',
  ARRIVED = 'ARRIVED',
  CANCELED = 'CANCELED',
}

interface IOrderItemRating{
  available: boolean;
  value: number;
}

export interface IOtherPassenger{
  phone: string;
  name: string;
}

export default interface IOrder{
  id?: number;
  date?: Date;
  price?: number;
  car_class: number;
  status?: OrderStatus;
  pre_order?: boolean;
  routepoints: IRoutePoint[];
  call_sign?: ICallSign;
  distance?: number;
  payment?: IPayment;
  additionalservices?: number[];
  comment?: string;
  rating?: IOrderItemRating;
  other_passenger?: IOtherPassenger;
  message_after_create?: string;
  max_search_time?: number;
}