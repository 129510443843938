import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IRoutePoint } from "../routePoints/types";
import { Box } from "@mui/material";
import OLMap, { IOLMapProps } from "../map";
import { MapPointer } from "./mapPointer";
import CurrentLocationButton from "./currentLocationButton";
import { IPoint } from "utils/types";
import { MapClass } from "../map/map";
import { MapEvent } from "ol";
import { toLonLat } from "ol/proj";
import CurrentLocationModel from "models/CurrentLocationModel";
import AppModel from "models/AppModel";
import { requests } from "utils/requests";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface IProps{
  onChange?: (value:IRoutePoint|null)=>void;
  children?: JSX.Element;
  mapProps?: IOLMapProps;
  forceCenter?: IPoint;
  readOnly?: boolean;
  onMapMoveEnd?: (event:MapEvent)=>void;
  onMapInit?: (map:MapClass)=>void;
}

export default function AddressOnMapField(props:IProps){
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  
  const [map, setMap] = useState<MapClass|undefined>(undefined);
  const [center, setCenter] = useState<IPoint|undefined>(undefined);
  const [mapMarginBottom, setMapMarginBottom] = useState<number>(0);
  const [mapMoving, setMapMoving] = useState<boolean>(false);
  const [pointerPosition, setPointerPosition] = useState<[number, number]|null>(null);
  const pointerPixelRef = useRef(null);
  const infoRef = useRef(null);

  const {
    onChange,
    mapProps,
    forceCenter,
    children,
    readOnly
  } = props;

  const onMapMoveEnd = (m:MapEvent) => {
    setMapMoving(false);
    if(props.onMapMoveEnd!==undefined){
      props.onMapMoveEnd(m);
    }

    if(!!readOnly) return;

    if(pointerPixelRef && pointerPixelRef?.current){
      let position = (pointerPixelRef?.current as any).getBoundingClientRect();
      const coordinates = toLonLat(m.map.getCoordinateFromPixel([position.x, position.y]));
      setPointerPosition([coordinates[0], coordinates[1]])
    }
  }

  const updateNear = useCallback(()=>{
    if(pointerPosition === null) return;

    const abortController = new AbortController();
    let timer = setTimeout(() => {
      requests.get("/address/near/", {
        limit: 1,
        lng: pointerPosition[0],
        lat: pointerPosition[1],
      }, abortController.signal).then((r) => {
        let res:IRoutePoint|null = null;
        if(!r.body?.count){
          enqueueSnackbar(t('address_not_found'), {variant: 'warning'})
        }else{
          res = r.body.results[0]
        }

        if(res!==null){
          res.point = {
            type: 'Point',
            coordinates: [
              pointerPosition[1],
              pointerPosition[0]
            ]
          }
        }

        if(onChange!==undefined){
          onChange(res)
        }
      })
    }, 200)

    return () => {
      clearTimeout(timer);
      abortController.abort();
    };

  }, [pointerPosition, enqueueSnackbar, t, onChange])

  useEffect(updateNear, [updateNear])

  const updateMapMarginBottom = useCallback(()=>{
    if(infoRef !== null && infoRef.current !== null){
      setMapMarginBottom((infoRef.current as any).clientHeight)
    }
  }, [infoRef])

  useEffect(()=>{
    if(map === null) return;
    if(mapProps){};
    updateMapMarginBottom();
  }, [map, mapProps, updateMapMarginBottom])

  const setCurrentLocation = useCallback(()=>{
    if(map === null) return;
    
    updateMapMarginBottom();
    if(CurrentLocationModel.point){
      setCenter({...CurrentLocationModel.point});
    }else{
      if(AppModel.city?.center){
        setCenter({...AppModel.city?.center});
      }
    }
  }, [map, updateMapMarginBottom])

  useEffect(()=>{
    if(center) return;

    if(CurrentLocationModel.point !== null){
      setCurrentLocation();
      return
    }

    let timeout:any = 0;
    const updateCenter = (point:IPoint) => {
      clearTimeout(timeout);
      timeout = setTimeout(()=>{
        if(!center){
          setCurrentLocation();
        }
      }, 200)
    }

    CurrentLocationModel.subscribe(updateCenter);
    return ()=>{
      CurrentLocationModel.unsubscribe(updateCenter);
    }
  }, [center, setCurrentLocation])

  useEffect(()=>{
    if(forceCenter!==undefined){
      if(map !== undefined){
        const event = new MapEvent("moveend", map.map);
        try{
          map.map.dispatchEvent(event)
        }catch{}
      }
      setCenter(forceCenter);
    }
  }, [forceCenter, map])

  const rndID = useMemo(()=>{
    return `map-id-${Math.random()}`;
  }, [])
  
  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0
    }}>
      <OLMap
        {...(mapProps||{})}
        id={rndID}
        center={!mapProps?.route ? center : undefined}
        currentLocation={true}
        includeCurrentLocationToFit={false}
        marginBottom={mapMarginBottom}
        onInit={(m)=>{
          if(props.onMapInit!==undefined){
            props.onMapInit(m)
          }
          setMap(m)
        }}
        onMoveStart={(m)=>{
          setMapMoving(true);
        }}
        onMoveEnd={onMapMoveEnd}
      />

      {children?(
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            flexGrow={1}
            flexShrink={1}
            sx={{
              position: 'relative'
            }}
          >
            {!readOnly?(
              <MapPointer
                ref={pointerPixelRef}
                active={mapMoving}
              />
            ):null}
          </Box>
          <Box
            flexGrow={0}
            flexShrink={0}
          >
            <Box
              ref={infoRef}
              sx={{
                position: 'relative',
                pb: 2,
              }}
            >
              <CurrentLocationButton
                onClick={setCurrentLocation}
              />
              
              {children}
            </Box>
          </Box>
        </Box>
      ):null}
    </Box>
  )
}